<template>
  <div>
    <div class="counter" v-if="left.total">
      <v-alert dense type="warning">
        距离考试结束还有{{ left.minutes }}分{{ left.seconds }}秒
      </v-alert>
    </div>
    <v-container>
      <v-form
        ref="examForm"
        v-model="valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-btn text color="secondary" @click="back()">返回</v-btn>
                <v-spacer></v-spacer>
                <small
                  >在线考试:
                  {{ traingingplan ? traingingplan.mingcheng : "" }}</small
                >
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>
                  <div style="color: black">
                    {{ traingingplan.description }}
                  </div>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-for="q in traingingplan.questions" :key="q.id">
            <v-card>
              <v-card-title>
                <small>{{ q.title }}</small>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-row v-if="q.type === '填空题'">
                    <v-col cols="12">
                      <v-text-field
                        label="填写答案"
                        v-model="q.textanswer"
                        :rules="rules.textanswerRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="q.type === '选择题'" dense>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="q.arrayanswer"
                        :label="q.opt.A"
                        value="A"
                        hide-details
                        :rules="rules.arrayanswerRules"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="q.arrayanswer"
                        :label="q.opt.B"
                        value="B"
                        hide-details
                        :rules="rules.arrayanswerRules"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="q.arrayanswer"
                        :label="q.opt.C"
                        value="C"
                        hide-details
                        :rules="rules.arrayanswerRules"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="q.arrayanswer"
                        :label="q.opt.D"
                        value="D"
                        hide-details
                        :rules="rules.arrayanswerRules"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" align="right">
            <v-btn color="primary" @click="submit(true)">提交答案</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogSet.title }}
        </v-card-title>
        <v-card-text>{{ dialogSet.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="back()"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <style scoped>
.counter {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  margin-left: 100px;
}
</style>
    <script>
export default {
  props: ["id"],
  data() {
    return {
      dialogSet: {
        open: false,
        title: "",
        message: "",
      },
      leixing: "",
      counter: "",
      left: {
        total: 0,
        minutes: 0,
        seconds: 0,
      },
      valid: false,
      loading: false,
      traingingplan: {
        questions: [],
      },
      rules: {
        textanswerRules: [(v) => !!v || "请填写答案"],
        arrayanswerRules: [
          (v) => (v && v.length > 0) || "请填选择你认为正确的答案",
        ],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchExam();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchExam();
  },
  destroyed() {
    if (this.counter) {
      clearInterval(this.counter);
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "mytraining",
        params: { type: this.leixing },
      });
    },
    fetchExam() {
      this.loading = true;
      this.$hc
        .req()
        .get(`exams/${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.leixing = x.Data.peixunleixing;
            this.$confirm(
              `考试时长${x.Data.duration}分钟, 确认开始考试吗？`
            ).then((y) => {
              if (y) {
                x.Data.questions = x.Data.questions || [];
                for (var i = 0; i < x.Data.questions.length; i++) {
                  var q = x.Data.questions[i];
                  q.arrayanswer = [];
                  q.textanswer = "";
                  if (q.type === "选择题") {
                    q.opt = JSON.parse(q.options);
                  }
                }
                this.traingingplan = x.Data;
                this.$hc
                  .req()
                  .post(`exams/counting`, { examid: this.traingingplan.examid })
                  .go({
                    lock: false,
                    toastError: true,
                    toastSuccess: "",
                  })
                  .subscribe(
                    (j) => {
                      this.left.total = j.Data.left;
                      if (j.Data.end) {
                        this.dialogSet.title = "您的考试已结束";
                        this.dialogSet.message =
                          "您的考试已结束, 如有疑问请联系管理员";
                        this.dialogSet.open = true;
                      } else {
                        this.calculateLeftTime();
                        this.counter = setInterval(() => {
                          this.timetick();
                        }, 1000);
                      }
                    },
                    (j) => {}
                  );
              } else {
                this.back();
              }
            });
          },
          (x) => {}
        );
    },
    submit(force) {
      if (force) {
        if (!this.$refs.examForm.validate()) return;
      }
      var a = {};
      a.id = this.traingingplan.examid;
      a.questions = [];
      for (var i = 0; i < this.traingingplan.questions.length; i++) {
        var q = this.traingingplan.questions[i];
        a.questions.push({
          id: q.id,
          textanswer: q.textanswer,
          arrayanswer: q.arrayanswer,
        });
      }
      this.$hc
        .req()
        .post(`exams/answer`, a)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe((x) => {
          this.$ns.cast("snack", {
            text: `你的成绩: ${x.Data}`,
            color: "success",
          });
          this.back();
        });
    },
    calculateLeftTime() {
      var s = this.left.total % 60;
      var m = (this.left.total - s) / 60;
      this.left.seconds = s;
      this.left.minutes = m;
    },
    timetick() {
      this.left.total -= 1;
      if (this.left.total <= 0) {
        this.left.total = 0;
        clearInterval(this.counter);
        this.submit(false);
      }
      this.calculateLeftTime();
    },
  },
};
</script>
    