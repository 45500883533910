<template>
  <div>
    <v-card>
      <v-card-title>
        <small>考试</small>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.start="props">{{
            props.item.start | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.answers="props">{{
            props.item.result ? "已作答" : "未作答"
          }}</template>
          <template v-slot:item.end="props">{{
            props.item.end | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="props.item.status != '进行中'"
                  v-on="on"
                  :href="`${cousewareurl(props.item)}`"
                  target="_blank"
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
              </template>
              <span>下载试卷</span>
            </v-tooltip>
          </template>
          <template v-slot:item.up="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="props.item.status != '进行中'"
                  v-on="on"
                  @click="uploadanswer(props.item)"
                >
                  <v-icon>file_upload</v-icon>
                </v-btn>
              </template>
              <span>上传答案</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有考试</template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>

        <input
          ref="fileinput"
          type="file"
          accept=".doc,.docx"
          style="display: none"
          @change="getfile($event)"
        />
      </div>
    </v-card>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      q: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      answer: {},
      data: {},
      headers: [
        {
          text: "考试名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "是否作答",
          value: "answers",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "开始时间",
          value: "start",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "结束时间",
          value: "end",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试简介",
          value: "description",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "下载",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "上传答案",
          value: "up",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    getfile(evt) {
      var file = event.target.files;
      if (file && file.length > 0) {
        let fd = new FormData();
        fd.append("id", this.answer.id);
        fd.append("resultid", this.answer.resultid);
        fd.append("file", file[0]);
        this.$hc
          .req()
          .post(`examinations/answer`, fd)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.$refs.fileinput.value = "";
            },
            (x) => {
              this.$refs.fileinput.value = "";
            }
          );
      }
    },
    search() {
      this.fetchCoursewares();
    },
    cousewareurl(examination) {
      return (
        process.env.VUE_APP_VIDEO_ROOT +
        `examinations/${examination.id}/${examination.filename}`
      );
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `examinations?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    uploadanswer(item) {
      this.answer.id = item.id;
      if (item.result && item.result.id) {
        this.answer.resultid = item.result.id;
        this.$confirm("您已经作答过, 该操作会覆盖前次作答答案, 确定吗?").then(
          (x) => {
            if (x) {
              this.$refs.fileinput.click();
            }
          }
        );
      } else {
        this.$refs.fileinput.click();
      }
    },
  },
};
</script>
    