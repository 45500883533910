<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/trainingplans">返回</v-btn>
        <v-spacer></v-spacer>参加人员
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openJoinDialog">添加人员</v-btn>
      </v-card-title>
      <v-toolbar>
        <v-text-field
          label="姓名"
          clearable
          v-model="q"
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.checkedin="props">
            <v-btn
              v-if="props.item.checkedin"
              x-small
              color="warning"
              @click="removeCheckin(props.item.id)"
            >删除签到</v-btn>
            <v-btn
              x-small
              color="success"
              v-if="!props.item.checkedin"
              @click="checkin(props.item.id)"
            >给他签到</v-btn>
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteMember(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>尚未有人员参加培训</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages" :total-visible="10"></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{dialogSet.title}}</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-autocomplete
                  v-model="dialogSet.vm.data"
                  :items="dialogSet.items"
                  :loading="dialogSet.isLoading"
                  :search-input.sync="dialogSet.search"
                  hide-no-data
                  hide-selected
                  item-text="xingming"
                  item-value="id"
                  label="参训人员"
                  placeholder="输入姓名或身份证号"
                  prepend-icon="mdi-database-search"
                  return-object
                  clearable
                  :rules="rules.memberRule"
                >
                  <template v-slot:item="data">{{data.item.xingming}} ({{data.item.sfz}})</template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogSet.vm.data={};dialogSet.open = false">取消</v-btn>
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="join(dialogSet.vm.data)"
            >保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogSet.detailsOpen" max-width="600px">
      <plain-details :details="dialogSet.details" />
    </v-dialog>
  </div>
</template>
    
    <script>
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter
} from "rxjs/operators";
export default {
  props: ["id"],
  data() {
    return {
      search$: null,
      sub$: null,
      q: "",
      loading: true,
      dialogSet: {
        open: false,
        title: "添加参训人员",
        isLoading: false,
        items: [],
        search: "",
        vm: {
          valid: false,
          data: {}
        }
      },
      pagination: {
        page: 1,
        rowsPerPage: 15
      },
      data: {},
      rules: {
        memberRule: [v => (!!v && v.id) || "请选择参训人员"]
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "身份证",
          value: "sfz",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },        
        {
          text: "签到",
          value: "checkedin",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header"
        }
      ]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchMembers();
      },
      deep: true
    },
    "dialogSet.search": {
      handler() {
        this.search$.next(this.dialogSet.search);
      }
    }
  },
  mounted() {
    this.fetchMembers();
    this.search$ = new BehaviorSubject();
    this.sub$ = this.search$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(x => x && x.length >= 1),
        switchMap(x => {
          this.dialogSet.isLoading = true;
          return this.$http.get(`admin/trainingmembers/members?q=${x}`);
        })
      )
      .subscribe(x => {
        this.dialogSet.isLoading = false;
        this.dialogSet.items = x.body.Data;
      });
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    }
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchMembers();
    },
    fetchMembers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/trainingmembers/${this.id}?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}&q=${this.q||''}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
            this.data = x.Data;
          },
          x => {}
        );
    },
    checkin(id) {
      var t = "确定要给他签到吗?";
      this.$confirm(t).then(x => {
        if (x) {
          this.$hc
            .req()
            .post(`admin/trainingmembers/checkin`, {
              TrainingMemberId: id
            })
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchMembers();
              },
              x => {}
            );
        }
      });
    },
    removeCheckin(id) {
      var t = "确定要删除他的签到吗?";
      this.$confirm(t).then(x => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/trainingmembers/checkin/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchMembers();
              },
              x => {}
            );
        }
      });
    },
    deleteMember(id) {
      var t = "确定要从培训中删除他吗?";
      this.$confirm(t).then(x => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/trainingplans/join/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchMembers();
              },
              x => {}
            );
        }
      });
    },
    openJoinDialog() {
      this.dialogSet.open = true;
    },
    join(vm) {
      if (!this.$refs.addForm.validate()) return;
      var t = `确定将${vm.xingming}加入培训吗?`;
      this.$confirm(t).then(x => {
        if (x) {
          this.$hc
            .req()
            .post(`admin/trainingplans/join`, {
              TraningPlanId: this.id,
              MemberId: vm.id
            })
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.dialogSet.open = false;
                this.fetchMembers();
              },
              x => {}
            );
        }
      });
    }
  }
};
</script>
    