<template>
  <div>
    <v-card>
      <v-card-title>
        <small>专题课程</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.created="props">{{
            props.item.created | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.op="props">
            <v-btn
              color="secondary"
              x-small
              :to="`/topiclecturevideos/${props.item.id}`"
              >开始学习</v-btn
            >
          </template>
          <template v-slot:no-data>没有视频</template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],

  data() {
    return {
      q: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      coursewares: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "标题",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "created",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "学习",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    articleurl(id) {
      return this.$router.resolve({ name: "Article", params: { id: id } }).href;
    },
    search() {
      this.fetchCoursewares();
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `topics?type=3&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
    