<template>
  <v-dialog v-model="open" persistent max-width="800px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">报名</span>
        </v-card-title>
        <v-card-subtitle class="pb-0">
          请连接好摄像头, 选择你的摄像头。确保您的面部正对摄像头, 然后点击[拍照]。<br />
          右侧会出现拍摄的照片, 系统正确识别出人脸后, [报名]按钮将变为可用状态,
          如按钮扔不可用请调整您的面部姿态重新点击[拍照]直至[报名]按钮可用,
          然后点击[报名]按钮进行报名。 该照片将用于进入考试的面部识别参照,
          请务必谨慎操作。
        </v-card-subtitle>
        <v-card-text>
          <v-row align="center">
            <v-col cols="6">
              <v-select
                :items="devices"
                v-model="deviceId"
                label="选择摄像头"
                item-text="name"
                item-value="id"
                @change="startVideo"
              ></v-select>
            </v-col>
            <v-col cols="6"
              ><v-btn @click="capture" :disabled="(deviceId || '') == ''"
                >拍照</v-btn
              ></v-col
            >
          </v-row>

          <v-row align="center">
            <v-col cols="6">
              <video ref="_video" @playing="onVideoPlay" class="video" autoplay></video>
            </v-col>
            <v-col cols="6">
              <canvas ref="_capture" class="video"></canvas>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelDialog">取消</v-btn>
          <v-btn color="warning" :disabled="!canSave" @click="join">报名</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "PreQualificationDialog",
  props: ["data", "open"],
  data() {
    return {
      valid: false,
      canSave: false,
      faceFeature: "",
      deviceId: "",
      devices: [],
    };
  },
  mounted() {},
  methods: {
    reset() {
      this.devices = [];
      this.deviceId = "";
      this.canSave = false;
      if (this.$refs && this.$refs._capture) {
        this.$refs._capture.width = 0;
        this.$refs._capture.height = 0;
      }
    },
    onVideoPlay() {
      this.$refs._capture.width = this.$refs._video.clientWidth;
      this.$refs._capture.height = this.$refs._video.clientHeight;
    },
    capture() {
      var fapi = this.$face;
      this.$refs._capture
        .getContext("2d")
        .drawImage(
          this.$refs._video,
          0,
          0,
          this.$refs._video.clientWidth,
          this.$refs._video.clientHeight
        );
      this.$face._api
        .detectSingleFace(this.$refs._capture)
        .withFaceLandmarks()
        .withFaceDescriptor()
        .then((x) => {
          if (x) {
            this.faceFeature = fapi.saveFaceFeature(x.descriptor);
            this.canSave = true;
          } else {
          }
        });
    },
    listDevices() {
      this.reset();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.devices.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream = null;
          });
        })
        .catch((x) => {});
    },
    cancelDialog() {
      this.closeCamera();
      this.$emit("update:open", false);
    },
    closeCamera() {
      if (window.__stream) {
        window.__stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
    startVideo() {
      this.closeCamera();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: self.deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream = x;
          self.$refs._video.srcObject = x;
        })
        .catch((error) => {});
    },
    join() {
      var data = {
        qualificationid: this.data.id,
        facefeature: this.faceFeature,
        img: this.$refs._capture.toDataURL("image/jpeg"),
      };
      this.$hc
        .req()
        .post(`qualifications/join`, data)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "报名成功",
        })
        .subscribe(
          (x) => {
            this.closeCamera();
            this.$emit("success");
            this.$emit("update:open", false);
          },
          (x) => {}
        );
    },
  },
};
</script>
<style scoped>
.video {
  max-width: 400px;
  max-height: 400px;
}
</style>
