<template>
  <div>
    <v-card>
      <v-card-title>
        <small>课件管理</small>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加课件</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑课件</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteCourseware(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除课件</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有课件</template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="课件名称"
                    v-model="dialogSet.vm.data.name"
                    :rules="rules.mingchengRules"
                    prepend-icon="payment"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-textarea
                    name="hack-chrome"
                    label="课件简介"
                    :rows="3"
                    v-model="dialogSet.vm.data.description"
                    :rules="rules.descriptionRules"
                    prepend-icon="subject"
                    autocomplete="false"
                    :counter="500"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12>
                  <v-file-input
                    label="课件上传"
                    v-model="dialogSet.vm.data.file"
                    accept=".zip"
                    :rules="rules.videoRules"
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialogSet.vm.data = {};
                dialogSet.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="createCourseware"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      q: "",
      loading: true,
      dialogSet: {
        detailsOpen: false,
        details: {},
        open: false,
        title: "",
        isedit: false,
        vm: {
          valid: false,
          data: {},
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      coursewares: {},
      rules: {
        mingchengRules: [(v) => !!v || "请填写课件名称"],
        descriptionRules: [(v) => !!v || "请填写课件简介"],
        videoRules: [(v) => !!v || "请选择上传课件压缩包"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "课件名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课件简介",
          value: "description",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchCoursewares();
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/coursewares?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },

    openEditDialog(vm) {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "编辑课件";
      this.dialogSet.isedit = true;
      this.rules.videoRules = [];
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.dialogSet.vm.data, vm);
      this.dialogSet.open = true;
    },
    openAddDialog() {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "添加课件";
      this.dialogSet.showVideo = false;
      this.dialogSet.isedit = false;
      this.rules.videoRules = [(v) => !!v || "请填选择上传课件压缩包"];
      this.dialogSet.vm.data = {};

      this.dialogSet.open = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    createCourseware() {
      if (!this.$refs.addForm.validate()) return;
      if (this.dialogSet.vm.data.id) {
        var fe = new FormData();
        fe.append("name", this.dialogSet.vm.data.name);
        fe.append("id", this.dialogSet.vm.data.id);
        fe.append("description", this.dialogSet.vm.data.description);
        if (this.dialogSet.vm.data.file) {
          fe.append("file", this.dialogSet.vm.data.file);
        }
        this.$hc
          .req()
          .put(`admin/coursewares`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      } else {
        var fd = new FormData();
        fd.append("name", this.dialogSet.vm.data.name);
        fd.append("description", this.dialogSet.vm.data.description);
        fd.append("file", this.dialogSet.vm.data.file);
        this.$hc
          .req()
          .post(`admin/coursewares`, fd)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      }
    },
    deleteCourseware(id) {
      this.$confirm("确定要删除这个课件? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/coursewares/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchCoursewares();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
    