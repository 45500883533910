<template>
  <v-app id="inspire" style="min-width: 1100px">
    <v-row style="height: 50px; background: #0e7fb7; max-height: 50px">
      <v-container style="height: 50px; background: #0e7fb7">
        <v-row style="height: 50px; background: #0e7fb7">
          <v-btn
            text
            x-large
            style="background-color: #ffffff"
            color="#0c7fb8"
            to="/information"
            >技能考核管理系统</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text large color="#ffffff" to="/">系统首页</v-btn>
          <v-btn text large color="#ffffff" @click="gologin()">系统登录</v-btn>
          <v-btn text large color="#ffffff" to="/dashboard">培训通知</v-btn>
          <v-btn text large color="#ffffff" to="/topiclectures">专题课程</v-btn>
          <v-btn
            text
            large
            color="#ffffff"
            @click="goto('http://hbepb.hebei.gov.cn/index.html')"
            >中心网站</v-btn
          >
        </v-row>
      </v-container>
    </v-row>
    <div id="header">
      <div class="top_hebhb">
        <h2>
          <a href="https://www.hbem.org.cn/" target="_blank"
            ><img src="images/logo.png" alt=""
          /></a>
        </h2>
        <div class="pic_gzdt" style="float: right; height: 100px">
          <div id="turn" class="turn">
            <a href="https://www.hbem.org.cn/" style="cursor: default" target="_blank"
              ><img src="images/slogan.png"
            /></a>
            <div class="turn-bg"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav" style="height: 5px"></div>
    <v-main style="background-color: #f2f2f2">
      <router-view></router-view>

      <v-row style="background-color: #f2f2f2">
        <v-container>
          <v-row style="background-color: #f2f2f2">
            <div class="wrap" style="padding-top: 50px; width: 1100px; margin: 0px auto">
              <div
                class="about_link1"
                style="
                  height: 65px;
                  border: 1px solid #005ca7;
                  padding-right: 10px;
                  padding-left: 10px;
                  text-align: center;
                "
              >
                <div>
                  <span style="float: left; margin-top: 10px">
                    <a
                      href="http://hbepb.hebei.gov.cn/"
                      target="_blank"
                      style="font-size: 15px; font-family: 微软雅黑"
                      >河北省生态环境厅</a
                    >
                  </span>
                  <span
                    class="fl"
                    style="margin-left: 25px; float: left; margin-top: 10px"
                  >
                    <a
                      href="http://www.mee.gov.cn/"
                      target="_blank"
                      style="font-size: 15px; font-family: 微软雅黑"
                      >生态环境部</a
                    >
                  </span>
                  <span
                    class="fl"
                    style="margin-left: 25px; float: left; margin-top: 10px"
                  >
                    <a
                      href="http://www.cnemc.cn/"
                      target="_blank"
                      style="font-size: 15px; font-family: 微软雅黑"
                      >中国环境监测总站</a
                    >
                  </span>
                  <span
                    class="fl"
                    style="margin-left: 25px; float: left; margin-top: 10px"
                  >
                    <a
                      href="http://www.secmep.cn/"
                      target="_blank"
                      style="font-size: 15px; font-family: 微软雅黑"
                      >生态环境部卫星环境应用中心</a
                    >
                  </span>
                  <span
                    class="fl"
                    style="margin-left: 25px; float: left; margin-top: 10px"
                  >
                    <a
                      href="http://www.hebei.gov.cn/"
                      target="_blank"
                      style="font-size: 15px; font-family: 微软雅黑"
                      >河北省人民政府</a
                    >
                  </span>
                </div>
              </div>
              <div class="about_other" style="margin-top: 30px">
                <ul>
                  <li></li>
                  <li>
                    <a
                      onclick="return href=filterXSS('/Stationmaster?id=eab7da0241124378860cb18b89279b50')"
                      href="javascript:void(0);"
                      >领导致辞</a
                    >
                  </li>
                  <li>
                    <a
                      onclick="return href=filterXSS('/Low?id=691d4b856f56477bbed66effb24821db')"
                      href="javascript:void(0);"
                      >法律声明</a
                    >
                  </li>
                  <li class="li_rightborder">
                    <a
                      onclick="return href=filterXSS('/contact')"
                      href="javascript:void(0);"
                      >联系我们</a
                    >
                  </li>
                </ul>
              </div>
              <div class="footer">
                <div class="imgdiv1">
                  <img src="images/flogo.png" />
                </div>
                <div class="imgdiv2">
                  <img src="images/blue.png" />
                </div>
                <div class="imgdiv3">
                  <ul>
                    <li>
                      <img
                        src="images/hbhberwei02.jpg"
                        alt=""
                        style="width: 70px; height: 70px"
                      />
                      <p>官方微信</p>
                    </li>
                    <li>
                      <img
                        src="images/hbAQIzx02.jpg"
                        alt=""
                        style="width: 70px; height: 70px"
                      />
                      <p>AQI在线</p>
                    </li>
                  </ul>
                  <br />

                  <div style="margin-top: -22px">
                    主办：河北省生态环境监测中心&nbsp;&nbsp;&nbsp;&nbsp;
                    联系电话：0311-89253300 <br />
                    地址：河北省石家庄市裕华区雅清街30号 邮政编码：050000<br />
                    Copyright © 2018 CNEMC
                    <a target="_blank" href="http://www.beian.miit.gov.cn"
                      ><font>冀ICP备20000227号-1</font></a
                    >&nbsp;&nbsp;<br />
                    <!-- <a target="_blank" href="http://www.beian.miit.gov.cn"><font>信息产业部备案管理系统</font></a><br> -->
                    <span style="color: #0199e4"
                      >日访问：1455&nbsp;&nbsp;总访问：1314871 &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-row>
    </v-main>
    <v-snackbar
      v-model="msg.open"
      :bottom="!msg.top"
      :color="msg.color || 'success'"
      :left="msg.left"
      :multi-line="msg.ml"
      :right="msg.right"
      :timeout="msg.timeout || 5000"
      :top="msg.top"
      :vertical="false"
    >
      {{ msg.text }}
      <v-btn dark text @click="msg.open = false">关闭</v-btn>
    </v-snackbar>
    <div v-if="loading" class="loading">Loading&#8230;</div>
  </v-app>
</template>

<style scoped>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.5) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.5) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
p,
span,
th,
td {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none outside;
}

a img {
  border: 0;
}

img {
  vertical-align: middle;
}

body {
  /*margin: 0px auto;
	font-size:15px;
	line-height:130%;
	color: #000;*/
  font-family: "微软雅黑";
}

a:link {
  color: #000;
  text-decoration: none;
}

a:visited {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #0182c2;
  text-decoration: none;
}

a:active {
  color: #111;
  text-decoration: none;
}

.clear {
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

#header {
  width: 100%;
  height: 164px;
  text-align: left;
  overflow: hidden;
  background: url(./assets/header.jpg) center top no-repeat;
  background-size: 100%;
}

#header .top_hebhb {
  width: 1200px;
  margin: 0 auto;
}

#header h2 {
  width: 560px;
  float: left;
  padding-top: 40px;
}

#header h4 {
  float: right;
  text-align: right;
  width: 600px;
  padding: 0;
}

.turn {
  width: 452px;
  margin-top: 30px;
  height: 195px;
}

.turn-pic li img {
  width: inherit;
  height: inherit;
}

.turn-bg {
  background: none;
}

#nav {
  width: 100%;
  margin: 0 auto;
  height: 44px;
  background: #0199e4;
}

#footer {
  width: 100%;
  background: #474e61;
  color: #b3b5bb;
  line-height: 24px;
  padding: 20px 0 20px 0;
  clear: both;
  font-size: 13px;
}

.fotleft {
  width: 640px;
  float: left;
}

.fotleft img {
  float: left;
  margin-right: 10px;
}

.fotleft p {
  float: left;
}

.fotleft p span {
  color: #0199e4;
}

.footercon {
  width: 1200px;
  margin: 0 auto;
}

.footercon ul li {
  float: left;
  width: 75px;
  height: 45px;
  text-align: center;
  border-right: 1px solid #363c4c;
  color: #b5b8c0;
}

.footercon ul li a {
  color: #b5b8c0;
}

.footercon ul li a:hover {
  color: #fff;
}

.footercon ul li p {
  line-height: 100%;
}

.footercon ul {
  float: right;
  width: 456px;
  margin-top: 20px;
  position: relative;
}

.footercon ul li.weixinfot:hover .weixinlogofot {
  visibility: visible;
  z-index: 9999;
}

.weixinlogofot {
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  left: -45px;
  visibility: hidden;
}

.wxwbsearch {
  position: relative;
}

.weixinlogoidx {
  position: absolute;
  z-index: 9999;
  top: 31px;
  left: -10px;
  visibility: hidden;
}

.wxwbsearch ul li.weixin:hover .weixinlogoidx {
  visibility: visible;
}

.footerlink {
  width: 100%;
  height: 60px;
  line-height: 40px;
  padding-bottom: 20px;
}

.footerlink p {
  width: 1200px;
  padding: 12px;
  margin: 0 auto;
  text-align: center;
  background-color: #d4eefa;
}

.footerlink p span {
  margin: 0 10px;
}

.footerlink p span select {
  border: none;
}

.nav {
  width: 100%;
  margin: 0 auto;
  height: 50px;
  background: #0e7fb7;
  position: relative;
  z-index: 99;
}

.menubox01 {
  width: 1100px;
  margin: 0 auto;
  height: 50px;
}

.nav .list {
  line-height: 50px;
  text-align: center;
  height: 50px;
}

.nav .list li {
  float: left;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-left: 1px solid #34ade9;
}

.nav .list li.last {
  float: left;
  border-right: none;
}

.nav .list li.first {
  float: left;
}

.nav .list li a {
  background: url(./assets/menu_icon.png) 20px -2px no-repeat;
}

.nav .list li a:hover {
  background-color: #0182c2;
  color: #fff;
}

.nav .list a {
  color: #fff;
}

.nav .list a:link {
  color: #fff;
}

.nav .list a:visited {
  color: #fff;
}

.nav .list a:active {
  color: #fff;
}

.nav .list a:hover {
  cursor: pointer;
}

.nav .list a:hover,
.nav .list .now {
  color: #3a88ec;
  height: 44px;
  line-height: 44px;
}

.nav .list li.last a:hover,
.nav .list li.last .now {
  border: none;
}

.nav .list li.first a:hover,
.nav .list li.first .now {
  border: none;
}

/* 主导航样式 */

.menubox01 > p {
  width: 131px;
  float: left;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-left: 1px solid #34ade9;
  margin: 0;
}

.menubox01 > p > a {
  display: block;

  padding-left: 32px;
  color: #fff;
  background: url(./assets/menu_icon.png) 30px -1px no-repeat;
}

.menubox01 > p > a:hover {
  background-color: #0182c2;
  color: #fff;
}

.menubox01 > p > a.homeon {
  background-color: #0182c2;
  color: #fff;
}
.nav .list li a {
  width: 100%;
}
.nav .list li {
  width: 135px;
}
.nav .list li.zxgk {
  width: 135px;
}

.nav .list li.zxgk a {
  display: inline-block;
  width: 100%;
  padding-left: 35px;
  background-position: 15px -102px;
}
.nav .list li.gzdt {
  width: 135px;
}

.nav .list li.gzdt a {
  display: inline-block;
  width: 100%;
  padding-left: 35px;
  background-position: 15px -152px;
}

.nav .list li.tzgg a {
  display: inline-block;
  padding-left: 35px;
  background-position: 15px -202px;
}

.nav .list li.hjzl a {
  display: inline-block;
  padding-left: 35px;
  background-position: 15px -254px;
}

.nav .list li.kycx a {
  display: inline-block;
  padding-left: 35px;
  background-position: 15px -302px;
}

.nav .list li.fgbz a {
  display: inline-block;
  padding-left: 35px;
  background-position: 15px -352px;
}

.nav .list li.djgz a {
  display: inline-block;
  padding-left: 35px;
  background-position: 15px -54px;
  border-right: 1px solid #34ade9;
}

.da19 {
  background-image: url(./assets/banner.png);
  background-repeat: no-repeat;
  background-position: center top;
  clear: both;
}

.container {
  margin: 0px auto;
  padding: 0px;
  background-color: white;
}

.ptitle01 {
  text-align: center;
  margin-bottom: 1.5em;
  padding-top: 50px;
}
.ptitle01 .t1 {
  color: #d02c3d;
  font-size: 32px;
}
.ptitle01 .t1 a {
  color: #d02c3d;
}
.ptitle01 .t1 img {
  width: 65px;
}

.ptitle02 {
  border-top: 2px solid #d12c3d;
  text-align: center;
}
.ptitle02 .t1 {
  background-color: #fff;
  position: relative;
  top: -0.8em;
  padding: 0 2em;
  color: #000;
}
.ptitle02 .t1 .sep {
  margin: 0 1ex;
}
.ptitle02 .jl {
  width: 9px;
  height: 7px;
  float: left;
  background: url(./assets/bg001b.png) no-repeat 0 0;
  margin-top: -2px;
}
.ptitle02 .jr {
  width: 9px;
  height: 7px;
  float: right;
  background: url(./assets/bg001a.png) no-repeat 0 0;
  margin-top: -2px;
}
.ptitle02.ptitle02a {
  border-top-color: #b9b9b9;
}
.ptitle02.ptitle02a .jl {
  background-image: url(./assets/bg001d.png);
}
.ptitle02.ptitle02a .jr {
  background-image: url(./assets/bg001c.png);
}

.about_locallink {
  clear: both;
  height: 72px;
  padding-top: 10px;
}

.about_locallink .s_about2 {
  width: 12%;
  height: 20px;
  float: left;
  color: #4db6ff;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.about_locallink .s_linkdiv2 {
  width: 100%;
  float: left;
}

.about_locallink .s_link2 {
  width: 6%;
  padding: 0 10px;
  margin: 0 0 10px 0;
}

.about_other {
  float: left;
  width: 100%;
  height: 38px;
  background-color: #005ca7;
  border-radius: 10px;
}

.about_other ul {
  width: 39%;
  height: 38px;
  margin: 0 auto;
}

.about_other ul li {
  float: left;
  margin: 9px 0;
  padding: 0 20px;
  font-size: 16px;
  list-style: none;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fff;
}

.about_other ul .li_rightborder {
  border-right: none;
}

.about_other ul li a {
  color: #fff;
}

.about_other ul li a:hover {
  color: #fff !important;
}

.footer {
  height: 70px;
  padding: 50px 0px;
}

.footer .imgdiv1 {
  width: 180px;
  float: left;
  padding-top: 18px;
  padding-left: 40px;
}

.footer .imgdiv2 {
  width: 100px;
  float: left;
  padding-top: 10px;
}

.footer .imgdiv3 {
  width: 730px;
  float: right;
  line-height: 24px;
  font-size: 14px;
}

.footer .imgdiv3 ul {
  float: right;
  margin-right: 30px;
}

.footer .imgdiv3 ul li {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
  text-align: center;
}

.nav .box {
  margin: 0 auto;
  top: 44px;
  width: 1100px;
  overflow: hidden;
}

.nav .cont {
  width: 1097px;
  position: relative;
  border-bottom: 1px solid #0199e4;
  border-left: 1px solid #0199e4;
  border-right: 1px solid #0199e4;
  float: left;
  clear: both;
  background: #fff;
}

.nav .cont .menucon {
  height: 55px;
  padding: 0;
  float: left;
  clear: both;
  width: 100%;
}

.nav .cont .menucon a {
  display: inline-block;
  height: 55px;
  line-height: 55px;
  padding: 0 0px;
  width: 149px;
  text-align: center;
  float: left;
}

.nav .cont .menucon2 {
  height: 55px;
  padding: 0 2%;
  float: left;
  clear: both;
  width: 96%;
}

.nav .cont .menucon2 a {
  display: inline-block;
  height: 55px;
  line-height: 55px;
  padding: 0 0px;
  width: 160px;
  text-align: center;
  float: left;
}
</style>
<script>
export default {
  name: "Landing",
  data: () => ({
    menu: "",
    loading: false,
    msg: {
      open: false,
      timeout: 5000,
      color: "success",
      left: false,
      right: false,
      ml: false,
      top: true,
      text: "",
    },
  }),
  mounted() {
    this.$ns.on("snack").subscribe((x) => {
      var opt = {
        open: false,
        timeout: 5000,
        color: "success",
        left: false,
        right: false,
        ml: false,
        top: true,
        text: "",
      };
      Object.assign(opt, x.data);
      this.msg = opt;
      this.msg.open = true;
    });
    this.$ns.on("lock").subscribe((x) => (this.loading = x.data));
  },
  methods: {
    gologin() {
      this.$router.push("/login");
    },
    goto(url) {
      window.location.href = url;
    },
    switchMenu(m) {
      this.menu = m;
    },
  },
};
</script>
