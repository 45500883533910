<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>判卷</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-select
          :items="searchType"
          v-model="searchAll"
          dense
          hide-details
          label="仅显示"
          item-text="id"
          item-value="value"
          @change="fetchData"
        ></v-select>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          dense
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-btn
              x-small
              class="success"
              :to="`/qualifications/${props.item.qmid}/verify`"
              >进入判卷</v-btn
            >
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      searchAll: false,
      searchType: [
        {
          id: "所有",
          value: true,
        },
        {
          id: "尚未判卷",
          value: false,
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},

      headers: [
        {
          text: "考生ID",
          value: "qmid",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "score",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "作弊",
          value: "cheat",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {},

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `qualifications/${this.id}/verifymembers?all=${this.searchAll}&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {
            this.loading = false;
            this.$ns.cast("snack", {
              text: x.Message,
              color: "warning",
            });
            this.$router.replace("/qualifications");
          }
        );
    },
  },
};
</script>
