<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/qualifications">返回</v-btn>
        <v-spacer></v-spacer>
        <small>监考老师管理</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="member"
          :items="members"
          :loading="isLoading"
          :search-input.sync="searchmember"
          item-text="xingming"
          item-value="id"
          label="搜索用户"
          hide-details
          flat
          return-object
          :filter="itemFilter"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>账号: {{ data.item.sfz }}</v-list-item-title>
                <v-list-item-subtitle>
                  姓名:{{ data.item.xingming }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn @click="addMember">添加监考老师</v-btn>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteMember(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除监考老师</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";

export default {
  props: ["id"],
  data() {
    return {
      isLoading: false,
      searchmember: "",
      members: [],
      member: {},
      obs: {
        search$: null,
        searchSub$: null,
      },
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    searchmember(val) {
      this.obs.search$.next(val);
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  mounted() {
    this.fetchData();
    this.obs.search$ = new BehaviorSubject();
    this.obs.searchSub$ = this.obs.search$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading = true;
          return this.$hc
            .req()
            .get(`admin/members/search?pagesize=10000&pageindex=1&q=${x || ""}`)
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading = false;
          this.members = x.Data.Payload;
        },
        (x) => {
          this.isLoading = true;
        }
      );
  },

  methods: {
    itemFilter() {
      return true;
    },
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualificationmembers?id=${this.id}&type=监考&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteMember(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualificationmembers?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
    addMember() {
      if (this.member && this.member.id) {
        this.$hc
          .req()
          .post(`admin/qualificationmembers`, {
            memberid: this.member.id,
            qualificationid: this.id,
            type: "监考",
          })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchData();
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
