<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" @click="back()">返回</v-btn>
        <v-spacer></v-spacer>
        <small>专题课程: {{ topic.title }}</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="topic.videos"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  small
                  icon
                  v-on="on"
                  @click="openDetailsDialog(props.item)"
                >
                  <v-icon small>play_circle_outline</v-icon>
                </v-btn>
              </template>
              <span>播放视频</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有课程</template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.detailsOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogSet.lecture.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <video
                  ref="videoPlayer"
                  class="
                    video-js
                    vjs-default-skin vjs-big-play-centered vjs-fluid
                  "
                  controls="true"
                  preload="metadata"
                ></video>
              </v-flex>
              <v-flex xs12>{{ dialogSet.lecture.description }}</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              pauseVideo();
              dialogSet.lecture = {};
              dialogSet.detailsOpen = false;
            "
            >关闭</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({
    loading: true,
    slides: [],
    topic: {},
    dialogSet: {
      player: null,
      detailsOpen: false,
      lecture: {},
    },

    headers: [
      {
        text: "课程名称",
        value: "name",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "课程简介",
        value: "description",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "课程时长(秒)",
        value: "length",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },

      {
        text: "操作",
        value: "op",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
    ],
  }),

  mounted() {
    this.fetchPlans();
  },
  methods: {
    back() {
      window.history.go(-1);
    },
    fetchPlans() {
      this.loading = true;
      this.$hc
        .req()
        .get(`topics/${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.topic = x.Data;
          },
          (x) => {}
        );
    },
    makeBR(str) {
      if (!str) return "";
      return str.replace(/\n/g, "<br />");
    },
    pauseVideo() {
      if (this.dialogSet.player) {
        this.dialogSet.player.pause();
      }
    },
    openDetailsDialog(vm) {
      this.dialogSet.lecture = vm;
      this.dialogSet.detailsOpen = true;
      if (!this.dialogSet.player) {
        this.$nextTick(() => {
          this.dialogSet.player = this.$video(this.$refs.videoPlayer, {
            language: "zh-CN",
            preload: "metadata",
            controls: true,
          });

          this.dialogSet.player.src({
            src: `${process.env.VUE_APP_VIDEO_ROOT}topicvideos/${vm.filename}`,
            type: "video/mp4",
          });
        });
      } else {
        this.dialogSet.player.src({
          src: `${process.env.VUE_APP_VIDEO_ROOT}topicvideos/${vm.filename}`,
          type: "video/mp4",
        });
      }
    },
  },
};
</script>
