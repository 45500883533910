import Vue from 'vue'
import Router from 'vue-router'
import App from '../App.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ChangePassword from "../views/ChangePassword.vue";
import UserProfile from "../views/UserProfile.vue";
import Home from "../views/Home.vue";
import MyTraining from "../views/MyTraining.vue";
import MyLectures from "../views/Lectures.vue";
import MemberList from '../views/admin/MemberList.vue'
import MemberProfile from '../views/admin/MemberProfile.vue'
import SystemMessage from '../views/admin/SystemMessage.vue'
import Managers from '../views/admin/Managers.vue'
import TrainingPlans from '../views/admin/TrainingPlans.vue'
import TrainingMembers from '../views/admin/TrainingMembers.vue'
import AdminFeedBacks from '../views/admin/Feedbacks.vue'
import AdminAchievements from '../views/admin/Achievements.vue'
import AdminTrainingLectures from '../views/admin/TrainingLectures.vue'
import AdminTrainingExams from '../views/admin/TrainingExams.vue'
import MyExams from '../views/Exams.vue'
import MyAllLectures from '../views/MyAllLectures.vue'
import moment from "moment"
import Information from "../views/Information.vue"
import AdminCourseware from "../views/admin/Coursewares.vue"
import Courseware from "../views/Coursewares.vue"
import AdminExaminations from "../views/admin/Examinations.vue"
import Examinations from "../views/Examinations.vue"
import AdminExaminationResults from "../views/admin/ExaminationResults.vue"
import AdminOpinions from "../views/admin/Opinions.vue"
import AdminTopics from "../views/admin/Topics.vue"
import Topics from "../views/Topics.vue"
import Article from "../views/Article.vue"
import Experts from "../views/Experts.vue"
import MyTopicLectures from "../views/MyTopicLectures.vue"
import AdminTopicLectures from "../views/admin/TopicLectures.vue"
import AdminTopicLectureVideos from "../views/admin/TopicLectureVideos.vue"
import MyTopicLectureVideos from "../views/MyTopicLectureVideos.vue"
import AdminQualifications from "../views/admin/Qualifications.vue"
import AdminQualificationQuizList from "../views/admin/QualificationQuizList.vue"
import Qualifications from "../views/Qualifications.vue"
import QualificationStart from "../views/Qualification.vue"
import AdminQualificationMemberList from "../views/admin/QualificationMemberList.vue"
import AdminQualificationVerifierList from "../views/admin/QualificationVerifierList.vue"
import AdminQualificationMonitorList from "../views/admin/QualificationMonitorList.vue"
import QualificationMonitor from "../views/QualificationMonitor.vue"
import VerifyQualificationMembers from "../views/VerifyQulificationMembers.vue"
import VerifyQualification from "../views/ValidateQualification.vue"
import AdminPreviewQualification from "../views/admin/QualificationPreview.vue"

Vue.use(Router)

var r = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: App,
      children: [
        {
          path: '/',
          name: 'activelectures',
          component: MyAllLectures
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Home
        },
        {
          path: '/experts',
          name: 'experts',
          component: Experts
        },
        {
          path: '/userprofile',
          name: 'userprofile',
          component: UserProfile
        },
        {
          path: '/changepassword',
          name: 'changepassword',
          component: ChangePassword
        },
        {
          path: '/mytraining/:type',
          name: 'mytraining',
          component: MyTraining,
          props: true
        },
        {
          path: '/mytraining/:id/lectures',
          name: 'mylectures',
          component: MyLectures,
          props: true
        },
        {
          path: '/training/:id/exam',
          name: 'myexam',
          component: MyExams,
          props: true
        },
        {
          path: '/coursewares',
          name: 'coursewares',
          component: Courseware,
        },
        {
          path: '/examinations',
          name: 'examinations',
          component: Examinations,
        },
        {
          path: '/topics/:id',
          name: 'topics',
          component: Topics,
          props: true
        },
        {
          path: '/topiclectures',
          name: 'topiclectures',
          component: MyTopicLectures
        },
        {
          path: '/topiclecturevideos/:id',
          name: 'topiclecturevideos',
          component: MyTopicLectureVideos,
          props: true
        },
        {
          path: '/qualifications',
          name: 'qualifications',
          component: Qualifications,
          props: true
        },
        {
          path: '/qualifications/:id/start',
          name: 'qualification_start',
          component: QualificationStart,
          props: true
        },
        {
          path: '/qualifications/:id/monitor',
          name: 'qualification_monitor',
          component: QualificationMonitor,
          props: true
        }        ,
        {
          path: '/qualifications/:id/members',
          name: 'qualification_members',
          component: VerifyQualificationMembers,
          props: true
        },
        {
          path: '/qualifications/:qualificationmemberid/verify',
          name: 'qualification_verify',
          component: VerifyQualification,
          props: true
        }
      ]
    },
    {
      path: '/',
      component: App,
      children: [
        {
          path: '/admin/userlist',
          name: 'admin_memberlist',
          component: MemberList
        },
        {
          path: '/admin/userprofile/:id',
          name: 'admin_userprofile',
          component: MemberProfile,
          props: true
        },
        {
          path: '/admin/systemmessage',
          name: 'admin_systemmessage',
          component: SystemMessage
        },
        {
          path: '/admin/managers',
          name: 'admin_managers',
          component: Managers
        },
        {
          path: '/admin/trainingplans',
          name: 'admin_trainingplans',
          component: TrainingPlans
        },
        {
          path: '/admin/trainingmembers/:id',
          name: 'admin_trainingtembers',
          component: TrainingMembers,
          props: true
        },
        {
          path: '/admin/feedbacks',
          name: 'admin_feedbacks',
          component: AdminFeedBacks
        },
        {
          path: '/admin/achievements/:id',
          name: 'admin_achievements',
          component: AdminAchievements,
          props: true
        },
        {
          path: '/admin/traininglectures/:id',
          name: 'admin_traininglectures',
          component: AdminTrainingLectures,
          props: true
        },
        {
          path: '/admin/trainingexams/:id',
          name: 'admin_trainingexams',
          component: AdminTrainingExams,
          props: true
        },
        {
          path: '/admin/coursewares',
          name: 'admin_coursewares',
          component: AdminCourseware
        },
        {
          path: '/admin/examinations',
          name: 'admin_examinations',
          component: AdminExaminations
        },
        {
          path: '/admin/examinations/:id/results',
          name: 'admin_examinationresults',
          component: AdminExaminationResults,
          props: true
        },
        {
          path: '/admin/opinions',
          name: 'admin_opinions',
          component: AdminOpinions
        },
        {
          path: '/admin/topics/:id',
          name: 'admin_topics',
          component: AdminTopics,
          props: true
        },
        {
          path: '/admin/topiclectures',
          name: 'admin_topiclectures',
          component: AdminTopicLectures
        },
        {
          path: '/admin/topiclectures/:id/videos',
          name: 'admin_topiclecturevideoss',
          component: AdminTopicLectureVideos,
          props: true
        },
        {
          path: '/admin/qualifications',
          name: 'admin_qualifications',
          component: AdminQualifications
        },
        {
          path: '/admin/qualifications/:id/quiz',
          name: 'admin_qualificationquiz',
          component: AdminQualificationQuizList,
          props: true
        },
        {
          path: '/admin/qualifications/:id/members',
          name: 'admin_qualification_members',
          component: AdminQualificationMemberList,
          props: true
        },
        {
          path: '/admin/qualifications/:id/verifiers',
          name: 'admin_qualification_verifiers',
          component: AdminQualificationVerifierList,
          props: true
        },
        {
          path: '/admin/qualifications/:id/monitors',
          name: 'admin_qualification_monitors',
          component: AdminQualificationMonitorList,
          props: true
        },
        {
          path: '/admin/qualifications/:id/preview',
          name: 'admin_qualification_preview',
          component: AdminPreviewQualification,
          props: true
        },
      ]
    },
    {
      path: '/Login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
      component: Login
    },
    {
      path: '/Register',
      name: 'Register',
      component: Register
    },
    {
      path: '/Information',
      name: 'Information',
      component: Information
    },
    {
      path: '/article/:id',
      name: 'Article',
      component: Article,
      props: true
    }
  ]
})

r.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/register' || to.path == '/information') {
    next();
  } else {
    const tk = Vue.ls.get("token", null, String);
    if (tk) {
      try {
        let u = JSON.parse(tk);
        var now = moment();
        var issue = moment(u.IssueDate || '1900-01-01');
        var d = now.diff(issue, "minutes");
        if (d > (u.ExpiredIn || 720)) {
          if (from.path == '/information') next("/login");
          else next("/information");
        } else {
          if (to.path.startsWith('/admin') && !u.IsAdmin) {
            Vue.ns.cast('snack', { text: '你没有权限这么做', color: 'error' });
            next("/");
          } else {
            next();
          }
        }
      } catch (ex) {
        next("/information");
      }
    } else {
      if (from.path == '/information') next("/login");
      else next("/information");
    }
  }
})

export default r;