<template>
  <div>    
    <v-card>
      <v-toolbar>
        <v-toolbar-title>系统公告</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加公告</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:item.expired="props">{{props.item.expired | moment('LL')}}</template>
          <template v-slot:expanded-item=" props ">
            <td :colspan="5" >
              <v-card style="margin:15px">
                <v-card-title>内容</v-card-title>
                <v-card-text v-html="makeBR(props.item.content)"></v-card-text>
              </v-card>
            </td>
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item) "
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑公告</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteMessage(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有系统公告</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages" :total-visible="10"></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addDialog" persistent max-width="600px">
      <v-form ref="addForm" v-model="addVM.valid" lazy-validation autocomplete="random-string">
        <v-card>
          <v-card-title>
            <span class="headline">{{addDialogTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="标题"
                    v-model="addVM.message.title"
                    :rules="rules.titleRules"
                    prepend-icon="title"
                    autocomplete="false"
                    :counter="20"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    name="hack-chrome"
                    v-model="addVM.message.content"
                    :rules="rules.contentRules"
                    prepend-icon="message"
                    :counter="200"
                    label="内容"
                    auto-grow
                    :rows="3"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    ref="expiredDialog"
                    v-model="expiredDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="addVM.message.expired"
                        label="过期时间"
                        prepend-icon="event"
                        :rules="rules.expiredRules"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addVM.message.expired"
                      no-title
                      @input="expiredDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addVM.message={};addDialog = false">取消</v-btn>
            <v-btn color="warning" :disabled="!addVM.valid" @click="createMessage">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      expanded: [],
      loading: true,
      addDialog: false,
      addDialogTitle: "",
      expiredDialog: false,
      addVM: {
        valid: false,
        message: {}
      },
      editVM: {
        valid: false,
        message: {}
      },
      rules: {
        titleRules: [
          v => !!v || "请填写标题",
          v =>
            (v && v.length <= 20 && v.length >= 3) ||
            "标题长度必须在3到20个字符之间"
        ],
        contentRules: [
          v => !!v || "请填写内容",
          v =>
            (v && v.length <= 200 && v.length >= 6) ||
            "内容长度必须在6到200个字符之间"
        ],
        expiredRules: [
          v => !!v || "请填写过期时间",
          v => {
            var n = this.$moment();
            var c = this.$moment(v);
            return c.diff(n, "days") >= 0 || "过期时间必须大于今天";
          }
        ]
      },
      pagination: {
        page: 1,
        rowsPerPage: 15
      },
      data: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "标题",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        // {
        //   text: "内容",
        //   value: "content",
        //   align: "center",
        //   sortable: false,
        //   class: "data-table-header"
        // },
        {
          text: "过期时间",
          value: "expired",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header"
        }
      ]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchMessages();
      },
      deep: true
    }
  },
  mounted() {
    this.fetchMessages();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    }
  },
  methods: {
    fetchMessages() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/systemmessages?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
            this.data = x.Data;
          },
          x => {}
        );
    },
    deleteMessage(id) {
      this.$confirm("确定要删除这条公告吗? 该操作无法恢复").then(x => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/systemmessages/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchMessages();
              },
              x => {}
            );
        }
      });
    },
    createMessage() {
      if (!this.$refs.addForm.validate()) return;
      if (this.addVM.message.id && this.addVM.message.id > 0) {
        this.$hc
          .req()
          .put(`admin/systemmessages`, this.addVM.message)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功"
          })
          .subscribe(
            x => {
              this.fetchMessages();
              this.addVM.message = {};
              this.addDialog = false;
            },
            x => {}
          );
      } else {
        this.$hc
          .req()
          .post(`admin/systemmessages`, this.addVM.message)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功"
          })
          .subscribe(
            x => {
              if (this.pagination.page == 1) {
                this.fetchMessages();
              } else {
                this.pagination.page = 1;
              }
              this.addVM.message = {};
              this.addDialog = false;
            },
            x => {}
          );
      }
    },
    openEditDialog(vm) {
      this.addDialogTitle = "编辑公告";
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.addVM.message, vm);
      this.addVM.message.expired = this.$moment(
        this.addVM.message.expired
      ).format("YYYY-MM-DD");
      this.addDialog = true;
    },
    openAddDialog() {
      this.addDialogTitle = "添加公告";
      this.addDialog = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    makeBR(str){
      return str.replace(/\n/g,'<br />');
    }
  }
};
</script>
