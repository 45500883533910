<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/trainingplans">返回</v-btn>
        <v-spacer></v-spacer>学员成绩
        <v-spacer></v-spacer>
      </v-card-title>
      <v-toolbar>
        <v-text-field
          label="姓名"
          clearable
          v-model="q"
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.progress="props">
            <v-progress-linear
              :value="props.item.progress"
              color="blue"
              height="20"
            >
              <template v-slot="{ value }">{{ value.toFixed(2) }}%</template>
            </v-progress-linear>
          </template>
          <template v-slot:item.resettime="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  x-small
                  v-on="on"
                  @click.native.stop="resetExamTime(props.item.id)"
                >
                  重置
                </v-btn>
              </template>
              <span>修改成绩</span>
            </v-tooltip>
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="
                    openChengjiDialog(props.item.id, props.item.totalscore)
                  "
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>修改成绩</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>尚未有人员参加培训</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogSet.vm.data.totalscore"
                  :rules="rules.scoreRules"
                  label="成绩"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogSet.open = false">取消</v-btn>
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="editChengji(dialogSet.vm.data)"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],
  data() {
    return {
      search$: null,
      sub$: null,
      q: "",
      loading: true,
      dialogSet: {
        open: false,
        title: "设置成绩",
        isLoading: false,
        items: ["合格", "其他"],
        vm: {
          valid: false,
          data: {
            chengji: "合格",
          },
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      rules: {
        scoreRules: [(v) => !isNaN(v * 1) || "填写成绩"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "身份证",
          value: "sfz",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "学习进度",
          value: "progress",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "totalscore",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "重置考试时间",
          value: "resettime",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "修改成绩",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchMembers();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchMembers();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    search() {
      this.fetchMembers();
    },
    fetchMembers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/trainingmembers/${this.id}?pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    openChengjiDialog(id, score) {
      this.dialogSet.vm.data.id = id;
      this.dialogSet.vm.data.totalscore = score;
      this.dialogSet.open = true;
    },
    editChengji(vm) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .put(`admin/trainingmembers/achievement`, {
          id: vm.id,
          chengji: "",
          chengjibeizhu: "",
          totalscore: this.dialogSet.vm.data.totalscore,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.open = false;
            this.fetchMembers();
          },
          (x) => {}
        );
    },
    displayChengji(vm) {
      if (vm.chengji == "合格") return vm.chengji;
      if (vm.chengji == "其他") return vm.chengjibeizhu;
      return "";
    },
    resetExamTime(id) {
      this.$hc
        .req()
        .put(`admin/trainingmembers/resetexamtime`, {
          id: id,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.open = false;
            this.fetchMembers();
          },
          (x) => {}
        );
    },
  },
};
</script>
    