<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{ qualification.name }}</v-toolbar-title>
    </v-toolbar>
    <v-card style="margin-top: 15px">
      <v-card-text>
        <qualification-quiz
          v-for="(quiz, i) in qualification.Quizs"
          :key="i"
          :quiz="quiz"
        ></qualification-quiz>
      </v-card-text>
      <v-card-actions v-if="started">
        <v-spacer> </v-spacer>
        <v-btn @click="backtolist">返回</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      started: false,
      qinfo: {},
      qualification: {
        quizs: [],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchQuiz();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    fetchQuiz() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/qualificationquizs/${this.id}/preview`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.started = true;
            this.qualification = x.Data.Payload;
          },
          (x) => {}
        );
    },
    backtolist() {
      this.$router.replace(`/admin/qualifications/${this.id}/quiz`);
    },
  },
};
</script>
