var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('small',[_vm._v("在线考试管理")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openQualificationEditDialog(null)}}},[_vm._v("添加")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.Payload,"loading":_vm.loading,"hide-default-footer":"","server-items-length":_vm.data.Total,"item-key":"id","fixed-header":true},scopedSlots:_vm._u([{key:"item.start",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.start,"YYYY-MM-DD HH:mm")))]}},{key:"item.joinstart",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.joinstart,"YYYY-MM-DD")))]}},{key:"item.joinend",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.joinend,"YYYY-MM-DD")))]}},{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openQualificationEditDialog(props.item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteQualification(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("删除考试")])])]}},{key:"item.quiz",fn:function(props){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" 管理 ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":"","to":("/admin/qualifications/" + (props.item.id) + "/quiz")},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-content',[_vm._v("考试题目")])],1),_c('v-list-item',{attrs:{"dense":"","to":("/admin/qualifications/" + (props.item.id) + "/members")},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-content',[_vm._v("参考考生")])],1),_c('v-list-item',{attrs:{"dense":"","to":("/admin/qualifications/" + (props.item.id) + "/monitors")},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-content',[_vm._v("监考老师")])],1),_c('v-list-item',{attrs:{"dense":"","to":("/admin/qualifications/" + (props.item.id) + "/verifiers")},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-content',[_vm._v("阅卷老师")])],1),_c('v-list-item',{attrs:{"dense":"","disabled":props.item.status != '阅卷结束'},on:{"click":function($event){$event.stopPropagation();return _vm.publishResult(props.item)}}},[_c('v-list-item-content',[_vm._v("发布成绩")])],1)],1)],1)]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('qualification-edit-dialog',{ref:"_edit_qualification",attrs:{"open":_vm.qualificationEdit},on:{"update:open":function($event){_vm.qualificationEdit=$event},"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }