<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-btn text color="secondary" to="/admin/trainingplans"
                >返回</v-btn
              >
              <v-spacer></v-spacer>
              <small
                >考试设置:
                {{ traingingplan ? traingingplan.mingcheng : "" }}</small
              >
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-form ref="examForm" autocomplete="false" v-model="descValid">
                <v-container>
                  <v-row>
                    <v-flex xs12>
                      <v-textarea
                        name="hack-chrome"
                        label="考试说明"
                        :rows="3"
                        v-model="traingingplan.description"
                        autocomplete="false"
                        :counter="200"
                        :rules="rules.descRules"
                      ></v-textarea>
                    </v-flex>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        label="考试时长"
                        v-model="traingingplan.duration"
                        :rules="rules.durationRules"
                        suffix="(分钟)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                @click="saveExam()"
                :disabled="!descValid"
                >保存</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openAddDialog">添加考题</v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="traingingplan.questions"
                :loading="loading"
                hide-default-footer
                disable-pagination
                item-key="id"
                :fixed-header="true"
              >
                <template v-slot:item.op="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        small
                        icon
                        v-on="on"
                        @click.native.stop="openEditDialog(props.item)"
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>编辑考题</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="error"
                        small
                        icon
                        v-on="on"
                        @click.native.stop="deleteQuestion(props.item.id)"
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>删除考题</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>没有考题</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogSet.add.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.add.vm.valid"
        lazy-validation
        dense
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.add.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="题目"
                    :rows="3"
                    v-model="dialogSet.add.vm.title"
                    autocomplete="false"
                    :counter="500"
                    :rules="rules.titleRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="题目序号"
                    v-model="dialogSet.add.vm.ord"
                    :rules="rules.ordRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="题目分值"
                    v-model="dialogSet.add.vm.score"
                    :rules="rules.scoreRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="题目类型"
                    :items="questionTypes"
                    v-model="dialogSet.add.vm.type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                align="center"
                style="padding: 0px 12px"
                v-if="dialogSet.add.vm.type === '选择题'"
              >
                <v-checkbox
                  v-model="dialogSet.add.vm.arrayanswer"
                  hide-details
                  value="A"
                  class="shrink mr-2 mt-0"
                  :rules="rules.arrayanswerRules"
                ></v-checkbox>
                <v-text-field
                  label="选项A"
                  v-model="dialogSet.add.vm.optionsObj.A"
                  :rules="rules.aRules"
                ></v-text-field>
              </v-row>
              <v-row
                align="center"
                style="padding: 0px 12px"
                v-if="dialogSet.add.vm.type === '选择题'"
              >
                <v-checkbox
                  v-model="dialogSet.add.vm.arrayanswer"
                  hide-details
                  value="B"
                  class="shrink mr-2 mt-0"
                  :rules="rules.arrayanswerRules"
                ></v-checkbox>
                <v-text-field
                  label="选项B"
                  v-model="dialogSet.add.vm.optionsObj.B"
                  :rules="rules.bRules"
                ></v-text-field>
              </v-row>
              <v-row
                align="center"
                style="padding: 0px 12px"
                v-if="dialogSet.add.vm.type === '选择题'"
              >
                <v-checkbox
                  v-model="dialogSet.add.vm.arrayanswer"
                  hide-details
                  value="C"
                  class="shrink mr-2 mt-0"
                  :rules="rules.arrayanswerRules"
                ></v-checkbox>
                <v-text-field
                  label="选项C"
                  v-model="dialogSet.add.vm.optionsObj.C"
                  :rules="rules.cRules"
                ></v-text-field>
              </v-row>
              <v-row
                align="center"
                style="padding: 0px 12px"
                v-if="dialogSet.add.vm.type === '选择题'"
              >
                <v-checkbox
                  v-model="dialogSet.add.vm.arrayanswer"
                  hide-details
                  value="D"
                  class="shrink mr-2 mt-0"
                  :rules="rules.arrayanswerRules"
                ></v-checkbox>
                <v-text-field
                  label="选项D"
                  v-model="dialogSet.add.vm.optionsObj.D"
                  :rules="rules.dRules"
                ></v-text-field>
              </v-row>
              <v-row align="center" v-if="dialogSet.add.vm.type === '填空题'">
                <v-col cols="12">
                  <v-text-field
                    label="正确答案"
                    v-model="dialogSet.add.vm.textanswer"
                    :rules="rules.textanswerRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialogSet.add.vm = {};
                dialogSet.add.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.add.vm.valid"
              @click="saveQuestion"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],
  data() {
    return {
      q: "",
      descValid: false,
      questionTypes: ["选择题", "填空题"],
      loading: true,
      dialogSet: {
        add: {
          open: false,
          title: "",
          isEdit: false,
          vm: {
            valid: false,
            type: "选择题",
            optionsObj: {},
            ord: 1,
            score: 10,
            arrayanswer: [],
            textanswer: "",
          },
        },
        details: {
          open: false,
          vm: {},
        },
      },
      traingingplan: {
        questions: [],
      },
      rules: {
        descRules: [(v) => !!v || "请填写考试说明"],
        titleRules: [(v) => !!v || "请填写题目"],
        durationRules: [(v) => v * 1 > 0 || "请填写考试时长"],
        ordRules: [(v) => /^\d+$/.test((v || "") + "") || "请填写考题序号"],
        scoreRules: [(v) => /^\d+$/.test((v || "") + "") || "请填题目分值"],
        aRules: [(v) => !!v || "请填写选项A"],
        bRules: [(v) => !!v || "请填写选项B"],
        cRules: [(v) => !!v || "请填写选项C"],
        dRules: [(v) => !!v || "请填写选项D"],
        arrayanswerRules: [
          (v) =>
            this.dialogSet.add.vm.type === "填空题" ||
            (v && v.length > 0) ||
            "请至少选择一个选项作为正确答案",
        ],
        textanswerRules: [
          (v) =>
            this.dialogSet.add.vm.type === "选择题" || !!v || "请填写正确答案",
        ],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目序号",
          value: "ord",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目分值",
          value: "score",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchExam();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchExam();
  },

  methods: {
    fetchExam() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/exams/${this.id}`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            x.Data.questions = x.Data.questions || [];
            this.traingingplan = x.Data;
          },
          (x) => {}
        );
    },
    saveExam() {
      if (!this.$refs.examForm.validate()) return;
      this.$hc
        .req()
        .post(`admin/exams`, {
          planid: this.id,
          description: this.traingingplan.description,
          duration: this.traingingplan.duration,
        })
        .go()
        .subscribe((x) => {
          this.traingingplan.examid = x.Data;
        });
    },
    openAddDialog() {
      if (!this.$refs.examForm.validate()) return;
      if (!this.traingingplan.examid && !this.traingingplan.description) {
        this.$ns.cast("snack", { text: "请先填写考试说明", color: "error" });
        return;
      }
      this.dialogSet.add.title = "添加考题";
      this.dialogSet.add.isEdit = false;
      var maxOrd = 0;
      for (var i = 0; i < this.traingingplan.questions.length; i++) {
        var q = this.traingingplan.questions[i];
        if (q.ord > maxOrd) {
          maxOrd = q.ord;
        }
      }
      maxOrd++;
      this.dialogSet.add.vm = {
        valid: false,
        type: "选择题",
        optionsObj: {},
        ord: maxOrd,
        score: 10,
        arrayanswer: [],
        textanswer: "",
      };
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      if (!this.traingingplan.examid) {
        this.$hc
          .req()
          .post(`admin/exams`, {
            planid: this.id,
            description: this.traingingplan.description,
          })
          .go()
          .subscribe((x) => {
            this.traingingplan.examid = x.Data;
            this.dialogSet.add.open = true;
          });
      } else {
        this.dialogSet.add.open = true;
      }
    },
    openEditDialog(vm) {
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      this.dialogSet.add.title = "修改考题";
      this.dialogSet.add.isEdit = true;
      this.dialogSet.add.vm = {
        valid: false,
        id: vm.id,
        title: vm.title,
        type: vm.type,
        optionsObj: {},
        ord: vm.ord,
        score: vm.score,
        arrayanswer: [],
        textanswer: "",
      };
      if (vm.type === "选择题") {
        this.dialogSet.add.vm.optionsObj = JSON.parse(vm.options);
        this.dialogSet.add.vm.arrayanswer = vm.answer.split(",");
      } else {
        this.dialogSet.add.vm.textanswer = vm.answer;
      }
      this.dialogSet.add.open = true;
    },
    deleteQuestion(id) {
      this.$confirm("确定要删除这个考题吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/exams/questions/${id}`)
            .get(`admin/exams/${this.id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.loading = false;
                this.traingingplan = x.Data;
              },
              (x) => {}
            );
        }
      });
    },
    saveQuestion() {
      if (!this.$refs.addForm.validate()) return;

      var q = {
        examid: this.traingingplan.examid,
        score: this.dialogSet.add.vm.score,
        title: this.dialogSet.add.vm.title,
        type: this.dialogSet.add.vm.type,
        ord: this.dialogSet.add.vm.ord,
      };

      if (q.type === "选择题") {
        q.options = JSON.stringify(this.dialogSet.add.vm.optionsObj);
        q.answer = this.dialogSet.add.vm.arrayanswer.join(",");
      } else {
        q.options = "";
        q.answer = this.dialogSet.add.vm.textanswer;
      }
      if (!this.dialogSet.add.isEdit) {
        this.$hc
          .req()
          .post(
            `admin/exams/questions`,
            q,
            (x) => (this.traingingplan = x.Data)
          )
          .get(`admin/exams/${this.id}`)
          .go()
          .subscribe((x) => {
            this.loading = false;
            this.traingingplan = x.Data;
            this.dialogSet.add.open = false;
          });
      } else {
        q.id = this.dialogSet.add.vm.id;
        this.$hc
          .req()
          .put(`admin/exams/questions`, q, (x) => (this.traingingplan = x.Data))
          .get(`admin/exams/${this.id}`)
          .go()
          .subscribe((x) => {
            this.loading = false;
            this.traingingplan = x.Data;
            this.dialogSet.add.open = false;
          });
      }
    },
  },
};
</script>
    