<template>
  <div>
    <v-card>
      <v-card-title>
        <small>{{ title }}</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.created="props">{{
            props.item.created | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  small
                  icon
                  v-on="on"
                  :href="articleurl(props.item.id)"
                  target="_blank"
                >
                  <v-icon small>remove_red_eye</v-icon>
                </v-btn>
              </template>
              <span>浏览文章</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有文章</template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],

  data() {
    return {
      q: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      coursewares: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "标题",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "created",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "浏览文章",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    title() {
      if (this.id == 0) {
        return "政策法规";
      } else if (this.id == 1) {
        return "环保标准";
      } else if (this.id == 2) {
        return "环境质量报告书";
      }
      return "";
    },
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    articleurl(id) {
      return this.$router.resolve({ name: "Article", params: { id: id } }).href;
    },
    search() {
      this.fetchCoursewares();
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `topics?type=${this.id}&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
    