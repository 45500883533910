<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          系统广播
          <span style="font-size:14px;" class="grey--text text--darken-1">点击查看详情</span>
        </span>
      </v-card-title>
      <v-card-text>
        <v-carousel cycle height="50" hide-delimiters :show-arrows-on-hover="true">
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet color="white" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-btn
                  text
                  class="grey--text text--darken-1"
                  style="width:80%"
                  @click="dialogSet.messageOpen=true;dialogSet.message=slide"
                >{{ slide.title }}</v-btn>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
    <div style="height:20px;"></div>
    <v-card>
      <v-card-title>近期培训</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="trainings.Payload"
          :server-items-length="trainings.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
        >
          <template v-slot:item.baomingstart="props">{{props.item.baomingstart | moment('LL')}}</template>
          <template v-slot:item.baomingend="props">{{props.item.baomingend | moment('LL')}}</template>
          <template v-slot:item.bm="props">
            <v-btn
              color="success"
              x-small
              v-if="!props.item.baoming && canBaoming(props.item)"
              @click="baoming(props.item.id)"
            >报名</v-btn>
            <span v-if="props.item.baoming">已报名</span>
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  small
                  icon
                  v-on="on"
                  @click="openDetailsDialog(props.item)"
                >
                  <v-icon small>remove_red_eye</v-icon>
                </v-btn>
              </template>
              <span>查看培训计划</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有培训计划</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages" :total-visible="10"></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.detailsOpen" max-width="600px">
      <plain-details :details="dialogSet.training" />
    </v-dialog>
    <v-dialog v-model="dialogSet.messageOpen" max-width="600px">
      <v-card>
        <v-card-title>{{dialogSet.message.title}}</v-card-title>
        <v-card-text v-html="makeBR(dialogSet.message.content)"></v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    slides: [],
    trainings: {},
    dialogSet: {
      detailsOpen: false,
      messageOpen: false,
      training: {},
      message: {}
    },
    pagination: {
      page: 1,
      rowsPerPage: 15
    },
    headers: [
      {
        text: "ID",
        value: "id",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "培训名称",
        value: "mingcheng",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "培训类型",
        value: "peixunleixing",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "课程数量",
        value: "lecturecount",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "报名开始时间",
        value: "baomingstart",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "报名结束时间",
        value: "baomingend",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "报名",
        value: "bm",
        align: "center",
        sortable: false,
        class: "data-table-header"
      },
      {
        text: "操作",
        value: "op",
        align: "center",
        sortable: false,
        class: "data-table-header"
      }
    ]
  }),
  watch: {
    pagination: {
      handler() {
        this.fetchPlans();
      },
      deep: true
    }
  },
  computed: {
    pages() {
      if (this.trainings.Total) {
        return Math.ceil(this.trainings.Total / this.pagination.rowsPerPage);
      }
      return 0;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$hc
        .req()
        .get(`systemmessages`, x => (this.slides = x.Data))
        .get(
          `trainingplans?type=recent&pagesize=${this.pagination.rowsPerPage}&pageindex=1`,
          x => (this.trainings = x.Data)
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
          },
          x => {
            this.loading = false;
            this.trainings = { Payload: [] };
          }
        );
    },
    fetchPlans() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `trainingplans?type=recent&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
            this.trainings = x.Data;
          },
          x => {}
        );
    },
    makeBR(str) {
      if (!str) return "";
      return str.replace(/\n/g, "<br />");
    },
    canBaoming(vm) {
      var now = this.$moment(this.$moment().format("YYYY-MM-DD"));
      var start = this.$moment(vm.baomingstart);
      return now.diff(start, "days") >= 0;
    },
    baoming(id) {
      this.$confirm("确定要报名参加该培训吗?").then(x => {
        if (x) {
          this.$hc
            .req()
            .post(`trainingplans/baoming/${id}`, {})
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "报名成功"
            })
            .subscribe(
              x => {
                this.fetchPlans();
              },
              x => {}
            );
        }
      });
    },
    openDetailsDialog(vm) {
      this.dialogSet.training = vm;
      this.dialogSet.detailsOpen = true;
    }
  }
};
</script>
