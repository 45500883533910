<template>
  <v-dialog v-model="open" persistent max-width="800px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">添加新用户</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="vm.sfz"
            :rules="rules.sfzRules"
            prepend-icon="person"
            autocomplete="false"
            :counter="20"
            label="账户(身份证号)"
            type="text"
            required
          ></v-text-field>
          <v-text-field
            v-model="vm.name"
            :rules="rules.nameRules"
            prepend-icon="person"
            autocomplete="false"
            :counter="20"
            label="姓名"
            type="text"
            required
          ></v-text-field>
          <v-text-field
            v-model="vm.gongzuodanwei"
            prepend-icon="apartment"
            autocomplete="false"
            :counter="50"
            label="工作单位"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="vm.zhiwu"
            prepend-icon="supervisor_account"
            autocomplete="false"
            :counter="20"
            label="职务"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="vm.mobile"
            prepend-icon="phonelink_ring"
            autocomplete="false"
            :counter="20"
            label="联系电话"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="vm.password"
            autocomplete="new-password"
            :rules="rules.pass1Rules"
            prepend-icon="lock"
            :counter="50"
            label="密码"
            type="password"
          ></v-text-field>
          <v-text-field
            v-model="vm.password2"
            autocomplete="new-password"
            :rules="[confirmPass]"
            prepend-icon="lock"
            :counter="50"
            label="确认密码"
            type="password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="success" :disabled="!valid" @click="createMember">创建</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateMemberDialog",
  props: ["open"],
  data() {
    return {
      vm: {},
      valid: false,
      rules: {
        sfzRules: [(v) => !!v || "请填写账户(身份证号)"],
        nameRules: [
          (v) => !!v || "请填写姓名",
          (v) => (v && v.length <= 5 && v.length >= 1) || "姓名长度必须在1到5个字符之间",
        ],
        pass1Rules: [
          (v) => !!v || "请填写密码",
          (v) =>
            (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
        ],
      },
    };
  },

  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认密码";
      return value === this.vm.password || "密码不一致";
    },
    createMember() {
      if (!this.$refs.form.validate()) return;
      this.$hc
        .req()
        .post(`admin/members/create`, this.vm)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.vm = {};
            this.$emit("success");
            this.$emit("update:open", false);
          },
          (x) => {}
        );
    },
  },
};
</script>
