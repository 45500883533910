<template>
  <div>    
    <v-card>
      <v-toolbar>
        <v-toolbar-title>培训反馈</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
          :single-expand="true"
        >
         
          <template v-slot:item.op="props">
           
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteFeedback(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有反馈</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages" :total-visible="10"></v-pagination>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15
      },
      data: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "培训计划",
          value: "planname",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "学员姓名",
          value: "membername",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "意见",
          value: "yijian",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header"
        }
      ]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchFeedbacks();
      },
      deep: true
    }
  },
  mounted() {
    this.fetchFeedbacks();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    }
  },
  methods: {
    fetchFeedbacks() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/trainingplans/feedbacks?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
            this.data = x.Data;
          },
          x => {}
        );
    },
    deleteFeedback(id) {
      this.$confirm("确定要删除这条反馈吗? 该操作无法恢复").then(x => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/trainingplans/feedbacks/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchFeedbacks();
              },
              x => {}
            );
        }
      });
    },
    makeBR(str){
      return str.replace(/\n/g,'<br />');
    }
  }
};
</script>
