<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="6" md="3" v-for="l in lectures" :key="l.id">
        <v-card class="mx-auto">
          <v-img
            class="white--text align-end"
            height="200px"
            :src="l.img"
          ></v-img>

          <v-card-subtitle class="pb-1">{{ l.planname }}</v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ l.name }}</div>

            <div>{{ l.description }}</div>
          </v-card-text>

          <v-card-actions>
            <v-progress-linear :value="l.progress" color="blue" height="20">
              <template v-slot="{ value }">{{ value.toFixed(2) }}%</template>
            </v-progress-linear>
            <v-spacer></v-spacer>
            <v-btn color="orange" text @click="openDetailsDialog(l)"
              >去学习</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogSet.detailsOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogSet.lecture.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <video
                  ref="videoPlayer"
                  class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid"
                  controls="true"
                  preload="metadata"
                ></video>
              </v-flex>
              <v-flex xs12>{{ dialogSet.lecture.description }}</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              pauseVideo();
              dialogSet.lecture = {};
              dialogSet.detailsOpen = false;
            "
            >关闭</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    <script>
import { BehaviorSubject, of } from "rxjs";
import { throttleTime, filter, map, catchError, tap } from "rxjs/operators";
export default {
  data() {
    return {
      search$: null,
      sub$: null,
      loading: false,
      lectures: [],
      dialogSet: {
        player: null,
        detailsOpen: false,
        lecture: {},
      },
    };
  },
  mounted() {
    this.fetchLectures();
    this.search$ = new BehaviorSubject();
    this.sub$ = this.search$
      .asObservable()
      .pipe(
        throttleTime(30000),
        filter((x) => x > 0),
        map((x) => {
          return {
            lectureid: this.dialogSet.lecture.id,
            planid: this.dialogSet.lecture.planid,
            progress: x.toFixed(0),
          };
        }),
        tap((x) => {
          this.$http.put(`lectures/progress`, x).then(
            (x) => {
              if (x.data && x.data.Success) {
                this.dialogSet.lecture.progress = x.data.Data;
              }
            },
            (x) => {}
          );
        }),
        catchError((err) => {
          return of({
            data: {
              Success: false,
            },
          });
        })
      )
      .subscribe(
        (x) => {},
        (x) => {}
      );
  },

  methods: {
    back() {
      window.history.go(-1);
    },
    fetchLectures() {
      this.loading = true;
      this.$hc
        .req()
        .get(`lectures/all`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;

            for (var i = 0; i < x.Data.length; i++) {
              x.Data[
                i
              ].img = `${process.env.VUE_APP_VIDEO_ROOT}${x.Data[i].img}`;
            }
            this.lectures = x.Data;
          },
          (x) => {}
        );
    },
    pauseVideo() {
      if (this.dialogSet.player) {
        this.dialogSet.player.pause();
      }
    },
    dealProcessBar(vm) {
      if (this.dialogSet.player) {
        if (vm.progress == 100 || vm.position == vm.length) {
          this.dialogSet.player.controlBar.progressControl.enable();
        } else {
          this.dialogSet.player.controlBar.progressControl.disable();
        }
      }
    },
    trackProgress(vm) {
      if (this.dialogSet.player) {
        var me = this;
        if (vm.progress == 100 || vm.position == vm.length) {
          this.dialogSet.player.off("timeupdate");
          this.dialogSet.player.off("ended");
        } else {
          this.dialogSet.player.on("timeupdate", function (x) {
            me.search$.next(this.currentTime());
          });
          this.dialogSet.player.on("ended", function (evy) {
            me.$http
              .put(`lectures/progress`, {
                lectureid: vm.id,
                planid: vm.planid,
                end: true,
              })
              .then(
                (x) => {
                  if (x.data && x.data.Success) {
                    me.dialogSet.lecture.progress = 100;
                  }
                },
                (x) => {}
              );
          });
        }
      }
    },
    openDetailsDialog(vm) {
      this.$hc
        .req()
        .post(`lectures/trending?id=${vm.id}`, {})
        .go({
          lock: false,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {},
          (x) => {}
        );
      this.dialogSet.lecture = vm;
      this.dialogSet.detailsOpen = true;
      if (!this.dialogSet.player) {
        this.$nextTick(() => {
          this.dialogSet.player = this.$video(this.$refs.videoPlayer, {
            language: "zh-CN",
            preload: "metadata",
            controls: true,
          });
          this.dealProcessBar(vm);

          this.dialogSet.player.src({
            src: `${process.env.VUE_APP_VIDEO_ROOT}${vm.filename}`,
            type: "video/mp4",
          });
          if (this.dialogSet.lecture.position) {
            this.dialogSet.player.currentTime(this.dialogSet.lecture.position);
          }
          this.trackProgress(vm);
        });
      } else {
        this.dealProcessBar(vm);
        this.dialogSet.player.src({
          src: `${process.env.VUE_APP_VIDEO_ROOT}${vm.filename}`,
          type: "video/mp4",
        });
        if (this.dialogSet.lecture.position) {
          this.dialogSet.player.currentTime(this.dialogSet.lecture.position);
        }
        this.trackProgress(vm);
      }
    },
  },
};
</script>
    