<template>
  <div>
    <v-row style="background-color: #f2f2f2">
      <v-container>
        <table class="container">
          <tr>
            <td>
              <img
                style="width: 120px; height: 120px"
                src="../../public/images/sysintro.jpg"
                alt=""
              />
            </td>
            <td>
              <p
                style="padding-top: 20px; color: #3d82c5; white-space: pre-wrap"
                v-html="
                  '河北省生态环境监测中心-技能考核培训系统：<br />&nbsp;&nbsp;&nbsp;&nbsp;通过信息化网络技术，线上培训与线下培训相结合，使全省监测人员对专业技能与知识进行更高效的学习培训。加强我省生态环境监测质量管理工作，掌握全省生态环境监测机构能力水平，促进学习专业理论、钻研业务技术的积极性，推动生态环境监测队伍技术水平的提高，对弘扬生态环境监测领域工匠精神，提高各级各类生态环境监测机构和人员技术水平，打造生态环境保护铁军先锋队具有重要作用。'
                "
              ></p>
            </td>
          </tr>
        </table>
      </v-container>
    </v-row>
    <v-row style="background-color: #98cc44">
      <v-container style="padding-top: 0px">
        <v-row>
          <span class="fw subtitle">最新培训</span>
          <v-spacer></v-spacer>
          <v-btn text color="#FFFFFF" large to="/dashboard">更多</v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="i in dashboard.noty" :key="i.id">
              <v-card class="mx-auto" style="background-color: #98cc44">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="i.img"
                  @click="gologin()"
                ></v-img>
                <table class="fw">
                  <tr>
                    <td width="75">培训类型:</td>
                    <td>{{ i.plantype }}</td>
                  </tr>
                  <tr>
                    <td>培训名称:</td>
                    <td>{{ i.planname }}</td>
                  </tr>

                  <tr>
                    <td>培训讲师:</td>
                    <td>{{ i.jiangshi }}</td>
                  </tr>
                  <tr>
                    <td>培训时间:</td>
                    <td>{{ i.start }}</td>
                  </tr>
                </table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>

    <v-row style="background-color: #5cc2d0">
      <v-container style="padding-top: 0px">
        <v-row>
          <span class="fw subtitle" style="background-color: #53afbc">专题课程</span>
          <v-spacer></v-spacer>
          <v-btn text color="#FFFFFF" large to="/topiclectures">更多</v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="i in dashboard.topic2" :key="i.id">
              <v-card class="mx-auto" style="background-color: #98cc44">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="i.img"
                  @click="gologin()"
                ></v-img>
                <table class="fw">
                  <tr>
                    <td>课程名称:</td>
                    <td>{{ i.title }}</td>
                  </tr>
                  <tr>
                    <td>创建时间:</td>
                    <td>{{ i.created }}</td>
                  </tr>
                </table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>

    <v-row style="background-color: #0e7fb7">
      <v-container style="padding-top: 0px">
        <v-row>
          <span class="fw subtitle" style="background-color: #0d6fa0">政策法规</span>
          <v-spacer></v-spacer>
          <v-btn text color="#FFFFFF" large to="/topics/0">更多</v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12">
              <table
                class="articlelist"
                width="100%"
                border="0"
                cellspacing="0"
                cellspending="0"
              >
                <tr v-for="i in dashboard.topic0" :key="i.id">
                  <td>
                    <a :href="articleurl(i.id)" target="_blank">{{ i.title }}</a>
                  </td>
                  <td width="200px">
                    {{ i.created | moment("YYYY-MM-DD HH:mm") }}
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>
    <v-row style="background-color: #5cc2d0">
      <v-container style="padding-top: 0px">
        <v-row>
          <span class="fw subtitle" style="background-color: #53afbc">环境标准</span>
          <v-spacer></v-spacer>
          <v-btn text color="#FFFFFF" large to="/topics/1">更多</v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12">
              <table
                class="articlelist"
                width="100%"
                border="0"
                cellspacing="0"
                cellspending="0"
              >
                <tr v-for="i in dashboard.topic1" :key="i.id">
                  <td>
                    <a :href="articleurl(i.id)" target="_blank">{{ i.title }}</a>
                  </td>
                  <td width="200px">
                    {{ i.created | moment("YYYY-MM-DD HH:mm") }}
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>
    <v-row style="background-color: #f2f2f2">
      <v-container style="padding-top: 0px">
        <v-row style="background-color: #f2f2f2">
          <v-col col="4" style="padding: 5px">
            <v-card flat>
              <v-system-bar color="#f2f2f2">
                <v-icon>trending_up</v-icon>
                <span style="color: #000000; font-size: 12pt">课程排行榜</span>
              </v-system-bar>
              <v-list flat dense color="#f2f2f2">
                <v-list-item-group color="primary">
                  <v-list-item v-for="i in dashboard.trending" :key="i.id">
                    <v-list-item-icon>
                      <v-avatar color="orange" size="20">
                        <span class="white--text" style="font-size: 9pt">{{ i.id }}</span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="i.name"></v-list-item-title>

                      <v-list-item-subtitle
                        style="text-align: right"
                        v-text="`${i.gongzuodanwei || ''} ${i.count}课时`"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col col="4" style="padding: 5px">
            <v-card flat>
              <v-system-bar color="#f2f2f2">
                <v-icon>school</v-icon>
                <span style="color: #000000">热门知识</span>
              </v-system-bar>
              <v-list flat dense color="#f2f2f2">
                <v-list-item-group color="primary">
                  <v-list-item v-for="i in dashboard.hot" :key="i.id">
                    <v-list-item-icon>
                      <v-avatar color="orange" size="20">
                        <span class="white--text" style="font-size: 9pt">{{ i.id }}</span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="i.name"></v-list-item-title>

                      <v-list-item-subtitle
                        style="text-align: right"
                        v-text="`${i.viewed}人次`"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col col="4" style="padding: 5px">
            <v-card flat>
              <v-system-bar color="#f2f2f2">
                <v-icon>trending_up</v-icon>
                <span style="color: #000000">学时排行榜</span>
              </v-system-bar>
              <v-list flat dense color="#f2f2f2">
                <v-list-item-group color="primary">
                  <v-list-item v-for="i in dashboard.study" :key="i.id">
                    <v-list-item-icon>
                      <v-avatar color="orange" size="20">
                        <span class="white--text" style="font-size: 9pt">{{ i.id }}</span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="i.gongzuodanwei"></v-list-item-title>

                      <v-list-item-subtitle
                        style="text-align: right"
                        v-text="
                          `总学习时间: ${i.hours}小时 ${i.minutes}分钟 ${i.seconds}秒`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<style scoped>
.v-list-item__icon {
  margin-right: 0px !important;
}
.fw {
  color: #ffffff;
}
table.fw tr td {
  color: #ffffff;
  padding: 2px;
}

.subtitle {
  display: block;
  height: 65px;
  width: 100px;
  background-color: #89b73d;
  line-height: 65px;
  text-align: center;
  font-size: 22px;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.articlelist tr td {
  color: #ffffff;
}
.articlelist tr {
  height: 35px;
}

.articlelist tr td a {
  color: #ffffff;
  font-size: 16px;
  outline-style: none;
  text-decoration: none;
  cursor: pointer;
}
</style>

<script>
export default {
  data: () => ({
    valid: false,
    vm: {
      account: "",
      password: "",
    },
    dashboard: {},
    rules: {
      nameRules: [(v) => !!v || "请填写用户名"],
      passRules: [(v) => !!v || "请填写密码"],
    },
  }),
  mounted() {
    this.fetchDashboard();
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;
      this.$hc
        .req()
        .post(
          "members/login",
          { sfz: this.vm.account, password: this.vm.password },
          (x) => {
            this.$ls.set("token", JSON.stringify(x.Data));
          }
        )
        //.get('api/configurations/default', x => this.ns.cast('ms:cfg_default', x.Data))
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "登陆成功",
        })
        .subscribe(
          (x) => this.$router.push("/"),
          (x) => {
            this.$ns.cast("snack", {
              text: "用户名或密码错误",
              color: "error",
            });
          }
        );
    },
    articleurl(id) {
      return this.$router.resolve({ name: "Article", params: { id: id } }).href;
    },
    gologin() {
      this.$router.push("/login");
    },
    fetchDashboard() {
      this.loading = true;
      this.$hc
        .req()
        .get(`public/dashboard?notificationcount=4&topiccount=4`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            for (var i = 0; i < x.Data.noty.length; i++) {
              x.Data.noty[
                i
              ].img = `${process.env.VUE_APP_VIDEO_ROOT}${x.Data.noty[i].img}`;
            }
            for (i = 0; i < x.Data.topic0.length; i++) {
              x.Data.topic0[
                i
              ].img = `${process.env.VUE_APP_VIDEO_ROOT}${x.Data.topic0[i].img}`;
            }
            for (i = 0; i < x.Data.topic2.length; i++) {
              x.Data.topic2[
                i
              ].img = `${process.env.VUE_APP_VIDEO_ROOT}topicvideos/${x.Data.topic2[i].img}`;
            }
            this.dashboard = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
