<template>
  <v-container fluid style="background-color: #f2f2f2">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md3 style="padding-top: 100px; padding-bottom: 25px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>注册</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" to="/login" icon large slot="activator">
                  <v-icon small>登录</v-icon>
                </v-btn>
              </template>
              <span>已有账户</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="vm.sfz"
                :rules="rules.sfzRules"
                prepend-icon="person"
                autocomplete="false"
                :counter="20"
                label="账户(身份证号)"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.name"
                :rules="rules.nameRules"
                prepend-icon="person"
                autocomplete="false"
                :counter="20"
                label="姓名"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.gongzuodanwei"
                :rules="rules.danweiRules"
                prepend-icon="apartment"
                autocomplete="false"
                :counter="50"
                label="工作单位"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.zhiwu"
                :rules="rules.zhiwuRules"
                prepend-icon="supervisor_account"
                autocomplete="false"
                :counter="20"
                label="职务"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.mobile"
                :rules="rules.phoneRules"
                prepend-icon="phonelink_ring"
                autocomplete="false"
                :counter="20"
                label="联系电话"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.password"
                autocomplete="new-password"
                :rules="rules.pass1Rules"
                prepend-icon="lock"
                :counter="50"
                label="密码"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="vm.password2"
                autocomplete="new-password"
                :rules="[confirmPass]"
                prepend-icon="lock"
                :counter="50"
                label="确认密码"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="register">注册</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    vm: {
      sfz: "",
      name: "",
      password: "",
      password2: "",
    },
    rules: {
      sfzRules: [
        (v) => !!v || "请填写账户(身份证号)",
        (v) =>
          (v && v.length <= 20 && v.length >= 15) ||
          "账户(身份证号)长度必须在15到20个字符之间",
        (v) => /^[0-9]+([a-zA-Z])?$/.test(v) || "账户(身份证号)不正确",
      ],
      nameRules: [
        (v) => !!v || "请填写姓名",
        (v) => (v && v.length <= 5 && v.length >= 1) || "姓名长度必须在1到5个字符之间",
      ],

      danweiRules: [
        (v) => !!v || "请填写工作单位",
        (v) =>
          (v && v.length <= 50 && v.length >= 1) || "工作单位长度必须在1到50个字符之间",
      ],
      zhiwuRules: [
        (v) => !!v || "请填写职务",
        (v) => (v && v.length <= 20 && v.length >= 1) || "职务长度必须在1到20个字符之间",
      ],
      phoneRules: [
        (v) => !!v || "请填写联系电话",
        (v) =>
          (v && v.length <= 50 && v.length >= 8) || "联系电话长度必须在8到50个字符之间",
      ],
      pass1Rules: [
        (v) => !!v || "请填写密码",
        (v) => (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
      ],
    },
  }),

  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认密码";
      return value === this.vm.password || "密码不一致";
    },
    register() {
      if (!this.$refs.form.validate()) return;
      this.$hc
        .req()
        .post("members/register", this.vm, (x) => {
          this.$ls.set("token", JSON.stringify(x.Data));
        })
        //.get('api/configurations/default', x => this.ns.cast('ms:cfg_default', x.Data))
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "注册成功",
        })
        .subscribe((x) => this.$router.push("/"));
    },
  },
};
</script>
