<template>
  <v-card>
    <v-card-title>判卷 - {{ data.name }}</v-card-title>
    <v-card-subtitle>考生: {{ data.member_name }}</v-card-subtitle>
    <v-card-text>
      <v-tabs
        background-color="blue-grey lighten-5"
        v-model="tab"
        @change="stopAllVideo()"
      >
        <v-tab>判卷</v-tab>
        <v-tab>切屏({{ data.switchs }})</v-tab>
        <v-tab>考生桌面录像({{ data.desktops }})</v-tab>
        <v-tab>考生摄像头录像({{ data.cameras }})</v-tab>
        <v-tab>面部识别失败记录({{ data.faces }})</v-tab>
        <v-tab>监考视频({{ data.monitor }})</v-tab>
        <v-tab-item>
          <qualification-quiz-verify
            v-for="(quiz, i) in data.quizs"
            :key="i"
            :quiz="quiz"
          ></qualification-quiz-verify>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="data && data.recorddesktop">
            <v-row>
              <v-col cols="6" v-for="l in data.logs_switchs" :key="l.id">
                <v-img :src="`${fileurl}${l.file}`"></v-img>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="data && !data.recorddesktop">
            <p>本次考试未开启考生录屏, 仅记录考试切屏次数为: {{ data.switchs }} 次</p>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="data">
            <v-row>
              <v-col cols="6" v-for="l in data.logs_desktops" :key="l.id">
                <video
                  @playing="onVideoPlaying"
                  :src="`${fileurl}${l.file}`"
                  style="width: 100%"
                  controls="true"
                  preload="auto"
                ></video>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="data">
            <v-row>
              <v-col cols="6" v-for="l in data.logs_cameras" :key="l.id">
                <video
                  @playing="onVideoPlaying"
                  :src="`${fileurl}${l.file}`"
                  style="width: 100%"
                  controls="true"
                  preload="auto"
                ></video>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="data">
            <v-row>
              <v-col cols="6" v-for="l in data.logs_faces" :key="l.id">
                <v-img :src="`${fileurl}${l.file}`"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="data">
            <v-row>
              <v-col cols="6" v-for="l in data.logs_monitor" :key="l.id">
                <video
                  @playing="onVideoPlaying"
                  :src="`${fileurl}${l.file}`"
                  style="width: 100%"
                  controls="true"
                  preload="auto"
                ></video>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" :to="`/qualifications/${data.id}/members`">返回列表</v-btn>
      <v-spacer></v-spacer>
      <span class="text-h6 text--primary">最终成绩: {{ total_score }}</span>
      <v-spacer></v-spacer>
      <v-switch
        style="margin-right: 10px"
        v-model="data.cheat"
        inset
        :label="`${data.cheat ? '作弊' : '未作弊'}`"
      ></v-switch>
      <v-btn color="primary" @click="submitResult(false)">提交成绩</v-btn>
      <v-btn color="primary" @click="submitResult(true)">提交成绩并开始下一个</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["qualificationmemberid"],
  data() {
    return {
      loading: false,

      tab: 0,
      data: {},
    };
  },
  watch: {
    qualificationmemberid: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.stopAllVideo();
  },
  computed: {
    fileurl() {
      return process.env.VUE_APP_VIDEO_ROOT;
    },
    total_score() {
      if (this.data && !this.data.cheat && this.data.quizs && this.data.quizs.length) {
        var total = 0;
        this.data.quizs.forEach((x) => (total += x.score * 1 || 0));
        return total;
      }
      return 0;
    },
  },
  methods: {
    onVideoPlaying(e) {
      this.stopAllVideo(e.target);
    },
    stopAllVideo(v) {
      var av = document.getElementsByTagName("video");
      if (av && av.length) {
        av.forEach((x) => {
          if (x != v) {
            x.pause();
          }
        });
      }
    },

    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(`qualifications/${this.qualificationmemberid}/verify`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    submitResult(next) {
      this.loading = true;
      this.$hc
        .req()
        .post(`qualifications/verifyanswer`, {
          cheat: this.data.cheat,
          qmid: this.qualificationmemberid,
          Quizs: this.data.quizs.map((m) => {
            return {
              quizid: m.quizid,
              correct: m.verified || false,
              score: m.score || 0,
            };
          }),
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            if (next) {
              if (x.Data.next) {
                this.$router.replace(`/qualifications/${x.Data.next_id}/verify`);
              } else {
                this.$ns.cast("snack", {
                  text: "阅卷已全部完成",
                  color: "success",
                });
                this.$router.replace(`/qualifications/${this.data.id}/members`);
              }
            } else {
              this.$router.replace(`/qualifications/${this.data.id}/members`);
            }
          },
          (x) => {}
        );
    },
  },
};
</script>
