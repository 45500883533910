<template>
  <div>
    <v-card>
      <v-card-title>
        <small>专题课程</small>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.created="props">{{
            props.item.created | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.videos="props">
            <v-btn
              x-small
              color="success"
              :to="`/admin/topiclectures/${props.item.id}/videos`"
              >管理视频</v-btn
            >
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteCourseware(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="1200px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="标题"
                    v-model="dialogSet.vm.data.title"
                    :rules="rules.mingchengRules"
                    prepend-icon="payment"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 style="min-height: 300px">
                  <v-textarea
                    name="hack-chrome"
                    label="简介"
                    :rows="3"
                    v-model="dialogSet.vm.data.content"
                    prepend-icon="subject"
                    autocomplete="false"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialogSet.vm.data = {};
                dialogSet.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="createCourseware"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      q: "",
      loading: true,
      dialogSet: {
        detailsOpen: false,
        details: {},
        open: false,
        title: "",
        isedit: false,
        vm: {
          valid: false,
          data: {},
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      coursewares: {},
      rules: {
        mingchengRules: [(v) => !!v || "请填写标题"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "标题",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "created",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课程视频",
          value: "videos",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchCoursewares();
    },
    articleurl(id) {
      return this.$router.resolve({ name: "Article", params: { id: id } }).href;
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/topics?type=3&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },

    openEditDialog(vm) {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "编辑";
      this.dialogSet.isedit = true;
      this.rules.videoRules = [];
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.dialogSet.vm.data, vm);
      this.dialogSet.open = true;
    },
    openAddDialog() {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "添加";
      this.dialogSet.showVideo = false;
      this.dialogSet.isedit = false;
      //this.rules.videoRules = [(v) => !!v || "请填选择上传课件压缩包"];
      this.dialogSet.vm.data = {};

      this.dialogSet.open = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    createCourseware() {
      if (!this.$refs.addForm.validate()) return;
      if (this.dialogSet.vm.data.id) {
        var fe = new FormData();
        fe.append("id", this.dialogSet.vm.data.id);
        fe.append("title", this.dialogSet.vm.data.title);
        fe.append("type", 3);
        fe.append("content", this.dialogSet.vm.data.content);
        this.$hc
          .req()
          .put(`admin/topics`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      } else {
        var fd = new FormData();
        fd.append("title", this.dialogSet.vm.data.title);
        fd.append("type", 3);
        fd.append("content", this.dialogSet.vm.data.content);
        this.$hc
          .req()
          .post(`admin/topics`, fd)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      }
    },
    deleteCourseware(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/topics/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchCoursewares();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
    