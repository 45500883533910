<template>
  <div>
    <v-card>
      <v-card-title>
        <small>专家库</small>
      </v-card-title>
      <v-toolbar>
        <v-text-field
          label="检索(单位、姓名、身份证、职务、专业)"
          clearable
          v-model="q"
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <div class="expcontainer">
          <table class="experts" border="1">
            <tr>
              <td rowspan="2">序号</td>
              <td rowspan="2">所在单位</td>
              <td rowspan="2">姓名</td>
              <td rowspan="2">身份证号</td>
              <td rowspan="2">职称/职务</td>
              <td rowspan="2">职称专业</td>
              <td rowspan="2" class="break">专业领域工作年限</td>
              <td colspan="3">所在单位专业技术身份</td>
              <!-- <td>1</td>
            <td>1</td> -->
              <td colspan="8">其他已获专家身份</td>
              <!-- <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td> -->
              <td colspan="15">精通专业领域</td>
              <!-- <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td> -->
              <td colspan="11">精通监测工作类别</td>
              <!-- <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td> -->
            </tr>
            <tr>
              <td class="break">机构授权签字人</td>
              <td class="break">机构技术负责人</td>
              <td class="break">机构质量负责人</td>
              <td class="break">国家级资质认定评审员</td>
              <td class="break">CNAS认可评审员</td>
              <td class="break">省级资质认定评审员</td>
              <td class="break">“三五”人才（第几批）</td>
              <td class="break">环境影响评价专家（注明省级、XX市级）</td>
              <td class="break">危险废物专家（注明省级、XX市级）</td>
              <td class="break">土壤专家（注明省级、XX市级）</td>
              <td class="break">其他专家（请注明具体类别、省级、xx市级）</td>
              <td class="break">水和废水</td>
              <td class="break">空气和废气</td>
              <td class="break">土壤和沉积物</td>
              <td class="break">地下水</td>
              <td class="break">噪声和振动</td>
              <td class="break">固体废物</td>
              <td class="break">海洋</td>
              <td class="break">生物类</td>
              <td class="break">生物多样性相关</td>
              <td class="break">水自动站管理</td>
              <td class="break">气自动站管理</td>
              <td class="break">污染源（废水）在线监测</td>
              <td class="break">污染源（废气）在线监测</td>
              <td class="break">非道路移动机械排放</td>
              <td class="break">其他</td>
              <td class="break">水质理化分析</td>
              <td class="break">环境空气、污染源废气分析</td>
              <td class="break">
                大型设备（请注明气相、气质、液相、液质、ICP、原子吸收、X射线荧光光谱仪等）
              </td>
              <td class="break">土壤和沉积物</td>
              <td class="break">危险废物监测</td>
              <td class="break">生物类项目监测分析</td>
              <td class="break">海洋监测分析</td>
              <td class="break">恶臭监测</td>
              <td class="break">噪声振动</td>
              <td class="break">加油站油气回收</td>
              <td class="break">其他</td>
            </tr>
            <tr v-for="e in result" :key="e.name">
              <td>{{ e.id }}</td>
              <td>{{ e.danwei }}</td>
              <td>{{ e.name }}</td>
              <td>{{ e.shenfenzheng }}</td>
              <td>{{ e.zhiwu }}</td>
              <td>{{ e.zhuanye }}</td>
              <td>{{ e.nianxian }}</td>
              <td>{{ e.dic["7"] }}</td>
              <td>{{ e.dic["8"] }}</td>
              <td>{{ e.dic["9"] }}</td>
              <td>{{ e.dic["10"] }}</td>
              <td>{{ e.dic["11"] }}</td>
              <td>{{ e.dic["12"] }}</td>
              <td>{{ e.dic["13"] }}</td>
              <td>{{ e.dic["14"] }}</td>
              <td>{{ e.dic["15"] }}</td>
              <td>{{ e.dic["16"] }}</td>
              <td>{{ e.dic["17"] }}</td>
              <td>{{ e.dic["18"] }}</td>
              <td>{{ e.dic["19"] }}</td>
              <td>{{ e.dic["20"] }}</td>
              <td>{{ e.dic["21"] }}</td>
              <td>{{ e.dic["22"] }}</td>
              <td>{{ e.dic["23"] }}</td>
              <td>{{ e.dic["24"] }}</td>
              <td>{{ e.dic["25"] }}</td>
              <td>{{ e.dic["26"] }}</td>
              <td>{{ e.dic["27"] }}</td>
              <td>{{ e.dic["28"] }}</td>
              <td>{{ e.dic["29"] }}</td>
              <td>{{ e.dic["30"] }}</td>
              <td>{{ e.dic["31"] }}</td>
              <td>{{ e.dic["32"] }}</td>
              <td>{{ e.dic["33"] }}</td>
              <td>{{ e.dic["34"] }}</td>
              <td>{{ e.dic["35"] }}</td>
              <td>{{ e.dic["36"] }}</td>
              <td>{{ e.dic["37"] }}</td>
              <td>{{ e.dic["38"] }}</td>
              <td>{{ e.dic["39"] }}</td>
              <td>{{ e.dic["40"] }}</td>
              <td>{{ e.dic["41"] }}</td>
              <td>{{ e.dic["42"] }}</td>
              <td>{{ e.dic["43"] }}</td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped>
.expcontainer {
  overflow-x: auto;
}
.experts {
  border: solid #000000;
  border-width: 1px 0px 0px 1px;
}
.experts .break {
  white-space: pre-wrap;
}
.experts > tr > td {
  border: solid #000000;
  border-width: 0px 1px 1px 0px;
  padding-left: 1px;
  padding-right: 1px;
}
</style>
 <script>
import filter from "lodash/filter";
export default {
  data() {
    return {
      q: "",
      experts: [
        {
          id: "1",
          danwei: "省中心",
          name: "武桂桃",
          shenfenzheng: "120104196411086343",
          zhiwu: "正高、副总",
          zhuanye: "环境监测",
          nianxian: "34",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "是",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "是",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "液质，ICP,原子吸收等",
            36: "是",
            37: "是",
            38: "是",
            39: "是",
            40: "是",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "2",
          danwei: "省中心",
          name: "党瑞华",
          shenfenzheng: "12010419651205632x",
          zhiwu: "副总工",
          zhuanye: "正高级",
          nianxian: "33",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "3",
          danwei: "省中心",
          name: "邢志贤",
          shenfenzheng: "110108197012049316",
          zhiwu: "正高/副主任",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "否",
            8: "否",
            9: "否",
            10: "否",
            11: "否",
            12: "否",
            13: "第一批",
            14: "否",
            15: "否",
            16: "省级",
            17: "司法鉴定/国家级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "√",
            25: "√",
            26: "√",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "√",
            36: "√",
            37: "",
            38: "√",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "4",
          danwei: "省中心",
          name: "田建立",
          shenfenzheng: "130104196701101515",
          zhiwu: "高工",
          zhuanye: "环境工程",
          nianxian: "20",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "",
            14: "省级",
            15: "",
            16: "省级",
            17: "",
            18: "",
            19: "是",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "是",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "5",
          danwei: "省中心",
          name: "董立鹏",
          shenfenzheng: "132331198103293079",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "第二批",
            14: "",
            15: "省级",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "6",
          danwei: "省中心",
          name: "王淑娟",
          shenfenzheng: "13010219710216038X",
          zhiwu: "高工",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）   一流专家（第二批）",
            14: "省级",
            15: "省级",
            16: "国家级 省级",
            17: "河北省环境检定修复司法咨询专家（省级）；中华人民共和国司法鉴定人（国家级）；河北省公益讼诉技术专家（省级）",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√（气相、气质、液相、原子吸收、离子色谱等）",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "7",
          danwei: "省中心",
          name: "车轩",
          shenfenzheng: "130104197109151518",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "27",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "8",
          danwei: "省中心",
          name: "付翠轻",
          shenfenzheng: "120106197808110521",
          zhiwu: "高级工程师/实验分析部副主任",
          zhuanye: "环境工程",
          nianxian: "17",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "√（第二秕）",
            14: "",
            15: "",
            16: "√",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质、液相、液质、ICP-OES、ICP、原子吸收、原子荧光、X射线荧光光谱仪等",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "9",
          danwei: "省中心",
          name: "李根利",
          shenfenzheng: "130128198309050610",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "河北省",
            17: "河北省环境应急专家",
            18: "✓ ",
            19: "✓ ",
            20: "✓ ",
            21: "",
            22: "✓ ",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "✓ ",
            30: "✓ ",
            31: "",
            32: "",
            33: "✓ ",
            34: "✓ ",
            35: "便携式气质、X射线荧光光谱仪",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "✓ ",
            42: "",
            43: "",
          },
        },
        {
          id: "10",
          danwei: "省中心",
          name: "张启云",
          shenfenzheng: "130102198202092112",
          zhiwu: "高级工程师",
          zhuanye: "环保",
          nianxian: "17年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相分子吸收光谱仪、X射线荧光光谱仪",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "11",
          danwei: "省中心",
          name: "任汉英",
          shenfenzheng: "130122198110082211",
          zhiwu: "高工",
          zhuanye: "环境监测",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "√ 河北省",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "√ 原子荧光 ",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "12",
          danwei: "省中心",
          name: "范莉茹",
          shenfenzheng: "130102198201200943",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "环境空气分析",
            35: "气质",
            36: "土壤分析",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "13",
          danwei: "省中心",
          name: "牛利民",
          shenfenzheng: "130703197512261510",
          zhiwu: "高工/科室主任",
          zhuanye: "环境监测",
          nianxian: "22",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "省级",
            15: "省级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "√",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "14",
          danwei: "省中心",
          name: "牛洁平",
          shenfenzheng: "130503196609289012",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "16年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "省级专家",
            17: "",
            18: "",
            19: "√",
            20: "√",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "15",
          danwei: "省中心",
          name: "苏海燕",
          shenfenzheng: "130104198106261820",
          zhiwu: "副高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "一",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "√",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "√",
          },
        },
        {
          id: "16",
          danwei: "省中心",
          name: "李歆琰",
          shenfenzheng: "130104198204121821\n",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "17",
          danwei: "省中心",
          name: "孙丽",
          shenfenzheng: "130503198212200322",
          zhiwu: "高工/部门副主任",
          zhuanye: "环境保护",
          nianxian: "12年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省三三三人才第三层次人才（省级）",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "18",
          danwei: "省中心",
          name: "张明华",
          shenfenzheng: "130102197908280321",
          zhiwu: "高工",
          zhuanye: "环境监测",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "土壤",
            21: "地下水",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "土壤",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "19",
          danwei: "省中心",
          name: "王菲",
          shenfenzheng: "130402198207253313",
          zhiwu: "高工/副主任",
          zhuanye: "环境工程",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "省级",
            17: "",
            18: "",
            19: "",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "20",
          danwei: "省中心",
          name: "宋岚",
          shenfenzheng: "13040619711217092X",
          zhiwu: "高工",
          zhuanye: "环境保护",
          nianxian: "28",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "21",
          danwei: "省中心",
          name: "侯冬利",
          shenfenzheng: "132429197911070614",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "15",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "√省级",
            15: "",
            16: "√省级",
            17: "",
            18: "",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "ICP原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "22",
          danwei: "省中心",
          name: "王海鹏",
          shenfenzheng: "130132198110292553",
          zhiwu: "副高级工程师",
          zhuanye: "环境监测",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "√",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "省级",
            17: "省级司法鉴定",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、气质",
            36: "√",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "23",
          danwei: "省中心",
          name: "王晓攀",
          shenfenzheng: "411081198111115663",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境工程",
          nianxian: "14.5",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "√",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "√",
          },
        },
        {
          id: "24",
          danwei: "河北省石家庄生态环境监测中心",
          name: "李亚卿",
          shenfenzheng: "130102196511240000",
          zhiwu: "正高级工程师/副主任",
          zhuanye: "环境保护",
          nianxian: "26",
          dic: {
            7: "授权签字人",
            8: "技术负责人",
            9: "",
            10: "",
            11: "",
            12: "省级资质认定评审员",
            13: "",
            14: "",
            15: "",
            16: "省级土壤专家",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "气相色谱、气质、原子吸收、ICP",
            36: "√",
            37: "",
            38: "√",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "25",
          danwei: "河北省石家庄生态环境监测中心",
          name: "冯媛",
          shenfenzheng: "130133198209210024",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "12",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批三层次",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "液相、离子色谱",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "26",
          danwei: "河北省石家庄生态环境监测中心",
          name: "高欣",
          shenfenzheng: "130105197612290626",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "22",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "ICPMS、ICPOES、原子吸收、离子色谱、气相、气质、）",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "27",
          danwei: "河北省石家庄生态环境监测中心",
          name: "杨丽丽",
          shenfenzheng: "130182198301166625",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第二批三层次",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "离子色谱、液相、气质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "28",
          danwei: "河北省石家庄生态环境监测中心",
          name: "姜建彪",
          shenfenzheng: "132902198011250515",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批三层次",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "29",
          danwei: "河北省石家庄生态环境监测中心",
          name: "康苏花",
          shenfenzheng: "130131198305154226",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "30",
          danwei: "河北省石家庄生态环境监测中心",
          name: "戴春岭",
          shenfenzheng: "130123198201060081",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第三批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质、ICP、原子吸收、X射线荧光光谱仪、原子荧光、离子色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "30",
          danwei: "河北省石家庄生态环境监测中心",
          name: "刘晖",
          shenfenzheng: "130102197803150643",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "18",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "32",
          danwei: "河北省石家庄生态环境监测中心",
          name: "马玲",
          shenfenzheng: "130102197409081221",
          zhiwu: "正高级工程师/副主任",
          zhuanye: "环境保护",
          nianxian: "23",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "32",
          danwei: "河北省石家庄生态环境监测中心",
          name: "王海英",
          shenfenzheng: "130102197508210340",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "24",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "34",
          danwei: "河北省石家庄生态环境监测中心",
          name: "朱高云",
          shenfenzheng: "32110219740127042X",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "23",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "气相、气质、离子色谱",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "35",
          danwei: "河北省石家庄生态环境监测中心",
          name: "周静博",
          shenfenzheng: "130521198512060782",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "液相、离子色谱、单颗粒气溶胶质谱",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "36",
          danwei: "河北省承德生态环境监测中心",
          name: "马莹",
          shenfenzheng: "13080219701229186X",
          zhiwu: "正高级工程师/副主任",
          zhuanye: "环境保护工程",
          nianxian: "31",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "37",
          danwei: "河北省承德生态环境监测中心",
          name: "陈冬梅",
          shenfenzheng: "130821197211077961",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境保护工程",
          nianxian: "26",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "38",
          danwei: "河北省承德生态环境监测中心",
          name: "崔萌",
          shenfenzheng: "132623198011240021",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "15",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管平台技术专家",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质、原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "39",
          danwei: "河北省承德生态环境监测中心",
          name: "杨佳",
          shenfenzheng: "210602198305250520",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管平台技术专家",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "气相、气质、液相、ICP、原子吸收、ICP-MS、离子色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "40",
          danwei: "河北省承德生态环境监测中心",
          name: "娄雅琢",
          shenfenzheng: "",
          zhiwu: "",
          zhuanye: "环境保护工程",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "41",
          danwei: "河北省承德生态环境监测中心",
          name: "那永良",
          shenfenzheng: "130821197007130315",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "42",
          danwei: "河北省承德生态环境监测中心",
          name: "鲁玉立",
          shenfenzheng: "130802198308050226",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "12",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气质",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "43",
          danwei: "河北省承德生态环境监测中心",
          name: "贾延辉",
          shenfenzheng: "",
          zhiwu: "",
          zhuanye: "环境保护工程",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "44",
          danwei: "河北省张家口生态环境监测中心",
          name: "胡燕峰",
          shenfenzheng: "130102197706200354",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "21",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "2019006154",
            11: "",
            12: "201613283",
            13: "第一批技术骨干",
            14: "省级（第二批）",
            15: "",
            16: "",
            17: "应急专家：张家口市级",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "√",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "√气相、气质、X射线荧光",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "45",
          danwei: "河北省张家口生态环境监测中心",
          name: "王存美",
          shenfenzheng: "13060419650925130X",
          zhiwu: "正高级工程师/主任",
          zhuanye: "环境工程",
          nianxian: "25",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "张家口市",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "46",
          danwei: "河北省张家口生态环境监测中心",
          name: "李俊杰",
          shenfenzheng: "130102196506071311",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "33",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "47",
          danwei: "河北省张家口生态环境监测中心",
          name: "崔永琴",
          shenfenzheng: "130702197104050320",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "29",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "√",
            11: "",
            12: "√",
            13: "技术骨干（第一批）",
            14: "省级、张家口市级",
            15: "",
            16: "张家口市级",
            17: "河北省生态环境监测技术专家库专家（省级）、京津冀环境损害司法鉴定机构登记评审专家库专家",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "√",
            34: "环境空气分析",
            35: "原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "48",
          danwei: "河北省张家口生态环境监测中心",
          name: "刘锦",
          shenfenzheng: "130703196909200316",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "30",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "河北省",
            15: "",
            16: "河北省",
            17: "环保部环境工程评估中心专家库专家河北省社会监测机构监管平台技术专家河北省清洁生产审核评估验收专家库专家河北省排污许可技术专家\n\n",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "49",
          danwei: "河北省张家口生态环境监测中心",
          name: "王树永",
          shenfenzheng: "132801196911094414",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "31",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "张家口市级",
            17: "应急专家：省级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "50",
          danwei: "河北省张家口生态环境监测中心",
          name: "陈聪",
          shenfenzheng: "210281198202276422",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "省级（第二批）",
            15: "",
            16: "",
            17: "应急专家：张家口市级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "51",
          danwei: "河北省张家口生态环境监测中心",
          name: "赵际沣",
          shenfenzheng: "130702196909010663",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "20",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级（第二批）",
            15: "",
            16: "",
            17: "应急专家：张家口市级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "52",
          danwei: "河北省张家口生态环境监测中心",
          name: "徐剑",
          shenfenzheng: "130702198005150910",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "18",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "张家口市级",
            17: "应急专家（张家口市级）、审批局专家（张家口市级）",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "53",
          danwei: "河北省张家口生态环境监测中心",
          name: "吴硕",
          shenfenzheng: "130702198206150669",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "54",
          danwei: "河北省张家口生态环境监测中心",
          name: "王婧",
          shenfenzheng: "132522198201010022",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "55",
          danwei: "河北省张家口生态环境监测中心",
          name: "潘民强",
          shenfenzheng: "130184198306175718",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质、液相、液质、ICP、原子吸收、X射线荧光光谱仪",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "56",
          danwei: "河北省张家口生态环境监测中心",
          name: "李惠玲",
          shenfenzheng: "130704196502160021",
          zhiwu: "高级工程师/自动站管理室负责人",
          zhuanye: "环境保护",
          nianxian: "35",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "河北省生态环境监测系统技术专家、全省环境空气自动监测技术专家、\n河北省社会监测机构监管平台技术专家",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "环境空气",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "57",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "冯建社",
          shenfenzheng: "132924197311168015",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "26",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "省级",
            15: "",
            16: "市级",
            17: "",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "√",
            39: "√",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "58",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "陈莹玮",
          shenfenzheng: "130302196808251888",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "31",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "59",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "赵玉华",
          shenfenzheng: "130102196510130361",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "33",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "",
            16: "市级",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气质、气相",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "60",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "于永斌",
          shenfenzheng: "130102197311050347",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "ICP、原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "√",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "61",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "张冬倩",
          shenfenzheng: "130223198206110661",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "62",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "闫超",
          shenfenzheng: "130302198303301818",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "63",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "姜成",
          shenfenzheng: "130181198509167110",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "13",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "64",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "张丽艳",
          shenfenzheng: "130203196405010327",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "33",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "65",
          danwei: "河北省秦皇岛生态环境监测中心",
          name: "单宁宁",
          shenfenzheng: "130324198512290017",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "10",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "原子荧光光度计、离子色谱仪、测汞仪",
            36: "",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "66",
          danwei: "河北省唐山生态环境监测中心",
          name: "刘文利",
          shenfenzheng: "510212196712282346",
          zhiwu: "正高级工程师/副主任",
          zhuanye: "环境监测",
          nianxian: "31",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "河北省",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "67",
          danwei: "河北省唐山生态环境监测中心",
          name: "代进",
          shenfenzheng: "320102196709302832",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "33",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "河北省",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "68",
          danwei: "河北省唐山生态环境监测中心",
          name: "陈旭锋",
          shenfenzheng: "13020319770701511X",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程技术",
          nianxian: "22",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "河北省强制性清洁生产审核评估验收专家,河北省政府采购评审专家",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "69",
          danwei: "河北省唐山生态环境监测中心",
          name: "李茂静",
          shenfenzheng: "370202196809053540",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "32",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "河北省强制性清洁生产审核评估验收专家,河北省政府采购评审专家",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、气质、液相、离子色谱",
            36: "√",
            37: "",
            38: "√",
            39: "√",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "70",
          danwei: "河北省唐山生态环境监测中心",
          name: "张彩霞",
          shenfenzheng: "430103197310014528",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "22",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "71",
          danwei: "河北省唐山生态环境监测中心",
          name: "卢桂军",
          shenfenzheng: "130227197203101015",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "17",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "ICP、原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "72",
          danwei: "河北省唐山生态环境监测中心",
          name: "陈振飞",
          shenfenzheng: "132826197608020019",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "17",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "液相、荧光光谱仪",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "73",
          danwei: "河北省唐山生态环境监测中心",
          name: "白立远",
          shenfenzheng: "130221197806291817",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "清洁生产审核专家，省级",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "74",
          danwei: "河北省唐山生态环境监测中心",
          name: "曹玉龙",
          shenfenzheng: "130224198410053138",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "9",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "75",
          danwei: "河北省唐山生态环境监测中心",
          name: "韩婕",
          shenfenzheng: "130203198202040023",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "7",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "原子荧光，气相色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "76",
          danwei: "河北省衡水生态环境监测中心",
          name: "任灵芝",
          shenfenzheng: "132828197809076521",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "20",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "77",
          danwei: "河北省衡水生态环境监测中心",
          name: "宋秒",
          shenfenzheng: "130503198309020627",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "14",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "78",
          danwei: "河北省衡水生态环境监测中心",
          name: "石华东",
          shenfenzheng: "130102197709290375",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "21",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "79",
          danwei: "河北省衡水生态环境监测中心",
          name: "赵婷娴",
          shenfenzheng: "131002197504191424",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "25",
          dic: {
            7: "",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "80",
          danwei: "河北省衡水生态环境监测中心",
          name: "邱娟娟",
          shenfenzheng: "131002198009033626",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "19",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "√",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "81",
          danwei: "白洋淀流域生态环境监测中心",
          name: "刘琳",
          shenfenzheng: "130602198202010954",
          zhiwu: "高级工程师",
          zhuanye: "",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "ICP、ICPMS、原子吸收",
            36: "是",
            37: "是",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "82",
          danwei: "白洋淀流域生态环境监测中心",
          name: "张涛",
          shenfenzheng: "130602196302220634",
          zhiwu: "高级工程师/中心副主任",
          zhuanye: "环境保护工程",
          nianxian: "37",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "是",
            43: "",
          },
        },
        {
          id: "83",
          danwei: "白洋淀流域生态环境监测中心",
          name: "李洋",
          shenfenzheng: "130603198405300333",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管平台专家（省级）",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "是",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "是",
            39: "",
            40: "是",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "84",
          danwei: "白洋淀流域生态环境监测中心",
          name: "姜婧",
          shenfenzheng: "130604197106100624",
          zhiwu: "正高级工程师/监测仪器分析室主任",
          zhuanye: "环境保护工程",
          nianxian: "26",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "",
            15: "",
            16: "",
            17: "省级监测专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "气相、气质",
            36: "是",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "85",
          danwei: "白洋淀流域生态环境监测中心",
          name: "陈海婴",
          shenfenzheng: "13060219710212006X",
          zhiwu: "正高级工程师/污染源与应急监测室主任",
          zhuanye: "环境工程",
          nianxian: "31",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "86",
          danwei: "白洋淀流域生态环境监测中心",
          name: "孟捷",
          shenfenzheng: "130602196904140324",
          zhiwu: "高级工程师/质量管理室主任",
          zhuanye: "环境保护工程",
          nianxian: "30",
          dic: {
            7: "",
            8: "",
            9: "是",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "",
            22: "是",
            23: "",
            24: "",
            25: "是",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "是",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "87",
          danwei: "白洋淀流域生态环境监测中心",
          name: "要杰",
          shenfenzheng: "130621198206257515",
          zhiwu: "高级工程师/地下水监测室主任",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "是（省级）",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "",
            20: "",
            21: "是",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "气相、气质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "88",
          danwei: "白洋淀流域生态环境监测中心",
          name: "佟霁坤",
          shenfenzheng: "130603198112190919",
          zhiwu: "高级工程师/办公室主任",
          zhuanye: "环境保护工程",
          nianxian: "17",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "是",
            26: "是",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "是",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "89",
          danwei: "白洋淀流域生态环境监测中心",
          name: "王东伟",
          shenfenzheng: "132432197710300055",
          zhiwu: "高级工程师/理化分析室主任",
          zhuanye: "环境工程",
          nianxian: "20",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "液相",
            36: "是",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "90",
          danwei: "白洋淀流域生态环境监测中心",
          name: "杨婧",
          shenfenzheng: "138604197701280623",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "20",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "是",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "91",
          danwei: "白洋淀流域生态环境监测中心",
          name: "张玄",
          shenfenzheng: "610121198401031439",
          zhiwu: "高级工程师/生物监测室主任",
          zhuanye: "环境保护",
          nianxian: "13",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "是（省级）",
            15: "",
            16: "",
            17: "河北省社会监测机构监管平台专家（省级）、保定市行政审批专家(市级）",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "是",
            42: "是",
            43: "",
          },
        },
        {
          id: "92",
          danwei: "白洋淀流域生态环境监测中心",
          name: "管景峰",
          shenfenzheng: "130603096801171000",
          zhiwu: "高级工程师/中心副主任",
          zhuanye: "环境工程",
          nianxian: "32",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "是（第一批）",
            14: "是（省级）",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "是",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "是",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "是",
            37: "",
            38: "是",
            39: "",
            40: "是",
            41: "是",
            42: "是",
            43: "",
          },
        },
        {
          id: "93",
          danwei: "白洋淀流域生态环境监测中心",
          name: "王晨",
          shenfenzheng: "130603198804240315",
          zhiwu: "高级工程师/监测仪器分析室副主任",
          zhuanye: "环境保护",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "ICP-MS",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "",
            42: "是",
            43: "",
          },
        },
        {
          id: "94",
          danwei: "白洋淀流域生态环境监测中心",
          name: "何玮光",
          shenfenzheng: "130823198504190033",
          zhiwu: "高级工程师/自动站管理室主任",
          zhuanye: "环境保护",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "是（省级）",
            15: "",
            16: "",
            17: "国家地表水水质自动监测数据审核专家（国家级）\n河北省环境空气自动监测技术专家（省级）\n河北省评标专家（省级）\n河北省社会监测机构监管技术专家（省级）",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "环境空气和地表水自动监测",
          },
        },
        {
          id: "95",
          danwei: "河北省沧州生态环境监测中心",
          name: "翟金双",
          shenfenzheng: "130903196404050026",
          zhiwu: "正高级工程师",
          zhuanye: "环境工程",
          nianxian: "34",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "技术骨干（第一批）",
            14: "",
            15: "",
            16: "",
            17: "环境应急专家（市级）",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "96",
          danwei: "河北省沧州生态环境监测中心",
          name: "吴伟",
          shenfenzheng: "130903197412040017",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "22",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "技术骨干（第一批）",
            14: "",
            15: "",
            16: "",
            17: "环境应急专家（市级）",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "97",
          danwei: "河北省沧州生态环境监测中心",
          name: "杨彬",
          shenfenzheng: "130903197508211511",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "23",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）",
            14: "√（河北省级）",
            15: "",
            16: "",
            17: "环境应急专家（市级）",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "98",
          danwei: "河北省沧州生态环境监测中心",
          name: "刘有为",
          shenfenzheng: "130926198503072216",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "99",
          danwei: "河北省沧州生态环境监测中心",
          name: "田志坤",
          shenfenzheng: "130903197208100043",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "29",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "√",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "100",
          danwei: "河北省沧州生态环境监测中心",
          name: "范睿",
          shenfenzheng: "130903197803231558",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "19",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）",
            14: "√（河北省级）",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "√",
            24: "√",
            25: "√",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√（气相、气质、液相、ICP、原子吸收、）",
            36: "√",
            37: "",
            38: "",
            39: "√",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "101",
          danwei: "河北省沧州生态环境监测中心",
          name: "孙志洪",
          shenfenzheng: "130921198304172079",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第二批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "液相、原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "102",
          danwei: "河北省沧州生态环境监测中心",
          name: "王淑荣",
          shenfenzheng: "130921198508083246",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "生态环境监测系统技术专家（沧州市）",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "103",
          danwei: "河北省沧州生态环境监测中心",
          name: "李珊",
          shenfenzheng: "132923198111092827",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "18",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "液相、离子色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "104",
          danwei: "河北省沧州生态环境监测中心",
          name: "侯锦英",
          shenfenzheng: "130102197209301824",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "29",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "技术骨干（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "105",
          danwei: "河北省沧州生态环境监测中心",
          name: "冯金艳",
          shenfenzheng: "130102198011250341",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "19",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "技术骨干（第二批）",
            14: "√（河北省级）",
            15: "",
            16: "√（河北省级）",
            17: "环境监测（沧州市）",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "106",
          danwei: "河北省沧州生态环境监测中心",
          name: "付衍宽",
          shenfenzheng: "130323197806140216",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "20",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "√（河北省级）",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "107",
          danwei: "河北省衡水生态环境监测中心",
          name: "王澎涛",
          shenfenzheng: "44010519690327013X",
          zhiwu: "正高级工程师、中心副主任",
          zhuanye: "环境监测",
          nianxian: "29",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "",
            14: "环评工程师、省级环评专家",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "108",
          danwei: "河北省衡水生态环境监测中心",
          name: "安文德",
          shenfenzheng: "133001196410010419",
          zhiwu: "正高级工程师、中心副主任",
          zhuanye: "环境保护",
          nianxian: "34",
          dic: {
            7: "是",
            8: "",
            9: "是",
            10: "",
            11: "",
            12: "是",
            13: "",
            14: "河北省、衡水市",
            15: "",
            16: "",
            17: "环境应急、衡水市",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "109",
          danwei: "河北省衡水生态环境监测中心",
          name: "蔡雅",
          shenfenzheng: "133025197212270426",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "28",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "省级、衡水市级",
            15: "",
            16: "",
            17: "省级清洁生产专家、衡水市级环境应急专家、衡水市级扬尘在线监测设备验收专家",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "110",
          danwei: "河北省衡水生态环境监测中心",
          name: "孟淑锦",
          shenfenzheng: "133001197208150228",
          zhiwu: "高级工程师",
          zhuanye: "环保",
          nianxian: "27",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "是（第二批）",
            14: "省级、衡水市级",
            15: "省级",
            16: "省级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "111",
          danwei: "河北省衡水生态环境监测中心",
          name: "翟玉荣",
          shenfenzheng: "130102197710221828",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批）",
            14: "省级、衡水市级",
            15: "",
            16: "",
            17: "省级清洁生产专家、衡水市级环境应急专家、衡水市级扬尘在线监测设备验收专家",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "112",
          danwei: "河北省衡水生态环境监测中心",
          name: "张虎",
          shenfenzheng: "13302319790110501X",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "17",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "液相色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "113",
          danwei: "河北省衡水生态环境监测中心",
          name: "宋鹏",
          shenfenzheng: "131127198004030015",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "15",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "√",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相色谱",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "114",
          danwei: "河北省衡水生态环境监测中心",
          name: "李金亮",
          shenfenzheng: "133001197407164454",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "115",
          danwei: "河北省衡水生态环境监测中心",
          name: "王惠",
          shenfenzheng: "131181198205080025",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "省级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "√",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "116",
          danwei: "河北省衡水生态环境监测中心",
          name: "王泽华",
          shenfenzheng: "131125198202180636",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "15",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "衡水市级",
            15: "省级",
            16: "",
            17: "扬尘在线监测设备验收专家（衡水市级）",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "117",
          danwei: "河北省衡水生态环境监测中心",
          name: "王海霞",
          shenfenzheng: "133001197111010042",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "28",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批技术骨干、第二批一流专家）",
            14: "是（省级、衡水市级）",
            15: "",
            16: "",
            17: "应急专家（衡水市级）、评标专家（省级、衡水市级）",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "118",
          danwei: "河北省衡水生态环境监测中心",
          name: "韩冰",
          shenfenzheng: "13110219840904061X",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "119",
          danwei: "河北省衡水生态环境监测中心",
          name: "国彤",
          shenfenzheng: "130102197412040383",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "22",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第二批技术骨干）",
            14: "",
            15: "",
            16: "",
            17: "工程设施装备质量专家（省级）",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "120",
          danwei: "河北省衡水生态环境监测中心",
          name: "栾英男",
          shenfenzheng: "133001197209250052",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "是（第一批）",
            14: "省级",
            15: "衡水市级",
            16: "",
            17: "省级空气站专家",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "121",
          danwei: "河北省邢台生态环境监测中心",
          name: "毕新霞",
          shenfenzheng: "130102196807010408",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "31",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "省级资质认定评审员",
            13: "“三五”人才（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收、X射线荧光光谱仪等",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "122",
          danwei: "河北省邢台生态环境监测中心",
          name: "褚亚丽",
          shenfenzheng: "130503197710230028",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "21",
          dic: {
            7: "机构授权签字人",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "离子色谱、原子荧光",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "123",
          danwei: "河北省邢台生态环境监测中心",
          name: "张倩",
          shenfenzheng: "130502198112210623",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "17",
          dic: {
            7: "机构授权签字人",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才（第二批）",
            14: "邢台市级",
            15: "",
            16: "省级",
            17: "省级固体废物污染防治专家、市级环境保护专家",
            18: "",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "124",
          danwei: "河北省邢台生态环境监测中心",
          name: "孙立洁",
          shenfenzheng: "130502198212091238",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "15",
          dic: {
            7: "机构授权签字人",
            8: "",
            9: "机构质量负责人",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才（第一批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "125",
          danwei: "河北省邢台生态环境监测中心",
          name: "王静",
          shenfenzheng: "130525198111016620",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "16",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "邢台市级",
            15: "",
            16: "",
            17: "政府采购评标专家 省级",
            18: "√",
            19: "",
            20: "√",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "126",
          danwei: "河北省邢台生态环境监测中心",
          name: "刘佳",
          shenfenzheng: "130582198301210026",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "6",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "液相、液质",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "127",
          danwei: "河北省邢台生态环境监测中心",
          name: "王莹莹",
          shenfenzheng: "130123198406106928",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "10",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "128",
          danwei: "河北省邢台生态环境监测中心",
          name: "张慧",
          shenfenzheng: "130521198201112784",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "省级资质认定评审员",
            13: "“三五”人才（第二批）",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "129",
          danwei: "河北省邢台生态环境监测中心",
          name: "刘雪伟",
          shenfenzheng: "130582198208200026",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "10",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "邢台市级、邯郸市级",
            15: "",
            16: "",
            17: "政府采购评标专家 省级",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "液相、离子色谱仪",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "130",
          danwei: "河北省邢台生态环境监测中心",
          name: "王永芳",
          shenfenzheng: "130524198106061543",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "131",
          danwei: "河北省邯郸生态环境监测中心",
          name: "白瑞芳",
          shenfenzheng: "620102196307072123",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "37",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "132",
          danwei: "河北省邯郸生态环境监测中心",
          name: "史晓慧",
          shenfenzheng: "130403196807151289",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "30",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "河北省级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "133",
          danwei: "河北省邯郸生态环境监测中心",
          name: "栗萍",
          shenfenzheng: "13040319731025122X",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "24",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "省级职称评审专家  邯郸市评审专家",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "√",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "134",
          danwei: "河北省邯郸生态环境监测中心",
          name: "赵欣",
          shenfenzheng: "130402197105240314",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境保护",
          nianxian: "28",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "135",
          danwei: "河北省邯郸生态环境监测中心",
          name: "高峰",
          shenfenzheng: "130402197010022437",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境保护",
          nianxian: "28",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "136",
          danwei: "河北省邯郸生态环境监测中心",
          name: "王路宁",
          shenfenzheng: "130903197109200014",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境保护",
          nianxian: "30",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "137",
          danwei: "河北省邯郸生态环境监测中心",
          name: "任辉",
          shenfenzheng: "130403197007011215",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "30",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "空气自动站专家省级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "辐射",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "√",
            38: "√",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "辐射",
          },
        },
        {
          id: "138",
          danwei: "河北省邯郸生态环境监测中心",
          name: "袁秀婷",
          shenfenzheng: "130406197209180366",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "邯郸市级",
            15: "",
            16: "",
            17: "邯郸市职称评审",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "ICP、ICP-MS、原子吸收",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "139",
          danwei: "河北省邯郸生态环境监测中心",
          name: "田勇",
          shenfenzheng: "130421197408121581",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "21",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "140",
          danwei: "河北省邯郸生态环境监测中心",
          name: "岳亮",
          shenfenzheng: "130402198304080000",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "141",
          danwei: "河北省邯郸生态环境监测中心",
          name: "陈梅兰",
          shenfenzheng: "132132197201284222",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "142",
          danwei: "河北省邯郸生态环境监测中心",
          name: "郭焕霞",
          shenfenzheng: "130425197808125843",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、液相、液质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "143",
          danwei: "河北省邯郸生态环境监测中心",
          name: "王炜玮",
          shenfenzheng: "130402197707153349",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "22",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "河北省级",
            15: "",
            16: "",
            17: "省级职称评审专家",
            18: "√",
            19: "",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "√",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "144",
          danwei: "石家庄市环境监控中心",
          name: "李粟",
          shenfenzheng: "130604197207291220",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "145",
          danwei: "石家庄市环境监控中心",
          name: "刘志建",
          shenfenzheng: "130108197206161828",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "24",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "省级",
            15: "",
            16: "省级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "气相、液相、原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "146",
          danwei: "石家庄市环境监控中心",
          name: "孙彦敏",
          shenfenzheng: "130102197204280665",
          zhiwu: "正高级工程师",
          zhuanye: "环境工程",
          nianxian: "27",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "",
            16: "省级",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "147",
          danwei: "石家庄市环境监控中心",
          name: "张藏领",
          shenfenzheng: "133024197109223027",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、气质、液相、液质、ICP、原子吸收",
            36: "",
            37: "",
            38: "菌类",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "148",
          danwei: "石家庄市环境监控中心",
          name: "路娜",
          shenfenzheng: "130185198311141340",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "7",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "√",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "液相色谱、ICP-MS、ICP、原子吸收、原子荧光、离子色谱、测汞仪、总有机碳测定仪、X射线荧光光谱仪",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "149",
          danwei: "石家庄市环境监控中心",
          name: "袁张燊",
          shenfenzheng: "13010219810703183X",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "是",
            12: "",
            13: "第一批",
            14: "省级",
            15: "市级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "150",
          danwei: "石家庄市环境预测预报中心",
          name: "李冬",
          shenfenzheng: "130102197501232133",
          zhiwu: "正高级工程师",
          zhuanye: "环境工程",
          nianxian: "24",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "省级",
            16: "省级",
            17: "科技专家库--省级\n环境应急专家--省级\n排污许可评审专家--省级\n清洁生产和固废污染治理专家--省级\n大气污染防治专家--省级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "√",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "√",
          },
        },
        {
          id: "151",
          danwei: "石家庄市环境预测预报中心",
          name: "徐曼",
          shenfenzheng: "130133198010040929",
          zhiwu: "高级工程师/副主任",
          zhuanye: "环境工程",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "√",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "152",
          danwei: "石家庄市环境预测预报中心",
          name: "王玮",
          shenfenzheng: "130103198605240016",
          zhiwu: "高级工程师/预报分析室主任",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "石家庄市青年拔尖人才",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "153",
          danwei: "石家庄市环境预测预报中心",
          name: "杨会珠",
          shenfenzheng: "130185198109250041",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "空气质量预报",
            33: "",
            34: "",
            35: "气相，气质，原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "154",
          danwei: "石家庄市正定环境监控中心",
          name: "王慧霞",
          shenfenzheng: "130102197410250360",
          zhiwu: "高级环保工程工程师/党组成员",
          zhuanye: "环保工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "155",
          danwei: "石家庄市正定环境监控中心",
          name: "李梅芳",
          shenfenzheng: "130105197404182129",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "156",
          danwei: "石家庄市无极环境监控中心",
          name: "张文彬",
          shenfenzheng: "13013019730912009X",
          zhiwu: "高级工程师/指导员",
          zhuanye: "环境工程",
          nianxian: "20",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "市级",
            16: "省级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "157",
          danwei: "石家庄市赞皇环境监控中心",
          name: "张淑芬",
          shenfenzheng: "132322197401115221",
          zhiwu: "高级工程师\n副主任",
          zhuanye: "环保",
          nianxian: "13",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "158",
          danwei: "承德市环境监控中心",
          name: "张华",
          shenfenzheng: "130102197612060354",
          zhiwu: "高级工程师/中心主任",
          zhuanye: "环境工程",
          nianxian: "21",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "环境应急、省级",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "159",
          danwei: "承德市环境监控中心",
          name: "刘俊",
          shenfenzheng: "130802197205230465",
          zhiwu: "副主任/高级工程师",
          zhuanye: "环境保护",
          nianxian: "29",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "否",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "160",
          danwei: "承德市环境监控中心",
          name: "张志丽",
          shenfenzheng: "130102196506151821",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "35",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "科技局专家、市级",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "161",
          danwei: "承德市环境监控中心",
          name: "郭立水",
          shenfenzheng: "130802196309251012",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "29",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "√",
            37: "√",
            38: "√",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "162",
          danwei: "承德市环境监控中心",
          name: "张静",
          shenfenzheng: "13080219811118102x",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "163",
          danwei: "承德市环境监控中心",
          name: "张旭",
          shenfenzheng: "13080219831107101X",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "164",
          danwei: "承德市环境监控中心",
          name: "邓杰",
          shenfenzheng: "130802197204021039",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "24",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "√",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "√",
            43: "",
          },
        },
        {
          id: "165",
          danwei: "承德市环境监控中心",
          name: "丁玉兰",
          shenfenzheng: "13010219631022032x",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "35",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "√",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "166",
          danwei: "承德市环境应急与重污染天气预警中心",
          name: "孔慧芹",
          shenfenzheng: "130802197502031024",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "承德市级",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "167",
          danwei: "承德市生态环境检验检测站",
          name: "唐海龙",
          shenfenzheng: "132628197509134418",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "22",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "168",
          danwei: "承德市生态环境检验检测站",
          name: "杨春普",
          shenfenzheng: "130802196907231011",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "8",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "承德市级",
            15: "承德市级",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "169",
          danwei: "张家口市环境监测站",
          name: "李靖洁",
          shenfenzheng: "130702197304170626",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "24",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "河北省环境影响评价技术评审专家库专家、张家口市行政审批局审批项目专家库专家",
            15: "",
            16: "张家口市土壤专家库专家",
            17: "河北省社会监测机构监管技术专家、河北省生态环境监测技术专家、环境应急专家库专家（张家口市生态环境局）、张家口市永定河产定发展联盟",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "170",
          danwei: "张家口市环境监测站",
          name: "龚希波",
          shenfenzheng: "13252519701014206X",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "27",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管技术专家、河北省生态环境监测技术专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "171",
          danwei: "张家口市环境监测站",
          name: "李晓红",
          shenfenzheng: "132532197802150504",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "19",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省清洁生产专家（省级）、河北省社会监测机构监管技术专家、河北省生态环境监测技术专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "气相",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "172",
          danwei: "张家口市环境监测站",
          name: "杜秀娟",
          shenfenzheng: "130702198507132421",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "6",
          dic: {
            7: "是",
            8: "",
            9: "是",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管技术专家、河北省生态环境监测技术专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "是",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "173",
          danwei: "张家口市环境监测站",
          name: "徐婷婷",
          shenfenzheng: "130703198301221548",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管技术专家、河北省生态环境监测技术专家",
            18: "是",
            19: "是",
            20: "",
            21: "是",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "174",
          danwei: "张家口市环境监测站",
          name: "赵娜",
          shenfenzheng: "130621198412231826",
          zhiwu: "高级工程师/综合室主任",
          zhuanye: "环境监测",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "河北省社会监测机构监管技术专家、河北省生态环境监测技术专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "是",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "综合及质量管理",
          },
        },
        {
          id: "175",
          danwei: "秦皇岛市环境监控中心",
          name: "张庆杰",
          shenfenzheng: "610113196607050038",
          zhiwu: "正高工",
          zhuanye: "环境保护",
          nianxian: "32",
          dic: {
            7: "",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "176",
          danwei: "秦皇岛市环境监控中心",
          name: "李中秋",
          shenfenzheng: "120104196908156343",
          zhiwu: "正高级工程师/中心副主任",
          zhuanye: "环境工程",
          nianxian: "30",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "省级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "177",
          danwei: "秦皇岛市环境监控中心",
          name: "袁 颖",
          shenfenzheng: "13030319730425623X",
          zhiwu: "高工",
          zhuanye: "环保",
          nianxian: "16",
          dic: {
            7: "",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "178",
          danwei: "秦皇岛市环境监控中心",
          name: "朱慧君",
          shenfenzheng: "530102196907033740",
          zhiwu: "正高级工程师",
          zhuanye: "环境保护",
          nianxian: "29",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级、秦皇岛市级",
            15: "",
            16: "秦皇岛市级",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "179",
          danwei: "秦皇岛市环境监控中心",
          name: "郭玲",
          shenfenzheng: "130322198205024023",
          zhiwu: "副高级工程师",
          zhuanye: "环境工程",
          nianxian: "15",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收、ICP",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "180",
          danwei: "秦皇岛市环境监控中心",
          name: "于宁",
          shenfenzheng: "130302198503252512",
          zhiwu: "高工",
          zhuanye: "环境保护工程",
          nianxian: "10",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、气质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "181",
          danwei: "秦皇岛市环境监控中心",
          name: "田程",
          shenfenzheng: "130304198408110527",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "10",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "液相、原子荧光",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "182",
          danwei: "秦皇岛市环境监控中心",
          name: "赵丽静",
          shenfenzheng: "232321198404050047",
          zhiwu: "副高",
          zhuanye: "环境工程",
          nianxian: "11",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "183",
          danwei: "秦皇岛市环境监控中心",
          name: "狄楠楠",
          shenfenzheng: "131081198410181620",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "9",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、气质、ICP、原子吸收、ICP-MS",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "184",
          danwei: "北戴河区环境监控中心",
          name: "李滨",
          shenfenzheng: "130304197708030516",
          zhiwu: "副高",
          zhuanye: "环境工程",
          nianxian: "26",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "185",
          danwei: "北戴河区环境监控中心",
          name: "赵艳凤",
          shenfenzheng: "130302197601313548",
          zhiwu: "副高",
          zhuanye: "计算机信息资源管理",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "√",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "186",
          danwei: "秦皇岛市卢龙县环境监控中心",
          name: "董英杰",
          shenfenzheng: "130324197703016614",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "13",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "187",
          danwei: "秦皇岛市卢龙县环境监控中心",
          name: "薛艳芹",
          shenfenzheng: "210504197607291363",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "23",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "188",
          danwei: "秦皇岛市卢龙县环境监控中心",
          name: "张玉堂",
          shenfenzheng: "130324197506025423",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "189",
          danwei: "唐山市生态环境局",
          name: "周海兵",
          shenfenzheng: "130224197610250019",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "21",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "190",
          danwei: "唐山市环境监控中心",
          name: "张晓郁",
          shenfenzheng: "130206198003071811",
          zhiwu: "正高级工程师/副主任",
          zhuanye: "环境监测",
          nianxian: "18",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "",
            17: "市级环境应急专家",
            18: "是",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "191",
          danwei: "唐山市环境监控中心",
          name: "徐文哲",
          shenfenzheng: "130203196802241217",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "20",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "省级、市级",
            16: "省级",
            17: "监测专家、清洁能源  省级",
            18: "",
            19: "是",
            20: "",
            21: "",
            22: "是",
            23: "",
            24: "是",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "是",
            43: "",
          },
        },
        {
          id: "192",
          danwei: "唐山市环境监控中心",
          name: "张志强",
          shenfenzheng: "130203198106050635",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "16",
          dic: {
            7: "是",
            8: "",
            9: "是",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "是",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "193",
          danwei: "唐山市环境监控中心",
          name: "周立松",
          shenfenzheng: "130229198311213679",
          zhiwu: "高级工程师",
          zhuanye: "环境监测工程技术",
          nianxian: "14",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "是",
            13: "",
            14: "",
            15: "",
            16: "是（省级）",
            17: "",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "是",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "是（气相、气质、液相、原子吸收）",
            36: "是",
            37: "是",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "194",
          danwei: "唐山市环境监控中心",
          name: "魏飞",
          shenfenzheng: "310104196905305628",
          zhiwu: "正高级工程师",
          zhuanye: "环境工程",
          nianxian: "19",
          dic: {
            7: "是",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "河北省环境影响评价专家（省级）",
            15: "河北省固废专家库专家",
            16: "秦皇岛市土壤专家",
            17: "河北省工信厅固废和节能专家、河北省环保厅清洁生产专家",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "辐射",
          },
        },
        {
          id: "195",
          danwei: "唐山市生态环境局古冶区分局",
          name: "石敏瑜",
          shenfenzheng: "450203198502131028",
          zhiwu: "高级工程师/监控中心副主任",
          zhuanye: "环境保护工程",
          nianxian: "11",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、液相、原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "196",
          danwei: "唐山市生态环境局滦南县分局",
          name: "李永波",
          shenfenzheng: "130224197302132424",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "26",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "197",
          danwei: "唐山市生态环境局迁安市分局",
          name: "王芳",
          shenfenzheng: "130226197402160628",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "199",
          danwei: "唐山市生态环境局曹妃甸区分局",
          name: "马慧玲",
          shenfenzheng: "510227198310190000",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "14",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "200",
          danwei: "唐山市生态环境局丰润区分局",
          name: "周翠兰",
          shenfenzheng: "130221196903090027",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "30",
          dic: {
            7: "",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "201",
          danwei: "唐山市生态环境局玉田县分局",
          name: "付佳",
          shenfenzheng: "130229198509100047",
          zhiwu: "高级工程师/监控中心副主任",
          zhuanye: "环境监测",
          nianxian: "13",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收、离子色谱",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "202",
          danwei: "唐山市生态环境局滦州市分局",
          name: "葛爱群",
          shenfenzheng: "130223197308250628",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "28",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "203",
          danwei: "唐山市生态环境局丰南区分局",
          name: "张翠忠",
          shenfenzheng: "130102196809130366",
          zhiwu: "正高、监控中心副主任",
          zhuanye: "环境监测",
          nianxian: "27",
          dic: {
            7: "√",
            8: "√",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、原子吸收",
            36: "",
            37: "√",
            38: "√",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "204",
          danwei: "唐山市生态环境局丰南区分局",
          name: "王丽",
          shenfenzheng: "130222197712230024",
          zhiwu: "高级工程师/综合技术室主任",
          zhuanye: "环境工程",
          nianxian: "22",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "205",
          danwei: "唐山市生态环境局丰南区分局",
          name: "李慧萍",
          shenfenzheng: "13022219720915532X",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "国家级、省级、市级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "206",
          danwei: "廊坊市环境监控中心",
          name: "马文秀",
          shenfenzheng: "132801197409250228",
          zhiwu: "正高级工程师/中心主任",
          zhuanye: "环境监测与治理工程",
          nianxian: "20",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "市级土壤专家",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "207",
          danwei: "廊坊市环境监控中心",
          name: "周正",
          shenfenzheng: "131002198302174428",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "8",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "208",
          danwei: "廊坊市环境监控中心",
          name: "韩梅",
          shenfenzheng: "132801198010242423",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "17",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "√",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "209",
          danwei: "廊坊市环境监控中心",
          name: "李艳宾",
          shenfenzheng: "13280119740403442X",
          zhiwu: "正高级工程师",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "√廊坊市级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "210",
          danwei: "廊坊市环境监控中心",
          name: "孙亚楠",
          shenfenzheng: "131026198703208685",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "12",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "211",
          danwei: "廊坊市环境监控中心",
          name: "蔡萌",
          shenfenzheng: "131002198605174222",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程其他专业",
          nianxian: "12年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "市级土壤专家",
            17: "",
            18: "✔",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "✔",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "✔",
          },
        },
        {
          id: "212",
          danwei: "廊坊市生态环境局",
          name: "万金颖",
          shenfenzheng: "13280119730718424X",
          zhiwu: "正高级工程师",
          zhuanye: "环保工程其他专业",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "河北省、廊坊市",
            15: "",
            16: "",
            17: "廊坊市动物防疫条件审查评估专家",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "213",
          danwei: "三河市环境监控中心",
          name: "薄福生",
          shenfenzheng: "131082197807181030",
          zhiwu: "副高级工程师",
          zhuanye: "环境监测工程",
          nianxian: "19",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "214",
          danwei: "霸州市环境监测站",
          name: "陈颖",
          shenfenzheng: "131081198103062322",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "20",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "215",
          danwei: "文安县环境监测站",
          name: "张俊祥",
          shenfenzheng: "132828196609088670",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "18",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "√廊坊市级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相、液相、原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "216",
          danwei: "",
          name: "王京汉",
          shenfenzheng: "",
          zhiwu: "",
          zhuanye: "",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "217",
          danwei: "满城环境监控中心",
          name: "杜艳敏",
          shenfenzheng: "130102198004151820",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "15",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "√",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "218",
          danwei: "蠡县环境监控中心",
          name: "朱玉磊",
          shenfenzheng: "130635197211090020",
          zhiwu: "副高级工程师/中心主任",
          zhuanye: "环境监测",
          nianxian: "26",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "219",
          danwei: "保定市环境监控中心",
          name: "李 军",
          shenfenzheng: "130602196812070330",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "31年",
          dic: {
            7: "",
            8: "技术负责人",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "省级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "√",
            37: "√",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "220",
          danwei: "保定市环境监控中心",
          name: "王亚斌",
          shenfenzheng: "130602197502240319",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "23年",
          dic: {
            7: "是",
            8: "是",
            9: "否",
            10: "否",
            11: "否",
            12: "否",
            13: "技术骨干第一批",
            14: "河北省级、保定市级",
            15: "否",
            16: "否",
            17: "应急、清洁生产",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "221",
          danwei: "保定市环境监控中心",
          name: "王 卫",
          shenfenzheng: "13060419691110122x",
          zhiwu: "高级工程师/总工",
          zhuanye: "环保工程",
          nianxian: "30年",
          dic: {
            7: "",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "",
            16: "",
            17: "环境应急（省级）",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "√",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "荧光光谱仪",
            36: "√",
            37: "√",
            38: "√",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "222",
          danwei: "保定市环境监控中心",
          name: "关 健",
          shenfenzheng: "130638198102058527",
          zhiwu: "高级工程师/中心副主任",
          zhuanye: "环境监测工程",
          nianxian: "17年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "省市生态环境监测系统监测专家/省级；审批局专家库行政审批专家/市级；河北省大气污染治理专家库推荐专家/省级",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "223",
          danwei: "保定市环境监控中心",
          name: "董志民",
          shenfenzheng: "130604196303080000",
          zhiwu: "高工",
          zhuanye: "环境保护",
          nianxian: "36年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "",
            15: "",
            16: "",
            17: "监测专家/省",
            18: "∨",
            19: "∨",
            20: "",
            21: "∨",
            22: "∨",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "∨",
            28: "∨",
            29: "∨",
            30: "∨",
            31: "",
            32: "",
            33: "∨",
            34: "∨",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "∨",
            42: "",
            43: "",
          },
        },
        {
          id: "224",
          danwei: "保定市环境监控中心",
          name: "安雅娟",
          shenfenzheng: "130604197909250626",
          zhiwu: "高工",
          zhuanye: "环境保护",
          nianxian: "18年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "省级",
            16: "",
            17: "省排污许可技术专家",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "225",
          danwei: "高碑店环境监控中心",
          name: "谢红专",
          shenfenzheng: "130684196307080057",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "23年",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "226",
          danwei: "沧州市生态环境监控中心",
          name: "陈晓东",
          shenfenzheng: "130603197010231017",
          zhiwu: "正高工",
          zhuanye: "环保工程",
          nianxian: "29",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "",
            16: "",
            17: "科技奖励评审省级",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "227",
          danwei: "沧州市生态环境监控中心",
          name: "牟金玲",
          shenfenzheng: "130902196311260028",
          zhiwu: "正高工",
          zhuanye: "环境监测",
          nianxian: "37",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "228",
          danwei: "沧州市生态环境监控中心",
          name: "张忠旭",
          shenfenzheng: "1.30903197207041E+17",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "27",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第一批",
            14: "省级",
            15: "市级",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "229",
          danwei: "沧州市生态环境监控中心",
          name: "郭跃华",
          shenfenzheng: "130903196807020032",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "29",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "第二批",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "230",
          danwei: "沧州市生态环境监控中心",
          name: "郭伟伟",
          shenfenzheng: "130903198201020010",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "16",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "气相、液相、ICP、原吸、X荧光",
            36: "√",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "231",
          danwei: "沧州市生态环境监控中心",
          name: "刘军普",
          shenfenzheng: "130603196410110957",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "33",
          dic: {
            7: "",
            8: "",
            9: "质量负责代理人",
            10: "",
            11: "",
            12: "",
            13: "“三五”人才（第1批）",
            14: "省级",
            15: "",
            16: "",
            17: "生态环境监测技术专家省级",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "质量管理",
          },
        },
        {
          id: "232",
          danwei: "沧州市生态环境监控中心",
          name: "张彦春",
          shenfenzheng: "130984198001282428",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "18",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "√",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "质量管理",
          },
        },
        {
          id: "233",
          danwei: "沧州市生态环境局泊头市分局",
          name: "董伟玲",
          shenfenzheng: "132902197510300164",
          zhiwu: "高级工程师",
          zhuanye: "环保工程",
          nianxian: "25",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "省级",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "原子吸收分光光度计",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "234",
          danwei: "沧州市生态环境局肃宁县分局环境监控中心",
          name: "武艳娥",
          shenfenzheng: "132923197709102028",
          zhiwu: "高级工程师",
          zhuanye: "环境监测",
          nianxian: "22",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "235",
          danwei: "吴桥县环境保护监测站",
          name: "赵卫国",
          shenfenzheng: "310115197901020450",
          zhiwu: "高级工程师/监测站长",
          zhuanye: "环保工程",
          nianxian: "21",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "气相色谱",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "236",
          danwei: "衡水市环境监控中心",
          name: "王颖",
          shenfenzheng: "133025197611176621",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "15",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "市级扬尘在线监测技术专家、市级应急监测专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "是",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "是",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "237",
          danwei: "衡水市环境监控中心",
          name: "王向峰",
          shenfenzheng: "133025198207176667",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "11",
          dic: {
            7: "是",
            8: "",
            9: "是",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "衡水市级",
            17: "市级扬尘在线监测技术专家",
            18: "是",
            19: "是",
            20: "是",
            21: "是",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "是",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "238",
          danwei: "衡水市阜城县环境监控中心",
          name: "张振宏",
          shenfenzheng: "133031196806276617",
          zhiwu: "高级工程师\n\n",
          zhuanye: "环境工程",
          nianxian: "21",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "市级扬尘在线监测技术专家",
            18: "是",
            19: "是",
            20: "",
            21: "是",
            22: "是",
            23: "",
            24: "",
            25: "是",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "是",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "239",
          danwei: "邢台市生态环境监控中心",
          name: "杨军朝",
          shenfenzheng: "130102197310120358",
          zhiwu: "高级工程师",
          zhuanye: "环境保护工程",
          nianxian: "13",
          dic: {
            7: "机构授权签字人",
            8: "机构技术负责人  ",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "环境保护技术评审专家（环境工程、市级）、河北省生态环境监测系统技术专家（环境监测、省级）、河北省排污许可技术专家（环境监测、省级）、",
            18: "水和废水",
            19: "空气和废气",
            20: "土壤和沉积物",
            21: "地下水",
            22: "噪声和振动",
            23: "固体废物",
            24: "",
            25: "",
            26: "",
            27: "水自动站管理",
            28: "气自动站管理",
            29: "污染源（废水）在线监测",
            30: "污染源（废气）在线监测",
            31: "",
            32: "",
            33: "水质理化分析",
            34: "环境空气、污染源废气分析",
            35: "便携气质",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "噪声振动",
            42: "",
            43: "",
          },
        },
        {
          id: "240",
          danwei: "邢台市生态环境监控中心\n\n",
          name: "刘瑶宁",
          shenfenzheng: "130532198108222523",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "15",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "是",
            28: "",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "离子色谱仪",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "是",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "241",
          danwei: "邢台市沙河生态环境监控中心\n\n",
          name: "淮雪坡",
          shenfenzheng: "130582197510300015",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "24",
          dic: {
            7: "是",
            8: "是",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "是",
            19: "是",
            20: "",
            21: "",
            22: "是",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "是",
            30: "是",
            31: "",
            32: "",
            33: "是",
            34: "是",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "是",
            42: "",
            43: "",
          },
        },
        {
          id: "242",
          danwei: "邯郸市环境监控中心",
          name: "王燕",
          shenfenzheng: "130403197309300928",
          zhiwu: "高级工程师",
          zhuanye: "环保",
          nianxian: "25",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "243",
          danwei: "邯郸市磁县生态环境分局",
          name: "马宝丽",
          shenfenzheng: "13213019730421008X",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "27",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "244",
          danwei: "邯郸市曲周县生态环境分局",
          name: "郭洪江",
          shenfenzheng: "13212319770904053x",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "23",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "√",
            34: "√",
            35: "原子吸收",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "√",
            43: "",
          },
        },
        {
          id: "245",
          danwei: "邯郸市永年区生态环境分局",
          name: "武  燕",
          shenfenzheng: "130429197604070022",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "24",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "气相",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "246",
          danwei: "定州市环境监控中心",
          name: "王跃辉",
          shenfenzheng: "130102197310010378",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "6",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "清洁生产审核（省级）",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "247",
          danwei: "定州市环境监控中心",
          name: "时洪丽",
          shenfenzheng: "130102197609232426",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "24",
          dic: {
            7: "√",
            8: "",
            9: "√",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "248",
          danwei: "定州市环境监控中心",
          name: "梁英娟",
          shenfenzheng: "110105197412085448",
          zhiwu: "高级工程师",
          zhuanye: "环境保护",
          nianxian: "26",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "省级验收专家、省级应急专家",
            18: "√",
            19: "√",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "√",
            29: "",
            30: "√",
            31: "",
            32: "",
            33: "",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "249",
          danwei: "辛集市环境监控中心",
          name: "王云英",
          shenfenzheng: "130102197412190381",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "25",
          dic: {
            7: "√",
            8: "√",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "“技术骨干”第一批",
            14: "√（省级）",
            15: "",
            16: "√（辛集市级）",
            17: "环境应急（辛集市）",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "250",
          danwei: "辛集市环境监控中心",
          name: "赵小雅",
          shenfenzheng: "130181197410074526",
          zhiwu: "高级工程师",
          zhuanye: "环境工程",
          nianxian: "27",
          dic: {
            7: "√",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "√（辛集市级）",
            17: "环境应急（辛集市）",
            18: "√",
            19: "√",
            20: "",
            21: "√",
            22: "√",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "√",
            30: "√",
            31: "",
            32: "",
            33: "√",
            34: "√",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "√",
            41: "√",
            42: "",
            43: "",
          },
        },
        {
          id: "251",
          danwei: "河北雄安新区管理委员会生态环境局",
          name: "吴海梅",
          shenfenzheng: "130702197006210975",
          zhiwu: "高级工程师/副局长",
          zhuanye: "环境保护",
          nianxian: "25年",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "√",
            19: "√",
            20: "√",
            21: "√",
            22: "√",
            23: "√",
            24: "",
            25: "",
            26: "",
            27: "√",
            28: "√",
            29: "√",
            30: "√",
            31: "√",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "252",
          danwei: "",
          name: "孙学军",
          shenfenzheng: "",
          zhiwu: "",
          zhuanye: "",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
        {
          id: "253",
          danwei: "",
          name: "孙志强",
          shenfenzheng: "",
          zhiwu: "",
          zhuanye: "",
          nianxian: "",
          dic: {
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
            17: "",
            18: "",
            19: "",
            20: "",
            21: "",
            22: "",
            23: "",
            24: "",
            25: "",
            26: "",
            27: "",
            28: "",
            29: "",
            30: "",
            31: "",
            32: "",
            33: "",
            34: "",
            35: "",
            36: "",
            37: "",
            38: "",
            39: "",
            40: "",
            41: "",
            42: "",
            43: "",
          },
        },
      ],
    };
  },
  computed: {
    result() {
      if (this.q) {
        return filter(this.experts, (x) => {
          return (
            (x.name && x.name.indexOf(this.q) >= 0) ||
            (x.shenfenzheng && x.shenfenzheng.indexOf(this.q) >= 0) ||
            (x.danwei && x.danwei.indexOf(this.q) >= 0) ||
            (x.zhiwu && x.zhiwu.indexOf(this.q) >= 0) ||
            (x.zhuanye && x.zhuanye.indexOf(this.q) >= 0)
          );
        });
      }
      return this.experts;
    },
  },
  mounted() {},
  methods: {},
};
</script>
    