<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-text-field
          v-if="!advSearch"
          label="姓名"
          v-model="q.q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-toolbar-title v-if="advSearch">高级检索</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="toggleAdvSearch()">
              <v-icon v-if="!advSearch">keyboard_arrow_down</v-icon>
              <v-icon v-if="advSearch">keyboard_arrow_up</v-icon>
            </v-btn>
          </template>
          <span>{{ advSearch ? "收起" : "高级检索" }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="create_member_dialog = true">添加新用户</v-btn>
      </v-toolbar>
      <v-card-text v-if="advSearch">
        <v-card>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="姓名" v-model="q.q" dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="职务" v-model="q.zhiwu" dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="职称" v-model="q.zhicheng" dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    label="工作单位"
                    v-model="q.gongzuodanwei"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="学历" v-model="q.xueli" dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    dense
                    :items="searchTrainingItems"
                    v-model="q.trained"
                    label="是否参加过本站培训"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3"></v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-btn color="primary" @click="search">开始检索</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.zhuceshijian="props">{{
          props.item.zhuceshijian | moment("LL")
        }}</template>
        <template v-slot:item.resetpassword="props"
          ><v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="openRestpasswordDialog(props.item)"
              >
                <v-icon small>lock_open</v-icon>
              </v-btn>
            </template>
            <span>为该用户重设密码</span>
          </v-tooltip></template
        >
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                small
                icon
                v-on="on"
                @click.native.stop
                :to="{
                  name: 'admin_userprofile',
                  params: { id: props.item.id },
                }"
              >
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </template>
            <span>查看用户详细信息</span>
          </v-tooltip>
          <v-tooltip bottom v-if="props.item.id != 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="deleteUser(props.item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除用户</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有用户被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialogSet.open" persistent max-width="600px">
        <v-form
          ref="addForm"
          v-model="dialogSet.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">重设密码</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="dialogSet.info.data.password1"
                    :rules="rules.mimaRules"
                    :counter="50"
                    maxlength="50"
                    label="新密码"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogSet.info.data.password2"
                    :rules="[confirmPass]"
                    :counter="50"
                    maxlength="50"
                    label="确认新密码"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogSet.open = false">取消</v-btn>
              <v-btn
                color="warning"
                :disabled="!dialogSet.info.valid"
                @click="resetPassword(dialogSet.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
    <create-member-dialog
      ref="_create_membrer_dialog"
      :open.sync="create_member_dialog"
      @success="fetchUsers"
    ></create-member-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      create_member_dialog: false,
      advSearch: false,
      searchTrainingItems: ["无", "参加过", "没参加过"],
      q: {},
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      dialogSet: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: {},
      rules: {
        mimaRules: [
          (v) => !!v || "请填写密码",
          (v) =>
            (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
        ],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "职务",
          value: "zhiwu",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "职称",
          value: "zhicheng",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "联系电话",
          value: "dianhua",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "电子邮件",
          value: "email",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "注册时间",
          value: "zhuceshijian",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "完成课时",
          value: "FinishedLectures",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "重设密码",
          value: "resetpassword",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认新密码";
      return value === this.dialogSet.info.data.password1 || "密码不一致";
    },
    toggleAdvSearch() {
      this.advSearch = !this.advSearch;
      if (!this.advSearch) {
        let a = this.q.q;
        this.q = { q: a };
      }
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.findUsers();
      }
    },
    findUsers() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchUsers();
      }
    },
    fetchUsers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/members/search?pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&q=${this.q.q || ""}&gzdw=${this.q.gongzuodanwei || ""}&zc=${
            this.q.zhicheng || ""
          }&zw=${this.q.zhiwu || ""}&xl=${this.q.xueli || ""}&trained=${
            this.q.trained == "参加过"
              ? "true"
              : this.q.trained == "没参加过"
              ? "false"
              : ""
          }`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteUser(id) {
      this.$confirm("确定要删除这个用户吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/members/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchUsers();
              },
              (x) => {}
            );
        }
      });
    },
    openRestpasswordDialog(data) {
      this.dialogSet.info.data.id = data.id;
      this.dialogSet.open = true;
    },
    resetPassword(data) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .put(`admin/members/password/reset`, {
          id: data.id,
          Password: data.password1,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
