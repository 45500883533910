<template>
  <div>
    <v-card>
      <v-card-title>
        培训计划
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加培训计划</v-btn>
      </v-card-title>
      <v-toolbar>
        <v-text-field
          label="培训名称"
          clearable
          v-model="q"
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :server-items-length="data.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.from="props">{{
            props.item.from | moment("LL")
          }}</template>
          <template v-slot:item.to="props">{{
            props.item.to | moment("LL")
          }}</template>
          <template v-slot:item.publish="props">
            <v-btn
              x-small
              v-if="props.item.ispublish"
              color="info"
              @click="publish(props.item)"
              >取消发布</v-btn
            >
            <v-btn
              x-small
              v-if="!props.item.ispublish"
              color="success"
              @click="publish(props.item)"
              >发布</v-btn
            >
          </template>
          <template v-slot:item.lecture="props">
            <v-btn
              x-small
              color="success"
              :to="`/admin/traininglectures/${props.item.id}`"
              >课程管理</v-btn
            >
          </template>
          <template v-slot:item.exam="props">
            <v-btn
              x-small
              color="success"
              :to="`/admin/trainingexams/${props.item.id}`"
              >考试管理</v-btn
            >
          </template>
          <template v-slot:item.checkin="props">
            <v-btn
              x-small
              color="secondary"
              :to="`/admin/trainingmembers/${props.item.id}`"
              >查看</v-btn
            >
          </template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  small
                  icon
                  v-on="on"
                  @click="openDetailsDialog(props.item)"
                >
                  <v-icon small>remove_red_eye</v-icon>
                </v-btn>
              </template>
              <span>查看培训计划</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑培训计划</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteTrainingPlan(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:item.chengji="props">
            <v-btn
              color="secondary"
              x-small
              :to="`/admin/achievements/${props.item.id}`"
              >查看成绩</v-btn
            >
          </template>
          <template v-slot:no-data>没有培训计划</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="培训名称"
                    v-model="dialogSet.vm.data.mingcheng"
                    :rules="rules.mingchengRules"
                    prepend-icon="payment"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="fromDialog"
                    v-model="dialogSet.fromDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dialogSet.vm.data.from"
                        label="培训开始日期"
                        prepend-icon="event"
                        :rules="rules.fromRules"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dialogSet.vm.data.from"
                      no-title
                      @input="dialogSet.fromDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="toDialog"
                    v-model="dialogSet.toDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dialogSet.vm.data.to"
                        label="培训结束日期"
                        prepend-icon="event"
                        :rules="rules.toRules"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dialogSet.vm.data.to"
                      no-title
                      @input="dialogSet.toDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="startDialog"
                    v-model="dialogSet.startDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dialogSet.vm.data.baomingstart"
                        label="报名开始日期"
                        prepend-icon="event"
                        :rules="rules.startRules"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dialogSet.vm.data.baomingstart"
                      no-title
                      @input="dialogSet.startDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="endDialog"
                    v-model="dialogSet.endDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dialogSet.vm.data.baomingend"
                        label="报名结束日期"
                        prepend-icon="event"
                        :rules="rules.endRules"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dialogSet.vm.data.baomingend"
                      no-title
                      @input="dialogSet.endDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="培训内容"
                    v-model="dialogSet.vm.data.neirong"
                    :rules="rules.neirongRules"
                    prepend-icon="list"
                    autocomplete="false"
                    :counter="200"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="培训地点"
                    v-model="dialogSet.vm.data.didian"
                    :rules="rules.didianRules"
                    prepend-icon="my_location"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="培训类型"
                    v-model="dialogSet.vm.data.peixunleixing"
                    :rules="rules.peixunleixingRules"
                    prepend-icon="category"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="培训讲师"
                    v-model="dialogSet.vm.data.jiangshi"
                    :rules="rules.jiangshiRules"
                    prepend-icon="people"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="考核方式"
                    v-model="dialogSet.vm.data.kaohefangshi"
                    :rules="rules.kaohefangshiRules"
                    prepend-icon="spellcheck"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="参加要求"
                    v-model="dialogSet.vm.data.canjiayaoqiu"
                    :rules="rules.canjiayaoqiuRules"
                    prepend-icon="check_circle_outline"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    name="hack-chrome"
                    label="课件下载"
                    :rows="3"
                    v-model="dialogSet.vm.data.kejian"
                    prepend-icon="cloud_download"
                    autocomplete="false"
                    :counter="500"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialogSet.vm.data = {};
                dialogSet.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="createTrainingPlan"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogSet.detailsOpen" max-width="600px">
      <plain-details :details="dialogSet.details" />
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      q: "",
      loading: true,
      dialogSet: {
        detailsOpen: false,
        details: {},
        open: false,
        title: "",
        fromDialog: false,
        toDialog: false,
        startDialog: false,
        endDialog: false,
        vm: {
          valid: false,
          data: {},
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      rules: {
        mingchengRules: [(v) => !!v || "请填写培训名称"],
        fromRules: [(v) => !!v || "请填写培训开始日期"],
        toRules: [
          (v) => !!v || "请填写培训结束日期",
          (v) => {
            var n = this.$moment(this.dialogSet.vm.data.from);
            var c = this.$moment(v);
            return c.diff(n, "days") >= 0 || "培训结束日期不能小于培训开始日期";
          },
        ],
        startRules: [(v) => !!v || "请填写报名开始日期"],
        endRules: [
          (v) => !!v || "请填写报名结束日期",
          (v) => {
            var n = this.$moment(this.dialogSet.vm.data.baomingstart);
            var c = this.$moment(v);
            return c.diff(n, "days") >= 0 || "报名结束日期不能小于报名开始日期";
          },
        ],
        neirongRules: [(v) => !!v || "请填写培训内容"],
        didianRules: [(v) => !!v || "请填写培训地点"],
        peixunleixingRules: [(v) => !!v || "请填写培训类型"],
        jiangshiRules: [(v) => !!v || "请填写培训讲师"],
        kaohefangshiRules: [(v) => !!v || "请填写考核方式"],
        canjiayaoqiuRules: [(v) => !!v || "请填写参加要求"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "培训名称",
          value: "mingcheng",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "培训开始日期",
          value: "from",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "培训结束日期",
          value: "to",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "已报名",
          value: "baoming",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "已签到",
          value: "checkedin",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课程设置",
          value: "lecture",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试设置",
          value: "exam",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "发布",
          value: "publish",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "报名/签到",
          value: "checkin",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "chengji",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchPlans();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchPlans();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchPlans();
    },
    fetchPlans() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/trainingplans?pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    openEditDialog(vm) {
      this.dialogSet.title = "编辑培训计划";
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.dialogSet.vm.data, vm);
      this.dialogSet.vm.data.from = this.$moment(
        this.dialogSet.vm.data.from
      ).format("YYYY-MM-DD");
      this.dialogSet.vm.data.to = this.$moment(
        this.dialogSet.vm.data.to
      ).format("YYYY-MM-DD");
      this.dialogSet.vm.data.baomingstart = this.$moment(
        this.dialogSet.vm.data.baomingstart
      ).format("YYYY-MM-DD");
      this.dialogSet.vm.data.baomingend = this.$moment(
        this.dialogSet.vm.data.baomingend
      ).format("YYYY-MM-DD");
      this.dialogSet.open = true;
    },
    openAddDialog() {
      this.dialogSet.title = "添加培训计划";
      let n = this.$moment().format("YYYY-MM-DD");
      this.dialogSet.vm.data = {
        from: n,
        to: n,
        baomingstart: n,
        baomingend: n,
      };
      this.dialogSet.open = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    createTrainingPlan() {
      if (!this.$refs.addForm.validate()) return;
      if (this.dialogSet.vm.data.id) {
        this.$hc
          .req()
          .put(`admin/trainingplans`, this.dialogSet.vm.data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchPlans();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`admin/trainingplans`, this.dialogSet.vm.data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchPlans();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      }
    },
    deleteTrainingPlan(id) {
      this.$confirm("确定要删除这条培训计划吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/trainingplans/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchPlans();
              },
              (x) => {}
            );
        }
      });
    },
    openDetailsDialog(vm) {
      this.dialogSet.details = vm;
      this.dialogSet.detailsOpen = true;
    },
    publish(vm) {
      var t = vm.ispublish ? "确定取消发布吗?" : "确定要发布吗?";
      this.$confirm(t).then((x) => {
        if (x) {
          this.$hc
            .req()
            .put(`admin/trainingplans/publish/${vm.id}`, {})
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchPlans();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
    