var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"secondary","to":"/admin/qualifications"}},[_vm._v("返回")]),_c('v-spacer'),_c('small',[_vm._v("考题管理")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"15px"},attrs:{"color":"success","to":("/admin/qualifications/" + (this.id) + "/preview")}},[_vm._v("预览考卷")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openQuizEditDialog(
            {
              qualificationid: _vm.id,
              type: '单选题',
              options: '[]',
              anwser: '[]',
            },
            true
          )}}},[_vm._v("添加")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.Payload,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","item-key":"id","fixed-header":true},scopedSlots:_vm._u([{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openQuizEditDialog(props.item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteQuiz(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("删除考题")])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1),_c('qualification-quiz-edit-dialog',{ref:"_quiz",attrs:{"open":_vm.quizDialog},on:{"update:open":function($event){_vm.quizDialog=$event},"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }