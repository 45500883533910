<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/trainingplans">返回</v-btn>
        <v-spacer></v-spacer>
        <small
          >课程设置: {{ traingingplan ? traingingplan.mingcheng : "" }}</small
        >
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加课程</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="traingingplan.lectures"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑课程</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteLecture(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除课程</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有课程</template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="课程名称"
                    v-model="dialogSet.vm.data.name"
                    :rules="rules.mingchengRules"
                    prepend-icon="payment"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-textarea
                    name="hack-chrome"
                    label="课程简介"
                    :rows="3"
                    v-model="dialogSet.vm.data.description"
                    :rules="rules.descriptionRules"
                    prepend-icon="subject"
                    autocomplete="false"
                    :counter="500"
                  ></v-textarea>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="课程序号"
                    v-model="dialogSet.vm.data.ord"
                    :rules="rules.ordRules"
                    prepend-icon="list"
                    autocomplete="false"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    name="hack-chrome"
                    label="课程时长(秒)"
                    v-model="dialogSet.vm.data.length"
                    :rules="rules.lengthRules"
                    prepend-icon="query_builder"
                    autocomplete="false"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 v-show="dialogSet.showVideo">
                  <video
                    ref="videoPlayer"
                    class="
                      video-js
                      vjs-default-skin vjs-big-play-centered vjs-fluid
                    "
                    controls="true"
                    preload="auto"
                  ></video>
                </v-flex>
                <v-flex xs12>
                  <v-file-input
                    label="视频上传"
                    @change="getFile($event)"
                    v-model="dialogSet.vm.data.file"
                    accept="video/mp4"
                    :rules="rules.videoRules"
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                pauseVideo();
                dialogSet.vm.data = {};
                dialogSet.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="createLecture"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],
  data() {
    return {
      q: "",
      loading: true,
      dialogSet: {
        player: null,
        detailsOpen: false,
        details: {},
        open: false,
        title: "",
        isedit: false,
        vm: {
          valid: false,
          data: {
            ord: 0,
            length: 0,
          },
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      traingingplan: {},
      rules: {
        mingchengRules: [(v) => !!v || "请填写课程名称"],
        descriptionRules: [(v) => !!v || "请填写课程简介"],
        ordRules: [(v) => !isNaN(v * 1) || "请填写课程序号"],
        lengthRules: [(v) => !isNaN(v * 1) || "请填写视频时长"],
        videoRules: [(v) => !!v || "请选择上传视频"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课程顺序",
          value: "ord",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课程名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "课程简介",
          value: "description",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "时长(秒)",
          value: "length",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchPlans();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchPlans();
  },
  beforeDestroy() {
    if (this.dialogSet.player) {
      this.dialogSet.player.dispose();
    }
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchPlans();
    },
    getFile(evt) {
      var file = event.target.files;
      if (file && file.length > 0) {
        var me = this;
        var src = URL.createObjectURL(file[0]);
        this.dialogSet.showVideo = true;
        if (!this.dialogSet.player) {
          this.$nextTick(() => {
            this.dialogSet.player = this.$video(this.$refs.videoPlayer, {
              language: "zh-CN",
              preload: "auto",
              controls: true,
            });
            this.dialogSet.player.src({
              src: src,
              type: "video/mp4",
            });
            this.dialogSet.player.on("loadeddata", function (e) {
              var duration = me.dialogSet.player.duration();
              me.dialogSet.vm.data.length = (duration || 0).toFixed(0);
              try {
                var canvas = document.createElement("canvas");
                var canvasFill = canvas.getContext("2d");
                canvas.width = me.dialogSet.player.children_[0].videoWidth;
                canvas.height = me.dialogSet.player.children_[0].videoHeight;
                canvasFill.drawImage(
                  me.dialogSet.player.children_[0],
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );

                var img = canvas.toDataURL("image/jpeg");
                me.dialogSet.vm.data.img = img;
              } catch (ex) {}
              window.URL.revokeObjectURL(src);
            });
          });
        } else {
          this.dialogSet.player.on("loadeddata", function (e) {
            var duration = me.dialogSet.player.duration();
            me.dialogSet.vm.data.length = (duration || 0).toFixed(0);
            try {
              var canvas = document.createElement("canvas");
              var canvasFill = canvas.getContext("2d");
              canvas.width = me.dialogSet.player.children_[0].videoWidth;
              canvas.height = me.dialogSet.player.children_[0].videoHeight;
              canvasFill.drawImage(
                me.dialogSet.player.children_[0],
                0,
                0,
                canvas.width,
                canvas.height
              );

              var img = canvas.toDataURL("image/jpeg");
              me.dialogSet.vm.data.img = img;
            } catch (ex) {}
            window.URL.revokeObjectURL(src);
          });
          this.dialogSet.player.src({
            src: src,
            type: "video/mp4",
          });
        }
      } else {
        this.dialogSet.vm.data.file = null;
        this.dialogSet.vm.data.length = 0;
      }
    },
    fetchPlans() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/lectures?planid=${this.id}`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.traingingplan = x.Data;
          },
          (x) => {}
        );
    },
    pauseVideo() {
      if (this.dialogSet.player) {
        this.dialogSet.player.pause();
      }
    },

    openEditDialog(vm) {
      this.dialogSet.showVideo = true;
      this.dialogSet.title = "编辑课程";
      this.dialogSet.isedit = true;
      this.rules.videoRules = [];
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.dialogSet.vm.data, vm);
      this.dialogSet.open = true;
      if (!this.dialogSet.player) {
        this.$nextTick(() => {
          this.dialogSet.player = this.$video(this.$refs.videoPlayer, {
            language: "zh-CN",
            preload: "metadata",
            controls: true,
          });
          this.dialogSet.player.src({
            src: `${process.env.VUE_APP_VIDEO_ROOT}${vm.filename}`,
            type: "video/mp4",
          });
        });
      } else {
        this.dialogSet.player.src({
          src: `${process.env.VUE_APP_VIDEO_ROOT}${vm.filename}`,
          type: "video/mp4",
        });
      }
    },
    openAddDialog() {
      this.dialogSet.title = "添加课程";
      this.dialogSet.showVideo = false;
      this.dialogSet.isedit = false;
      this.rules.videoRules = [(v) => !!v || "请填选择上传视频"];
      this.dialogSet.vm.data = {
        ord: 0,
        length: 0,
      };

      this.dialogSet.open = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    createLecture() {
      if (!this.$refs.addForm.validate()) return;
      if (this.dialogSet.vm.data.id) {
        var fe = new FormData();
        fe.append("name", this.dialogSet.vm.data.name);
        fe.append("id", this.dialogSet.vm.data.id);
        fe.append("description", this.dialogSet.vm.data.description);
        fe.append("ord", this.dialogSet.vm.data.ord);
        fe.append("length", this.dialogSet.vm.data.length);
        fe.append("img", this.dialogSet.vm.data.img);
        if (this.dialogSet.vm.data.file) {
          fe.append("file", this.dialogSet.vm.data.file);
        }
        this.$hc
          .req()
          .put(`admin/lectures`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchPlans();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      } else {
        var fd = new FormData();
        fd.append("name", this.dialogSet.vm.data.name);
        fd.append("plainid", this.id);
        fd.append("description", this.dialogSet.vm.data.description);
        fd.append("ord", this.dialogSet.vm.data.ord);
        fd.append("length", this.dialogSet.vm.data.length);
        fd.append("img", this.dialogSet.vm.data.img);
        fd.append("file", this.dialogSet.vm.data.file);
        this.$hc
          .req()
          .post(`admin/lectures`, fd)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchPlans();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      }
    },
    deleteLecture(id) {
      this.$confirm("确定要删除这个课程? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/lectures/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchPlans();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
    