<template>
  <div>
    <v-card>
      <v-card-title>
        <small>学员答卷</small>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" to="/admin/examinations">返回</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload.answers"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  v-on="on"
                  :href="`${cousewareurl(props.item)}`"
                  target="_blank"
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
              </template>
              <span>下载答卷</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>没有答卷</template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
    
    <script>
export default {
  props: ["id"],
  data() {
    return {
      q: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      answer: {},
      data: { Payload: {} },
      headers: [
        {
          text: "学员姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "答卷下载",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    search() {
      this.fetchCoursewares();
    },
    cousewareurl(examination) {
      return (
        process.env.VUE_APP_VIDEO_ROOT +
        `examinations/${this.id}/anwsers/${examination.filename}`
      );
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/examinations/${this.id}/results?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
    