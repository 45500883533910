<template>
  <v-dialog v-model="open" persistent max-width="800px">
    <v-card>
      <v-card-title>分配考生</v-card-title>

      <v-card-text>
        <v-tabs background-color="blue-grey lighten-5" v-model="tab">
          <v-tab @click="switchTab(0)">未分配</v-tab>
          <v-tab @click="switchTab(1)">已分配</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="success" @click="assign">分配</v-btn>
              </v-card-title>
              <v-data-table
                dense
                v-model="tobeassigned"
                :headers="headers"
                :items="data.Payload"
                :loading="loading"
                hide-default-footer
                item-key="id"
                :fixed-header="true"
                :server-items-length="data.Total"
                show-select
              >
                <template v-slot:no-data></template>
              </v-data-table>
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                :total-visible="10"
              ></v-pagination>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="warning" @click="remove">移除</v-btn>
              </v-card-title>
              <v-data-table
                dense
                v-model="toberemoved"
                :headers="headers"
                :items="data.Payload"
                :loading="loading"
                hide-default-footer
                item-key="id"
                :fixed-header="true"
                :server-items-length="data.Total"
                show-select
              >
                <template v-slot:no-data></template>
              </v-data-table>
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                :total-visible="10"
              ></v-pagination>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="finished">完成</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AssignQualificationVerifierDialog",
  props: ["open", "qualificationid"],
  data() {
    return {
      loading: false,
      memberid: 0,
      tobeassigned: [],
      toberemoved: [],
      tab: 0,
      data: {},
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData(this.tab);
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    init(memberid) {
      this.memberid = memberid;
      this.data = {};
      this.tab = 0;
      this.data = {};
      this.tobeassigned = [];
      this.toberemoved = [];
      this.pagination.page = 1;
      this.fetchData(0);
    },
    finished() {
      this.$emit("success");
      this.$emit("update:open", false);
    },
    switchTab(t) {
      this.data = {};
      this.tobeassigned = [];
      this.toberemoved = [];
      this.pagination.page = 1;
      this.fetchData(t);
    },
    assign() {
      if (this.tobeassigned && this.tobeassigned.length) {
        var ids = this.tobeassigned.map((x) => x.id);
        this.loading = true;
        this.$hc
          .req()
          .put(`admin/qualificationmembers/verifiers`, {
            qualificationid: this.qualificationid,
            verifierid: this.memberid,
            assign: true,
            qualificationmemberids: ids,
          })
          .go({
            lock: true,
            toastError: false,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.tobeassigned = [];
              this.fetchData(0);
            },
            (x) => {}
          );
      }
    },
    remove() {
      if (this.toberemoved && this.toberemoved.length) {
        var ids = this.toberemoved.map((x) => x.id);
        this.loading = true;
        this.$hc
          .req()
          .put(`admin/qualificationmembers/verifiers`, {
            qualificationid: this.qualificationid,
            verifierid: this.memberid,
            assign: false,
            qualificationmemberids: ids,
          })
          .go({
            lock: true,
            toastError: false,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.toberemoved = [];
              this.fetchData(1);
            },
            (x) => {}
          );
      }
    },
    fetchData(t) {
      var mid = -1;
      if (t == 1) {
        mid = this.memberid;
      }
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualificationmembers?id=${this.qualificationid}&type=考生&pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&mid=${mid || 0}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
