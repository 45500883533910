import * as faceapi from 'face-api.js'
const MODEL_URL='/data/weights';

class FaceWorker {
    constructor(){
        this._api = faceapi;
        this.initialized=false;
    }

    async init(){
        if(!this.initialized) {            
            await this._api.loadSsdMobilenetv1Model(MODEL_URL);
            await this._api.loadFaceLandmarkModel(MODEL_URL);
            await this._api.loadFaceRecognitionModel(MODEL_URL);
            this.initialized=true;
        }
    }
    saveFaceFeature(f32Array){
        return btoa(String.fromCharCode(...(new Uint8Array(f32Array.buffer))));
    }
    loadFaceFeature(f32base64){
        let df32base64 = new Float32Array(new Uint8Array([...atob(f32base64)].map(c => c.charCodeAt(0))).buffer);
        return df32base64;
    }
    loadFaceMatcher(f32base64){
        let df32base64 = new Float32Array(new Uint8Array([...atob(f32base64)].map(c => c.charCodeAt(0))).buffer);
        const fs=[df32base64];
        var fd = new faceapi.LabeledFaceDescriptors('my', fs);
        var faceMatcher = new faceapi.FaceMatcher(fd, 0.6);
        return faceMatcher;
    }
}

export default new FaceWorker()