<template>
  <div>
    <v-card>
      <v-card-title>我的培训</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="trainings.Payload"
          :server-items-length="trainings.Total"
          :loading="loading"
          hide-default-footer
          item-key="id"
        >
          <template v-slot:item.from="props">{{
            props.item.from | moment("LL")
          }}</template>
          <template v-slot:item.to="props">{{
            props.item.to | moment("LL")
          }}</template>
          <template v-slot:item.feedback="props">
            <v-btn
              x-small
              color="secondary"
              @click="openFeedbackDialog(props.item.id)"
              :disabled="props.item.type != 1"
              >反馈</v-btn
            >
          </template>
          <template v-slot:item.jindu="props">
            <v-progress-linear
              :value="props.item.jindu"
              color="blue"
              height="20"
            >
              <template v-slot="{ value }">{{ value.toFixed(2) }}%</template>
            </v-progress-linear>
          </template>
          <template v-slot:item.checkin="props">
            <span v-if="props.item.checkedin">已签到</span>
            <v-btn
              v-else
              color="success"
              x-small
              :disabled="!(!props.item.checkedin && canCheckin(props.item))"
              @click="checkin(props.item.id)"
              >签到</v-btn
            >
          </template>
          <template v-slot:item.op="props">
            <v-btn
              color="secondary"
              x-small
              @click="openDetailsDialog(props.item)"
              >查看详情</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              x-small
              :to="`/mytraining/${props.item.id}/lectures`"
              :disabled="props.item.type != 1"
              >开始学习</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="props.item.examstatus > 0"
              color="secondary"
              x-small
              :to="`/training/${props.item.id}/exam`"
              :disabled="
                props.item.type != 1 ||
                props.item.jindu < 99 ||
                props.item.examstatus == 2
              "
              >{{ props.item.examstatus == 2 ? "考试结束" : "在线考试" }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              x-small
              :disabled="!props.item.totalscore || props.item.totalscore < 60"
              @click="downloadCert(props.item.mingcheng)"
              >证书下载</v-btn
            >
          </template>
          <template v-slot:no-data>没有培训计划</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogSet.detailsOpen" max-width="600px">
      <plain-details :details="dialogSet.training" />
    </v-dialog>
    <v-dialog v-model="dialogSet.feedbackOpen" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.feedback.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">意见反馈</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogSet.feedback.data.yijian"
                  :rules="rules.yijianRules"
                  :counter="200"
                  maxlength="200"
                  label="意见"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogSet.feedbackOpen = false"
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.feedback.valid"
              @click="addFeedback(dialogSet.feedback.data)"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data: () => ({
    loading: true,
    slides: [],
    trainings: {},
    dialogSet: {
      detailsOpen: false,
      feedbackOpen: false,
      training: {},
      feedback: {
        valid: false,
        data: {},
      },
    },
    rules: {
      yijianRules: [(v) => !!v || "请填写意见"],
    },
    pagination: {
      page: 1,
      rowsPerPage: 15,
    },
    headers: [
      {
        text: "ID",
        value: "id",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "培训名称",
        value: "mingcheng",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "培训类型",
        value: "peixunleixing",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "培训开始时间",
        value: "from",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "培训结束时间",
        value: "to",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "课程数量",
        value: "lecturecount",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "学习进度",
        value: "jindu",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "签到",
        value: "checkin",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "成绩",
        value: "totalscore",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "反馈",
        value: "feedback",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
      {
        text: "操作",
        value: "op",
        align: "center",
        sortable: false,
        class: "data-table-header",
      },
    ],
  }),
  watch: {
    type: {
      handler() {
        this.fetchPlans();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchPlans();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.trainings.Total) {
        return Math.ceil(this.trainings.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  mounted() {
    this.fetchPlans();
  },

  methods: {
    fetchPlans() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `trainingplans?type=${this.type}&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.trainings = x.Data;
          },
          (x) => {}
        );
    },
    makeBR(str) {
      if (!str) return "";
      return str.replace(/\n/g, "<br />");
    },
    canCheckin(vm) {
      var now = this.$moment(this.$moment().format("YYYY-MM-DD"));
      var start = this.$moment(vm.from);
      var end = this.$moment(vm.to);
      return now.diff(start, "days") >= 0 && end.diff(now, "days") >= 0;
    },
    checkin(id) {
      this.$confirm("确定要签到吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .post(`trainingplans/checkin/${id}`, {})
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "签到成功",
            })
            .subscribe(
              (x) => {
                this.fetchPlans();
              },
              (x) => {}
            );
        }
      });
    },
    openDetailsDialog(vm) {
      this.dialogSet.training = vm;
      this.dialogSet.detailsOpen = true;
    },

    openFeedbackDialog(id) {
      this.dialogSet.feedback.data.trainingplanid = id;
      this.dialogSet.feedback.data.yijian = "";
      this.dialogSet.feedbackOpen = true;
    },
    addFeedback(vm) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .post(`trainingplans/feedback`, vm)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.feedbackOpen = false;
          },
          (x) => {}
        );
    },
    downloadCert(lecture) {
      let self = this;
      let u = this.$ls.get("token");
      if (u) {
        var img = new Image();
        img.onload = function () {
          let user = JSON.parse(u);
          const username = user.Name;
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          ctx.font =
            'bold 48px "微软雅黑", "Microsoft Yahei","宋体",Tahoma,Arial,Helvetica,STHeiti';
          ctx.textAlign = "center";
          ctx.fillText(username, 536, 570);
          ctx.textAlign = "left";
          ctx.fillText(lecture, 890, 570);
          ctx.font =
            '48px "微软雅黑", "Microsoft Yahei","宋体",Tahoma,Arial,Helvetica,STHeiti';
          ctx.fillText(self.$moment().format("YYYY年MM月DD日"), 1220, 1150);
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "培训合格证书.png");
          } else {
            var a = document.createElement("a");
            a.setAttribute("download", "培训合格证书.png");
            a.setAttribute("href", canvas.toDataURL());
            a.click();
          }
        };
        img.src = "images/cert.jpg";
      }
    },
  },
};
</script>
