<template>
  <div class="articlecontainer">
    <div class="pad">
      <div class="article">
        <h1>{{ data.title }}</h1>
        <div class="date">{{ data.created | moment("YYYY-MM-DD HH:mm") }}</div>
        <div class="articlecontent ql-editor" v-html="data.content"></div>
        <div class="attachments">
          <div class="title" v-if="data.attachments && data.attachments.length">
            相关附件
          </div>
          <ul>
            <li v-for="a in data.attachments" :key="a.id">
              <a :href="`${attachmenturl}${a.filename}`" target="_blank">{{
                a.originfilename
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.articlecontainer {
  width: 1100px;
  margin: 0px auto;
  background: #ffffff;
  font-family: "宋体", "新宋体", "微软雅黑" !important;
}
.articlecontainer .pad {
  padding: 50px 36px 60px 36px;
}

.articlecontainer .pad .article {
  padding: 10px 66px 38px 66px;
  border: 1px #dddddd solid;
}

.articlecontainer .pad .article h1 {
  line-height: 56px;
  font-family: "微软雅黑", "宋体";
  font-size: 38px;
  text-align: center;
  padding: 26px 0 26px 0;
  font-weight: normal;
}

.date {
  position: relative;
  border-bottom: 1px #dcdcdc solid;
  padding-top: 7px;
  text-align: center;
  color: #666666;
  font-size: 14px;
  height: 35px;
  overflow: hidden;
}
.articlecontent {
  line-height: 30px;
  margin: 0;
  padding: 40px 0 40px 0;
  font-size: 16px;
}
.attachments {
  border-bottom: 1px #dddddd solid;
  border-top: 1px #dddddd solid;
  margin: 43px 0px 0px 0px;
  padding: 15px 0px;
}
.attachments .title {
  margin: 0;
  color: #00558e;
  line-height: 33px;
  font-size: 18px;
  font-family: "微软雅黑", "宋体";
}
.attachments ul {
  margin: 0;
  padding-left: 20px;
}

.attachments ul li a {
  color: #00558e;
  font-size: 16px;
  outline-style: none;
  text-decoration: none;
  cursor: pointer;
}
</style>

    <script>
export default {
  props: ["id"],

  data() {
    return {
      data: {},
    };
  },
  watch: {
    id: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch();
  },

  computed: {
    attachmenturl() {
      return process.env.VUE_APP_VIDEO_ROOT + "topics/";
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$hc
        .req()
        .get(`topics/${this.id}`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
    