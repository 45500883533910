<template>
 <v-card>
      <v-card-title>{{details.mingcheng}}</v-card-title>
      
      <v-card-text>
        <table style="width:90%" cellspacing="10">
          <tr>
            <td  class="grey--text text--darken-1">报名起止日期:</td>
            <td class="grey--text text--darken-3">从 {{details.baomingstart | moment('LL')}} 到 {{details.baomingend | moment('LL')}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">培训起止日期:</td>
            <td class="grey--text text--darken-3">从 {{details.from | moment('LL')}} 到 {{details.to | moment('LL')}}</td>
          </tr>
           <!-- <tr>
            <td  class="grey--text text--darken-1">已报名:</td>
            <td class="grey--text text--darken-3">{{details.baoming}}</td>
          </tr>
           <tr>
            <td  class="grey--text text--darken-1">已签到:</td>
            <td class="grey--text text--darken-3">{{details.checkedin}}</td>
          </tr> -->
          <tr>
            <td  class="grey--text text--darken-1">培训地点:</td>
            <td class="grey--text text--darken-3">{{details.didian}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">培训类型:</td>
            <td class="grey--text text--darken-3">{{details.peixunleixing}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">培训内容:</td>
            <td class="grey--text text--darken-3">{{details.neirong}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">培训讲师:</td>
            <td class="grey--text text--darken-3">{{details.jiangshi}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">参加要求:</td>
            <td class="grey--text text--darken-3">{{details.canjiayaoqiu}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">考核方式:</td>
            <td class="grey--text text--darken-3">{{details.kaohefangshi}}</td>
          </tr>
          <tr>
            <td  class="grey--text text--darken-1">课件:</td>
            <td class="grey--text text--darken-3">{{details.kejian}}</td>
          </tr>
        </table>
        
      </v-card-text>
 </v-card>
 
</template>

<script>
export default {
  name: 'PlainDetails',
  props:['details']
};
</script>
