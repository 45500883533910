<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/qualifications">返回</v-btn>
        <v-spacer></v-spacer>
        <small>考生管理</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteMember(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除考生</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "score",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualificationmembers?id=${this.id}&type=考生&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteMember(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualificationmembers?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
