<template>
  <div>
    <v-card>
      <v-card-title>
        <small>在线考试管理</small>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openQualificationEditDialog(null)">添加</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.start="props">{{
            props.item.start | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.joinstart="props">{{
            props.item.joinstart | moment("YYYY-MM-DD")
          }}</template>
          <template v-slot:item.joinend="props">{{
            props.item.joinend | moment("YYYY-MM-DD")
          }}</template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openQualificationEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteQualification(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除考试</span>
            </v-tooltip>
          </template>
          <template v-slot:item.quiz="props">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small color="primary" dark v-bind="attrs" v-on="on">
                  管理
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  @click.stop
                  :to="`/admin/qualifications/${props.item.id}/quiz`"
                >
                  <v-list-item-content>考试题目</v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  @click.stop
                  :to="`/admin/qualifications/${props.item.id}/members`"
                >
                  <v-list-item-content>参考考生</v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  @click.stop
                  :to="`/admin/qualifications/${props.item.id}/monitors`"
                >
                  <v-list-item-content>监考老师</v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  @click.stop
                  :to="`/admin/qualifications/${props.item.id}/verifiers`"
                >
                  <v-list-item-content>阅卷老师</v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="props.item.status != '阅卷结束'"
                  @click.stop="publishResult(props.item)"
                >
                  <v-list-item-content>发布成绩</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <qualification-edit-dialog
      ref="_edit_qualification"
      :open.sync="qualificationEdit"
      @success="fetchData"
    ></qualification-edit-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      qualificationEdit: false,
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},

      headers: [
        {
          text: "标题",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "报名开始",
          value: "joinstart",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "报名截止",
          value: "joinend",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试开始",
          value: "start",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试时间",
          value: "duration",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "",
          value: "quiz",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualifications?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    publishResult(item) {
      this.$hc
        .req()
        .post(`admin/qualifications/publishresult`, {
          qualificationid: item.id,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "成绩发布成功",
        })
        .subscribe(
          (x) => {
            this.fetchData();
          },
          (x) => {}
        );
    },
    openQualificationEditDialog(item) {
      var qualification = {};
      if (item) {
        qualification = { ...item };
        qualification.joinstart = this.$moment(
          qualification.joinstart,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("YYYY-MM-DD");
        qualification.joinend = this.$moment(
          qualification.joinend,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("YYYY-MM-DD");
        qualification.start_date = this.$moment(
          qualification.start,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("YYYY-MM-DD");
        qualification.start_time = this.$moment(
          qualification.start,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("HH:mm");
      }
      this.qualificationEdit = true;
      this.$refs._edit_qualification.loadData(qualification);
    },
    deleteQualification(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualifications?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
