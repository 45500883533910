<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/qualifications">返回</v-btn>
        <v-spacer></v-spacer>
        <small>考题管理</small>
        <v-spacer></v-spacer>
        <v-btn
          style="margin-right: 15px"
          color="success"
          :to="`/admin/qualifications/${this.id}/preview`"
          >预览考卷</v-btn
        >
        <v-btn
          color="secondary"
          @click="
            openQuizEditDialog(
              {
                qualificationid: id,
                type: '单选题',
                options: '[]',
                anwser: '[]',
              },
              true
            )
          "
          >添加</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openQuizEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteQuiz(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除考题</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <qualification-quiz-edit-dialog
      ref="_quiz"
      :open.sync="quizDialog"
      @success="fetchData"
    ></qualification-quiz-edit-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      data: {},
      quizDialog: false,
      quiz: {},
      headers: [
        {
          text: "序号",
          value: "ordindex",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目",
          value: "title2",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "分值",
          value: "point",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/qualificationquizs?qid=${this.id}`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    openQuizEditDialog(data, isnew) {
      this.$refs._quiz.loadData(data, isnew);
      this.quizDialog = true;
    },
    deleteQuiz(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualificationquizs?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
