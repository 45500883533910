var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('small',[_vm._v("考试管理")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.openAddDialog}},[_vm._v("添加考试")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.Payload,"loading":_vm.loading,"hide-default-footer":"","server-items-length":_vm.data.Total,"item-key":"id","fixed-header":true},scopedSlots:_vm._u([{key:"item.start",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.start,"YYYY-MM-DD HH:mm")))]}},{key:"item.end",fn:function(props){return [_vm._v(_vm._s(_vm._f("moment")(props.item.end,"YYYY-MM-DD HH:mm")))]}},{key:"item.answer",fn:function(props){return [_c('v-btn',{attrs:{"color":"secondary","x-small":"","to":("/admin/examinations/" + (props.item.id) + "/results")}},[_vm._v("查看答卷")])]}},{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openEditDialog(props.item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑考试")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteCourseware(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("删除考试")])])]}},{key:"no-data",fn:function(){return [_vm._v("没有考试")]},proxy:true}])})],1),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogSet.open),callback:function ($$v) {_vm.$set(_vm.dialogSet, "open", $$v)},expression:"dialogSet.open"}},[_c('v-form',{ref:"addForm",attrs:{"lazy-validation":"","autocomplete":"random-string"},model:{value:(_vm.dialogSet.vm.valid),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm, "valid", $$v)},expression:"dialogSet.vm.valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogSet.title))])]),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"name":"hack-chrome","label":"考试名称","rules":_vm.rules.mingchengRules,"prepend-icon":"payment","autocomplete":"false","counter":50},model:{value:(_vm.dialogSet.vm.data.name),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "name", $$v)},expression:"dialogSet.vm.data.name"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"fromDateDialog",attrs:{"persistent":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"开始日期","prepend-icon":"event","rules":_vm.rules.fromDateRules,"readonly":""},model:{value:(_vm.dialogSet.vm.data.startdate),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "startdate", $$v)},expression:"dialogSet.vm.data.startdate"}},on))]}}]),model:{value:(_vm.dialogSet.fromDateDialog),callback:function ($$v) {_vm.$set(_vm.dialogSet, "fromDateDialog", $$v)},expression:"dialogSet.fromDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dialogSet.fromDateDialog = false}},model:{value:(_vm.dialogSet.vm.data.startdate),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "startdate", $$v)},expression:"dialogSet.vm.data.startdate"}})],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"fromTimeDialog",attrs:{"return-value":_vm.dialogSet.vm.data.starttime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false},on:{"update:returnValue":function($event){return _vm.$set(_vm.dialogSet.vm.data, "starttime", $event)},"update:return-value":function($event){return _vm.$set(_vm.dialogSet.vm.data, "starttime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"开始时间","prepend-icon":"access_time","readonly":"","rules":_vm.rules.fromTimeRules},model:{value:(_vm.dialogSet.vm.data.starttime),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "starttime", $$v)},expression:"dialogSet.vm.data.starttime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogSet.fromTimeDialog),callback:function ($$v) {_vm.$set(_vm.dialogSet, "fromTimeDialog", $$v)},expression:"dialogSet.fromTimeDialog"}},[(_vm.dialogSet.fromTimeDialog)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.fromTimeDialog.save(_vm.dialogSet.vm.data.starttime)}},model:{value:(_vm.dialogSet.vm.data.starttime),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "starttime", $$v)},expression:"dialogSet.vm.data.starttime"}}):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"toDateDialog",attrs:{"persistent":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"结束日期","prepend-icon":"event","rules":_vm.rules.toDateRules,"readonly":""},model:{value:(_vm.dialogSet.vm.data.enddate),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "enddate", $$v)},expression:"dialogSet.vm.data.enddate"}},on))]}}]),model:{value:(_vm.dialogSet.toDateDialog),callback:function ($$v) {_vm.$set(_vm.dialogSet, "toDateDialog", $$v)},expression:"dialogSet.toDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dialogSet.toDateDialog = false}},model:{value:(_vm.dialogSet.vm.data.enddate),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "enddate", $$v)},expression:"dialogSet.vm.data.enddate"}})],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"toTimeDialog",attrs:{"return-value":_vm.dialogSet.vm.data.endtime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false},on:{"update:returnValue":function($event){return _vm.$set(_vm.dialogSet.vm.data, "endtime", $event)},"update:return-value":function($event){return _vm.$set(_vm.dialogSet.vm.data, "endtime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"结束时间","prepend-icon":"access_time","rules":_vm.rules.toTimeRules,"readonly":""},model:{value:(_vm.dialogSet.vm.data.endtime),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "endtime", $$v)},expression:"dialogSet.vm.data.endtime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogSet.toTimeDialog),callback:function ($$v) {_vm.$set(_vm.dialogSet, "toTimeDialog", $$v)},expression:"dialogSet.toTimeDialog"}},[(_vm.dialogSet.toTimeDialog)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.toTimeDialog.save(_vm.dialogSet.vm.data.endtime)}},model:{value:(_vm.dialogSet.vm.data.endtime),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "endtime", $$v)},expression:"dialogSet.vm.data.endtime"}}):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"name":"hack-chrome","label":"考试简介","rows":3,"rules":_vm.rules.descriptionRules,"prepend-icon":"subject","autocomplete":"false","counter":500},model:{value:(_vm.dialogSet.vm.data.description),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "description", $$v)},expression:"dialogSet.vm.data.description"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-file-input',{attrs:{"label":"试卷上传","accept":".doc,.docx","rules":_vm.rules.videoRules},model:{value:(_vm.dialogSet.vm.data.file),callback:function ($$v) {_vm.$set(_vm.dialogSet.vm.data, "file", $$v)},expression:"dialogSet.vm.data.file"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogSet.vm.data = {};
              _vm.dialogSet.open = false;}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"warning","disabled":!_vm.dialogSet.vm.valid},on:{"click":_vm.createCourseware}},[_vm._v("保存")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }