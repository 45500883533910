<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">面部识别认证</span>
        </v-card-title>
        <v-card-subtitle class="pb-0">
          请连接好摄像头, 选择你的摄像头。确保您的面部正对摄像头, 然后点击[认证]。<br />
          系统会根据您的报名照片进行面部识别认证, 通过后会自动进入待考区等待考试开始。
        </v-card-subtitle>
        <v-card-text>
          <v-row align="center">
            <v-col md="6" class="p5">
              <v-select
                :items="devices"
                v-model="deviceId"
                label="选择摄像头"
                item-text="name"
                item-value="id"
                @change="startVideo"
              ></v-select>
            </v-col>
            <v-col md="2" class="p5"
              ><v-btn @click="capture" :disabled="(deviceId || '') == ''"
                >认证</v-btn
              ></v-col
            >
          </v-row>
          <v-row align="center">
            <v-col md="12" class="p5">
              <video ref="_video" @playing="onVideoPlay" class="video" autoplay></video>
            </v-col>
          </v-row>
          <canvas ref="_capture" class="video" style="display: none"></canvas>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelDialog">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "ValidQualificationDialog",
  props: ["data", "open"],
  data() {
    return {
      valid: false,
      deviceId: "",
      devices: [],
    };
  },
  mounted() {},
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    reset() {
      this.devices = [];
      this.deviceId = "";
      if (this.$refs && this.$refs._capture) {
        this.$refs._capture.width = 0;
        this.$refs._capture.height = 0;
      }
    },
    onVideoPlay() {
      this.$refs._capture.width = this.$refs._video.clientWidth;
      this.$refs._capture.height = this.$refs._video.clientHeight;
    },
    capture() {
      this.$ns.cast("lock", true);
      var faceMatcher = this.$face.loadFaceMatcher(this.data.user_face_feature);
      this.$refs._capture
        .getContext("2d")
        .drawImage(
          this.$refs._video,
          0,
          0,
          this.$refs._video.clientWidth,
          this.$refs._video.clientHeight
        );
      this.$face._api
        .detectSingleFace(this.$refs._capture)
        .withFaceLandmarks()
        .withFaceDescriptor()
        .then((x) => {
          if (x) {
            var matchResult = faceMatcher.findBestMatch(x.descriptor);
            if (matchResult && matchResult.label == "my") {
              this.$ns.cast("snack", {
                text: "认证成功",
                color: "success",
              });
              this.closeCamera();
              this.$router.push(`/qualifications/${this.data.id}/start`);
            } else {
              this.$ns.cast("snack", {
                text: "认证失败, 请调整面部姿态并重试",
                color: "warning",
              });
            }
            this.$ns.cast("lock", false);
          } else {
            this.$ns.cast("lock", false);
          }
        });
    },
    listDevices() {
      this.reset();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.devices.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream = null;
          });
        })
        .catch((x) => {});
    },
    cancelDialog() {
      this.closeCamera();
      this.$emit("update:open", false);
    },
    closeCamera() {
      if (window.__stream) {
        window.__stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
    startVideo() {
      this.$ls.set("deviceId", this.deviceId);
      this.closeCamera();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: self.deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream = x;
          self.$refs._video.srcObject = x;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style scoped>
.video {
  max-width: 400px;
}
.p5 {
  padding: 5px;
}
</style>
